import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import Button from "components/button/button";
import { FaEllipsisH } from "react-icons/fa";
import { IoEllipse } from "react-icons/io5";
import { BsGlobe2, BsExclamationDiamondFill } from "react-icons/bs";
import UserTagIconSVG from "assets/svg/user-tag-icon";
import { Context } from "context/context";
import { formatDate, isImgUrl } from "utils/utils";
import ReactPlayer from "react-player";
import _ from "lodash";
import Popup from "reactjs-popup";
import OptionListItem from "../more-option/option-list-item";
import { gsap } from "gsap";
import AudioPlayer from "../media-button/audio-player";
import LazyImage from "../LazyImage";
// import { imageSquareOrPortrait } from "utils/utils";
import PrivacyBadge from "components/milestonelist/privacyBadge";
import { AiFillPushpin } from "react-icons/ai";
import { imageSquareOrPortrait } from "utils/utils";
import CharacterLimit from "../character-limit";
import ToolTip from "components/tootltip/tootltip";
import { CoverPlaceholderMilestone } from "constants/images";

export const DetailedViewCard = ({
  title,
  dateNote,
  date,
  people,
  url = "",
  viewUrl = "",
  description,
  privacy,
  isAdultContent,
  category,
  buttons = [],
  position,
  positionDescription,
  platform,
  isYourProfile,
  permission,
  profile,
  featured,
  containerStyle = "",
  onWatchMedia = () => {},
  onViewImage = () => {},
  handleViewPdf = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onReport = () => {},
}) => {
  const { showMaturedContent, setShowMaturedContent } = useContext(Context);
  const [showMoreOption, setShowMoreOption] = useState();
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [imageHeight, setImageHeight] = useState(null);
  // const [customClass, setCustomClass] = useState("");
  const [hideMusic, setHideMusic] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const listenRef = useRef();
  const optionRef = useRef();
  const contentRef = useRef();

  const handleMedia = async (media) => {
    let url = "";
    let fileExt = "";

    if (
      media.sub_type.name === "Multiple Images" ||
      media.sub_type.name === "Single Image"
    ) {
      onViewImage(title, media, media["media"]);
    } else {
      if (media.url) {
        url = media.url;
      } else {
        url = media["media"][0]["media"]["url"];
        fileExt = media["media"][0]["media"]["file_extension"];
      }

      if (await isImgUrl(url)) {
        onViewImage(title, media, media["media"]);
      } else if (ReactPlayer.canPlay(url)) {
        onWatchMedia(title, url);
      } else if (fileExt === "pdf") {
        handleViewPdf(title, url);
      } else {
        if (url.includes("https://www.tiktok.com/")) {
          onWatchMedia(title, url, true);
        } else {
          window.open(url, "_blank");
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (listenRef.current) {
          setHideMusic(true);
        }
        if (optionRef.current) {
          optionRef.current.close();
        }
      },
      true
    );
  }, []);

  useLayoutEffect(() => {
    gsap.fromTo(
      ".milestone-item",
      {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
      }
    );
  }, []);

  useEffect(() => {
    (async () => {
      const res = await imageSquareOrPortrait(url);
      const element = contentRef.current;
      if (res === "portrait") {
        if (element) {
          if (element.offsetHeight < 300) {
            setImageHeight(300);
          } else {
            setImageHeight(element.offsetHeight);
          }
          setImageUrl(url);
        }
      } else {
        setImageUrl(url);
      }
    })();
  }, [url]);

  return (
    // <div
      // className={`milestone-item w-full bg-siteBg 2md:rounded-md overflow-hidden ${containerStyle}`}>
    <div className="milestone-item w-full bg-siteBg rounded-md mt-3 2sm:mt-4.5 3md:mt-6 mb-8">
      <div className={`w-full flex flex-col 2md:flex-row space-y-0.5 2md:space-x-0.5 2md:space-y-0  ${
          imageUrl ? "min-h-300" : "min-h-300"
        }`}>
        {/* image */}
        {imageUrl ? (
          <div className="relative bg-white h-auto 2md:min-h-[300px] 2md:w-[300px]">
            {/* thumbnail */}
            <LazyImage
              viewUrl={viewUrl}
              source={imageUrl}
              customClassName="w-full md:max-w-[300px] h-auto 2md:max-h-[300px]"
              imageHeight={imageHeight}
              withContainer="w-full 2md:w-[300px]"
            />
            {/* restricted Image */}
            {/* {!isAdultContent && !showMaturedContent && (
              <div className="absolute top-0 left-0 matured-content-blured">
                <div className="w-full px-6 text-center">
                  <div className="flex justify-center">
                    <BsExclamationDiamondFill className="text-5xl" />
                  </div>
                  <h3 className="mt-4.5 font-bold text-lg">Mature Content</h3>
                  <p className="mt-4 text-sm font-medium hidden sm:block">
                    You must be 18+ to view this image. Are you over eighteen
                    and willing to see adult content?
                  </p>
                  <Button
                    buttonName="Continue"
                    buttonTextClass="text-sm text-white font-semibold"
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-11 bg-transparent border-2 border-white mt-5.5"
                    onClick={() => setShowMaturedContent((prev) => !prev)}
                  />
                </div>
              </div>
            )} */}
          </div>
        ):
        <div className="relative min-h-[300px] 2md:w-[300px] flex items-center justify-center bg-[#e9e9e9]">
            <div className="w-full 2md:w-[300px] flex items-center justify-center h-full bg-[#e9e9e9]">
              <img src={CoverPlaceholderMilestone} alt="Milestone" className="h-[111px] w-[113px]"/>
            </div>
        </div>
        }
        {/* content container */}
        <div
          className={`relative bg-white 2md:mt-0 px-6 pb-6 pt-4 2sm:py-4 2sm:px-7.5 p-7 w-full ${
            url
              ? "width-responsive-detailed-view"
              : "width-responsive-detailed-view-full"
          }`}
          ref={contentRef}>
          <div className="w-full flex 1sm:hidden justify-end">
            <button className="bg-thBg w-10 h-10 rounded-md">
              <FaEllipsisH className="m-auto" />
            </button>
          </div>
          {/* content */}
          <div className="w-full 1sm:flex 1sm:justify-between">
            <div className="w-full">
              {/* title */}
              <div className="flex items-center space-x-5 flex-wrap">
                <p className="text-base 2sm:text-xl 3md:text-2xl text-lightBlack font-medium tracking-tighter leading-7">
                  {title}
                </p>
              </div>
              {/* category */}
              {/* <div className="flex items-center space-x-5 flex-wrap">
                <p className="px-4.5 py-1.5 bg-lightGreen text-white text-xs uppercase font-semibold rounded-md">
                  {category}
                </p>
              </div> */}
              {/* details */}
              <div className="w-full mb-2 mt-1.5">
              <div className="w-full text-4xs 2sm:text-sm 3md:text-base text-darkerGray font-medium 3md:leading-[16px] flex items-center space-x-2 3md:space-x-3 flex-wrap">
                  <p>
                    {date}
                    {dateNote && (
                      <span className="ml-1">{`(${dateNote})`}</span>
                    )}
                  </p>
                  <PrivacyBadge privacy={privacy} isYourProfile={isYourProfile}/>
                  {/* <p className="flex items-center capitalize">
                    {privacy === "public" ? (
                      <BsGlobe2 className="mr-1.5" />
                    ) : (
                      <BsExclamationDiamondFill className="mr-1.5" />
                    )}
                    {privacy}
                  </p> */}
                  {people && (
                    <>
                      <p className="hidden md:block">
                        <IoEllipse className="w-1 h-1" />
                      </p>
                      <p className="hidden md:flex items-center">
                        <UserTagIconSVG className="mr-1.5" />
                        {people}
                      </p>
                    </>
                  )}
                  <p className="px-1.5 py-1 block  3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-1xs 2sm:text-xs uppercase font-semibold rounded-md " style={{ marginLeft: 20 }} >
                    {category}
                  </p>
                </div>
              </div>
              {/* Author Name and description */}
              <div className="w-full mt-4">
                <p className="text-4xs 2sm:text-sm 3md:text-base font-bold text-primary 3md:leading-6">
                  {position}
                  {positionDescription && ` - ${positionDescription} `}
                  {platform && `(${platform})`}
                </p>
                {/* <p
                  className="mt-2.5 text-base leading-7.5 tracking-tightest truncate-multiline"
                  dangerouslySetInnerHTML={{ __html: description }}></p> */}
                  <CharacterLimit html={description} /> 
              </div>
              {/* buttons */}
              {!_.isEmpty(buttons) && (
                <div className="w-full mt-3">
                  <div className="w-full grid grid-cols-3 1xs:grid-cols-4 4sm:grid-cols-5 2md:grid-cols-4 lg:grid-cols-6 gap-3">
                    {buttons.map((btn, index) => {
                      if (btn.type.name === "Listen") {
                        return (
                          <Popup
                            ref={listenRef}
                            key={index}
                            position="bottom left"
                            open={showAudioPlayer}
                            onClose={() => setShowAudioPlayer(false)}
                            onOpen={() => setHideMusic(false)}
                            arrow={false}
                            closeOnDocumentClick={false}
                            contentStyle={{ marginTop: 20 }}
                            repositionOnResize={true}
                            trigger={(open) => {
                              return (
                                <button
                                  className={`relative rounded-md py-3 px-4   xl:px-0 border-2 border-gray btn-hover-white-primary bg-white w-full h-full font-semibold ${
                                    showAudioPlayer || open
                                      ? "bg-darkPrimary text-white"
                                      : "text-lightBlack"
                                  }`}
                                  onClick={() => {
                                    setShowAudioPlayer((prev) => !prev);
                                    if (open) {
                                      setHideMusic(false);
                                    }
                                  }}>
                                  Listen
                                </button>
                              );
                            }}>
                            <AudioPlayer
                              media={btn}
                              hide={hideMusic}
                              onClosePlayer={() => {
                                if (listenRef.current) {
                                  listenRef.current.close();
                                }
                              }}
                            />
                          </Popup>
                        );
                      }

                      return (
                        btn.type.name === 'Other'  ? 
                        <div className="w-full">
                        <ToolTip
                        value={btn?.other_description === 'undefined' ? 'No descrption' : btn?.other_description || 'No descrption'}
                        backgroundColor="#0c6ed1"
                        color="white">
                          <Button
                            key={index}
                            buttonName={"Other"}
                            buttonTextClass={`text-xs 2md:text-sm text-lightBlack font-semibold`}
                            buttonRadius="6px"
                            buttonClass={`relative rounded-md py-3 px-4   xl:px-0 border-2 border-gray btn-hover-white-primary bg-white w-full h-full`}
                            onClick={() => handleMedia(btn)}
                          />
                          </ToolTip>
                          </div>
                        : <Button
                            key={index}
                            buttonName={btn.type.name}
                            buttonTextClass={`text-xs 2md:text-sm text-lightBlack font-semibold`}
                            buttonRadius="6px"
                            buttonClass={`relative rounded-md py-3 px-4   xl:px-0 border-2 border-gray btn-hover-white-primary bg-white w-full h-full`}
                            onClick={() => handleMedia(btn)}
                          />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {/* more */}
            <Popup
              ref={optionRef}
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ marginTop: 10, marginLeft: 5 }}
              trigger={
                <button
                  className="bg-thBg w-10 h-10 rounded-md hidden 1sm:block"
                  onClick={() => setShowMoreOption((open) => !open)}>
                  <FaEllipsisH className="m-auto" />
                </button>
              }>
              <OptionListItem
                {...{
                  isYourProfile,
                  onEdit,
                  onDelete,
                  permission,
                  onReport,
                  profile,
                  featured,
                }}
              />
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function OrganizePositionDetailedView({
  featured = {},
  milestones = [],
  pin=[],
  showFeatured,
  isYourProfile,
  profile,
  onWatchMedia = () => {},
  onViewImage = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onReport = () => {},
}) {
  const milestoneKeys = Object.keys(milestones);
  const featuredThumbnail = featured.thumbnail
    ? featured.thumbnail.webp_url || featured.thumbnail.url
    : null;

  useLayoutEffect(() => {
    gsap.fromTo(
      ".organize-title",
      {
        y: -20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
      }
    );
  }, []);

  return (
    <div className="w-full container-max-width">
      {showFeatured && (
        <div className="w-full container-max-width">
          {!_.isEmpty(featured) && (
            <DetailedViewCard
              profile={profile}
              featured={featured}
              url={featuredThumbnail}
              viewUrl={featured?.thumbnail?.url || null}
              title={featured.title}
              date={featured.formatted_date}
              people={featured.people}
              position={featured.name}
              positionDescription={featured.mp_description}
              platform={featured.platform}
              description={featured.description}
              privacy={featured.privacy}
              isAdultContent={featured.is_adult_content ? true : false}
              category={featured.category && featured.category.name}
              buttons={featured.media_type}
              onWatchMedia={onWatchMedia}
              onViewImage={onViewImage}
              onEdit={() => onEdit(featured)}
              onDelete={() => onDelete(featured)}
              isYourProfile={isYourProfile}
              onReport={() => onReport(featured)}
            />
          )}
        </div>
      )}
      <div className="w-full container-max-width">
          <div>
            { pin.length > 0 &&
              <p className="milestone-title text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter flex gap-3">
              <>
              Pinned
              </> 
              <AiFillPushpin className="text-2xl"/>
            </p>}
            {pin.map((obj,key) => {
              const date = formatDate(obj, profile?.dateFormat);
              const thumbnail = obj.thumbnail
                ? obj.thumbnail.webp_url || obj.thumbnail.url
                : null;
              return (
                <DetailedViewCard
                  containerStyle="mt-6"
                  key={`${key}`}
                  profile={profile}
                  featured={obj}
                  url={thumbnail}
                  viewUrl={obj?.thumbnail?.url || null}
                  title={obj.title}
                  date={date}
                  people={obj.people}
                  position={obj.name}
                  positionDescription={obj.mp_description}
                  platform={obj.platform}
                  description={obj.description}
                  privacy={obj.privacy}
                  isAdultContent={obj.is_adult_content ? true : false}
                  category={obj.category.name}
                  buttons={obj.media_type}
                  onWatchMedia={onWatchMedia}
                  onViewImage={onViewImage}
                  onEdit={() => onEdit(obj)}
                  onDelete={() => onDelete(obj)}
                  isYourProfile={isYourProfile}
                  onReport={() => onReport(obj)}
                />
              );
            })}
          </div>
      </div>

      <div className="w-full container-max-width mt-10">
        {milestoneKeys?.map?.((key) => (
          <div key={key}>
            <p className="organize-title px-7.5 3md:px-0 text-lightBlack font-bold text-2xl leading-7.5 tracking-tighter mt-10">
              {key} - {milestones[key].length} credits
            </p>
            {milestones[key]?.map?.((obj) => {
              const date = formatDate(obj, profile?.dateFormat);
              const thumbnail = obj.thumbnail
                ? obj.thumbnail.webp_url || obj.thumbnail.url
                : null;
              return (
                <DetailedViewCard
                  containerStyle="mt-6"
                  key={`${key}-${obj.id}`}
                  profile={profile}
                  featured={obj}
                  url={thumbnail}
                  viewUrl={obj?.thumbnail?.url || null}
                  title={obj.title}
                  date={date}
                  people={obj.people}
                  position={obj.name}
                  positionDescription={obj.mp_description}
                  platform={obj.platform}
                  description={obj.description}
                  privacy={obj.privacy}
                  isAdultContent={obj.is_adult_content ? true : false}
                  category={obj.category.name}
                  buttons={obj.media_type}
                  onWatchMedia={onWatchMedia}
                  onViewImage={onViewImage}
                  onEdit={() => onEdit(obj)}
                  onDelete={() => onDelete(obj)}
                  isYourProfile={isYourProfile}
                  onReport={() => onReport(obj)}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
