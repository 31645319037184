import React, {useRef,useEffect} from "react";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [["bold", "italic", "underline"], [{ list: "bullet" }]],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const modules_full = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default function WYSIWYGEditor({
  label = "",
  labelClass = "text-sm font-semibold text-darkerGray",
  rows = 4,
  inputClass = "px-4 py-5",
  fullToolbar = false,
  optional = false,
  onChange = (value)=>{},
  limitChar = 10000,
  ...props // make sure this pops here is for input only
}) {

  const reactQuillRef = useRef(null);
  const quillRef = useRef(null);

  useEffect(() => {
    attachQuillRefs();
  });

  const attachQuillRefs = () => {
    if (
      reactQuillRef.current &&
      typeof reactQuillRef.current.getEditor === 'function'
    ) {
      quillRef.current = reactQuillRef.current.getEditor();
    }
  };

  const handleChange = (html) => {
    var limit = limitChar;
    var quill = quillRef.current;
    if(quill){
      quill.on('text-change', function (delta, old, source) {
        if (quill.getLength() > limit) {
         quill.deleteText(limit, quill.getLength());
        }
      });
    } 
    onChange(html);
  }

  return (
    <div className="form-input-container" style={{ width: "100%" }}>
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
          {optional && <span className="font-normal"> (optional)</span>}
        </label>
      )}
      <ReactQuill
        ref={reactQuillRef}
        className="mt-2 w-full"
        theme="snow"
        modules={fullToolbar ? modules_full : modules}
        formats={formats}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
}
