import "./App.css";
import "./custom.css";
import "react-quill/dist/quill.snow.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ModalProvider } from "react-modal-hook";
import RouterComponent from "router/index";
import React from "react";

function App() {
  React.useEffect(()=>{
    locationIP();
  },[])
  const locationIP = async()=>{
    try{
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        console.log(data.country)
        // "MMMM D YYYY" - USA format "D MMMM YYYY" - international format
        if(data.country === 'US'){
          localStorage.setItem('dateFormat', 'MMMM D YYYY');
        }else{
          localStorage.setItem('dateFormat', 'D MMMM YYYY');
        }
    }catch(e){
      console.log("Errror : " + e);
    }
  }
  return (
    <ModalProvider>
      <div className="App">
        <RouterComponent />
      </div>
    </ModalProvider>
  );
}

export default App;
