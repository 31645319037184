import React, { useEffect, useRef, useState } from "react";
import Modal from "components/global/modal";
import { FaTimes } from "react-icons/fa";
import Slider from "react-slick";
import "./styles.css";
import Zoom from "./zoom";
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                transform: "scale(2.4)",
                marginRight: -40,
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "block",
                transform: "scale(2.4)",
                marginLeft: -40,
            }}
            onClick={onClick}
        />
    );
}

export default function ImageGallery({ hide, ...props }) {
    const { media = [], button = {}, focus = 1 } = props;

    const sliderRef = useRef();
    const [currentIndex, setCurrentIndex] = useState();

    const settings = {
        className: "center",
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentIndex((prev) => next + 1),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        adaptiveHeight: true,
    };

    const onChange = (index) => {
        setCurrentIndex((prev) => index + 1);
        sliderRef.current.slickGoTo(index);
    };

    useEffect(() => {
        if (sliderRef.current) {
            setCurrentIndex(focus);
            sliderRef.current.slickGoTo(focus - 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focus, sliderRef.current]);

    return (
        <>
            <div className="hidden sm:block">
                <Modal
                    width="w-11/20 md:w-13/20 lg:w-14/20 xl:w-15/20"
                    contentClassName="rounded-lg overflow-auto custom-style"
                    wrapperClassName="overflow-visible custom-style"
                    image_gallery={true}
                >
                    <div className="">
                        <div className="flex flex-row justify-between items-baseline mb-4">
                            <p className="text-2xl text-white font-bold">
                                {media.length}{" "}
                                {media.length > 1 ? "Photos" : "Photo"}
                            </p>
                            <p className="text-lg text-white">
                                {currentIndex} of {media.length}
                            </p>
                            {/* close */}
                            <button onClick={() => hide()} className="mr-4">
                                <FaTimes className="text-white text-xl" />
                            </button>
                        </div>
                        <div className="w-full">
                            {button.url ? (
                                <Slider ref={sliderRef} {...settings}>
                                    <div className="bg-black">
                                        <img
                                            src={button.url}
                                            className="object-cove m-auto"
                                            style={{ maxHeight: "555px" }}
                                            alt="slider"
                                        />
                                    </div>
                                </Slider>
                            ) : (
                                <Slider ref={sliderRef} {...settings}>
                                    {media.map((file, index) => {
                                        return (
                                            <Zoom>
                                                <div
                                                    key={index}
                                                    className="bg-black"
                                                >
                                                    <img
                                                        src={file.media.url}
                                                        className="object-cove m-auto"
                                                        style={{
                                                            maxHeight: "555px",
                                                        }}
                                                        alt={`slider-${index}`}
                                                    />
                                                </div>
                                            </Zoom>
                                        );
                                    })}
                                </Slider>
                            )}

                            <div className="flex flex-row justify-center space-x-4 mt-4">
                                {media.map((file, index) => {
                                    let url = file.url
                                        ? file.url
                                        : file.media.url;
                                    return (
                                        <div
                                            key={index}
                                            className="bg-black cursor-pointer"
                                            onClick={() => onChange(index)}
                                        >
                                            <img
                                                src={url}
                                                className="w-24 h-24 object-cover"
                                                alt={`preview-${index}`}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="block sm:hidden ">
                <Modal
                    width=""
                    contentClassName="rounded-lg custom-style modal-gallery"
                    wrapperClassName="overflow-visible custom-style"
                    image_gallery={true}
                >
                    <div className="flex flex-row justify-between items-baseline mb-4">
                        <p className="text-2xl text-white font-bold text-title ">
                            {media.length}{" "}
                            {media.length > 1 ? "Photos" : "Photo"}
                        </p>
                        <p className="text-lg text-white text-counter ">
                            {currentIndex} of {media.length}
                        </p>
                        {/* close */}
                        <button onClick={() => hide()} className="mr-4">
                            <FaTimes className="text-white text-sm" />
                        </button>
                    </div>
                    {button.url ? (
                        <Slider ref={sliderRef} {...settings}>
                            <div className="bg-black">
                                <img
                                    src={button.url}
                                    className="object-cove m-auto"
                                    style={{ maxHeight: "555px" }}
                                    alt="slider"
                                />
                            </div>
                        </Slider>
                    ) : (
                        <Slider ref={sliderRef} {...settings}>
                            {media.map((file, index) => {
                                return (
                                    <div key={index} className="bg-black">
                                        <img
                                            src={file.media.url}
                                            className="object-cove m-auto"
                                            style={{ maxHeight: "555px" }}
                                            alt={`slider-${index}`}
                                        />
                                    </div>
                                );
                            })}
                        </Slider>
                    )}
                </Modal>
            </div>
        </>
    );
}
