import React from "react";
import SingOutSVG from "assets/svg/signout";
import VerifyIconXSVG from "assets/svg/verified-icon-xs";
import UsersIconSVG from "assets/svg/users";
import ProfileDefault from "assets/images/profile_default.png";   
import { display_name } from "utils/utils";

export default function HeaderProfileDetails({
  userData = {},
  occupation = "",
  handleLogout = () => { },
  editAccount = () => { },
  myProfile = ()=>{},
  profiles = [],
}) {

  return (
    <div className="bg-white px-2 py-2">
      <div className="relative w-full flex items-center gap-x-5 bg-white px-6 py-4 rounded-md cursor-pointer hover:bg-modalGray" onClick={myProfile}> 
        <div className="w-24.5 h-24.5 bg-passwordGray rounded-lg">
          <img
            src={userData.profile?.profile_image_url || ProfileDefault}
            alt="milestone-profile"
            className="rounded-md w-24.5 h-24.5"
          />
        </div>
        <div className="w-15/20">
          <p className="text-lightBlack font-bold text-lg leading-5.5 tracking-tighter flex items-center">
            <>
              {userData?.profile?.profile_name || userData?.profile?.other_name || userData?.user?.username}
              {/* {userData?.profile?.username ||
              userData?.user?.username ||
                userData?.profile?.other_name ||
                (userData?.user.email ? userData?.user.email?.split("@")[0] : "")} */}
            </>
            {userData.profile.id3_verified && (
              <VerifyIconXSVG className="ml-2" />
            )}
          </p>
          <p className="text-darkerGray text-xs leading-4.5">{occupation}</p>
          <p className="text-darkerGray text-xs leading-4.5 flex items-center">
            <UsersIconSVG className="mr-2" />
            <>{profiles.length} Users</>
          </p>
          {/*  */}
          <div className="w-full mt-2.5 1xs:flex 1xs:justify-between">
            <button
              className="text-sm text-primary font-semibold leading-4.4 hover:text-lightPrimary"
              onClick={(e)=>{
                e.stopPropagation();
                editAccount();
              }}
            >
              Edit Account
            </button>
            <button
              className="text-sm text-lightBlack font-semibold leading-4.4 flex items-center hover:text-darkPrimary"
              onClick={(e)=>{
                e.stopPropagation();
                handleLogout();
              }}
            >
              <SingOutSVG className="mr-2" />
              <>Sign Out Account</>
            </button>
          </div> 
      </div>
    </div>
    </div>
  );
}
