import React, { useEffect, useState, useMemo } from "react";
import Modal from "components/global/modal";

import { formatDate } from "utils/utils";
import { DetailedViewCard } from "./detailed-view";
export default function ShareMilestoneModal({
  hide,
  // milestone,
  // featured,
  userData,
  // username,
  // profileId,
  onWatchMedia,
  onViewImage,
  handleViewPdf,
  editMilestone,
  removeMilestone,
  _status,
  share,
  hash_id,
  profile,
  onReport = () => {},
}) {
  const { http } = global.services;
  const [_milestone, setMilestone] = useState(null);
  // const [permission, setPermission] = useState(null);
  // const [profile, setProfile] = useState({});
  // const getUserProfilePermission = async (uname) => {
  //   try {
  //     const url = urlSerializer(`public/user-accesses/permission`, {
  //       username: uname,
  //       user_id: userData.user.id,
  //     });
  //     const { data } = await http.get(url);
  //     if (!_.isEmpty(data)) {
  //       setPermission(data);
  //     }
  //     console.log("getUserProfilePermission ", data);
  //   } catch (err) {
  //     console.log("share-milestone-modal getUserProfilePermission error", err);
  //   }
  // };
  // const getUserProfile = async (uname) => {
  //   try {
  //     const url = urlSerializer("public/user-profiles/get-profile", {
  //       username: uname,
  //       id: profileId,
  //     });
  //     const { data } = await http.get(url);
  //     setProfile(data);
  //   } catch (err) {
  //     console.log("error@getUserProfile", err);
  //   }
  // };
  // const getMilestone = async () => {
  //   let privacy = ["public"];
  //   if (userData) {
  //     if (
  //       profile.user &&
  //       userData.user &&
  //       userData.user.id === profile.user.id
  //     ) {
  //       privacy.push("private");
  //       privacy.push("verified");
  //       privacy.push("unlisted");
  //     } else {
  //       if (
  //         userData.account_verified &&
  //         !_.isEmpty(userData.account_verified)
  //       ) {
  //         privacy.push("verified");
  //       } else if (!_.isEmpty(permission)) {
  //         privacy.push("unlisted");
  //       }
  //     }
  //   }
  //   try {
  //     const { data } = await http.get("public/milestone/get-milestone", {
  //       hash_id: milestone,
  //       featured,
  //       skip: 0,
  //       user_profile_id: profile?.id,
  //       limit: 30,
  //       privacy,
  //       permission,
  //     });
  //     setMilestone(data.data);
  //   } catch (error) {
  //     console.log("share-milestone-modal getMilestone error", error);
  //   }
  // };
  // useEffect(() => {
  //   const uname = username.split("-")[0];
  //   if (userData) {
  //     if (userData.user.username !== uname) getUserProfilePermission(uname);
  //     else setPermission({ status: "owner" });
  //   }
  //   getUserProfile(uname);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   if (profile && permission) getMilestone();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [profile, permission]);

  const getShareMilestone = async () => {
    console.log("start share");
    const { data } = await http.get(`milestone/share/${hash_id}`);
    console.log(data);
    setMilestone(data);
    console.log("End share");
  };

  useEffect(() => {
    getShareMilestone();
    console.log("start share check=====================================");
    console.log(_milestone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  const closeModal = () => hide();
  // const date = formatDate(
  //   _milestone && _milestone
  // );
  // const thumbnail =
  //   _milestone.length > 0 && _milestone[0].thumbnail
  //     ? _milestone[0].thumbnail.webp_url || _milestone[0].thumbnail.url
  //     : null;

  const date = useMemo(() => {
    // console.log("===================MEMO====================");
    // console.log(_milestone);
    if (_milestone) {
      return formatDate(_milestone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_milestone]);

  return (
    <Modal
      share={share}
      width="custom-with-responsive"
      // width="w-19/20 md:w-18/20 2md:w-17/20 3md:w-15/20 4md:w-13/20 1xl:w-11/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible">
      {_milestone && (
        <div className="w-full px-8 pb-8 relative">
          {/* content */}
          <DetailedViewCard
            featured={_milestone}
            details={_milestone}
            url={
              _milestone.thumbnail &&
              (_milestone.thumbnail.webp_url || _milestone.thumbnail.url)
            }
            title={_milestone.title}
            dateNote={_milestone.date_note}
            date={date}
            people={_milestone.people}
            positions={_milestone.positions}
            platform={_milestone.platform}
            description={_milestone.description}
            privacy={_milestone.privacy}
            isAdultContent={_milestone.is_adult_content ? true : false}
            category={_milestone.category?.name}
            buttons={_milestone.media_type}
            onWatchMedia={onWatchMedia}
            onViewImage={onViewImage}
            handleViewPdf={handleViewPdf}
            onEdit={() => editMilestone(_milestone, _status)}
            onDelete={() => removeMilestone(_milestone)}
            onReport={() => onReport(_milestone)}
            isYourProfile={
              userData &&
              userData.profile &&
              profile &&
              userData.profile.id === profile.id
            }
            // permission={permission}
            share={false}
            closeModal={closeModal}
          />
          <div
            className="absolute bottom-8 text-base font-bold text-primary leading-6 cursor-pointer"
            style={{ right: 42 }}
            onClick={() => hide()}>
            See other milestones -&gt;
          </div>
          {/* end content */}
        </div>
      )}
    </Modal>
  );
}
