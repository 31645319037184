import React, { useContext, useLayoutEffect, useEffect } from "react";
import { connect } from "react-redux";
import Layout from "layouts/layout";
import { TabSelector } from "components/global/tabselector";
import { TabPanel, useTabs } from "react-headless-tabs";
import LineVerticalSVG from "assets/svg/line-vertical";
import { gsap } from "gsap";
import ProfileListFollowing from "components/profile/following/following";
import VerifyIconSVG from "assets/svg/verified-icon";
import BackArrowFollowingIconSVG from "assets/svg/back-arrow-following-icon";
import { useHistory } from "react-router-dom";
import { Context } from "context/context";
import _ from "lodash";
import { urlSerializer } from "utils/utils";
import PopInfo from "components/info-popup/pop-info";
import ArrowLeftIcon from "assets/svg/arrow-left-icon";

const Following = ({ userData }) => {
  const { http } = global.services;
  const [selectedTab, setSelectedTab] = useTabs(["following", "followers"]);
  const { profile: data, user } = userData;
  const { following, setFollowing, followers, setFollowers } =
    useContext(Context);
  const history = useHistory();

  const getFollowing = async (type = "following") => {
    try {
      const { data } = await http.get("public/user-followers", {
        user_profile_id: userData.profile.id,
        type: type,
      });
      if (type === "following") {
        setFollowing(data?.data);
      } else if (type === "followers") {
        setFollowers(data?.data);
      }
    } catch (err) {
      console.log("error@getFollowing", err);
    }
  };

  const onFollow = async (id) => {
    try {
      const url = urlSerializer("/follow", {
        followed_id: id,
        follower_id: userData?.profile?.id,
      });
      const { data } = await http.post(url);
      const { follow } = data;
      if (follow) {
        getFollowing("following");
        getFollowing("followers");
      }
      return follow;
    } catch (err) {
      console.log("error@onFollow", err);
    }
  };

  useLayoutEffect(() => {
    gsap.fromTo(
      ".following-tabs",
      { scale: 0.8, opacity: 0 },
      { scale: 1, opacity: 1 }
    );
    gsap.fromTo(
      ".following-title",
      { y: -25, opacity: 0 },
      { y: 0, opacity: 1 }
    );
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userData.profile)) {
      if (_.isEmpty(following) || _.isEmpty(followers)) {
        getFollowing("following");
        getFollowing("followers");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.profile]);

  const notFullName = (profile_name,user)=>{
    if(profile_name !== `${user?.first_name} ${user?.last_name}`){
      return true;
    }else{
      return false;
    }
  }

  return (
    <Layout title="Following">
      <div className="auth-page-wrapper pb-8.5">
        <div className="w-full container-max-width bg-white rounded-md px-6 pt-6.6">
          <div className="w-full flex items-center gap-4">
            <button
              // style={{ width: "46px", height: "71px" }}
              onClick={() => history.goBack()}>
              {/* <BackArrowFollowingIconSVG /> */}
              <ArrowLeftIcon className="font-bold text-5xl" />
            </button>
            <div className="w-full">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <h1 className="following-title text-base 2sm:text-2xl 3md:text-5xl text-lightBlack tracking-normal font-bold flex flex-row items-center justify-center 3md:justify-start">
                {/* <>{display_name() || data?.user?.username}</> */}
                <>{data?.profile_name || data?.other_name || data?.user?.username}</>
                  {data?.id3_verified && (
                    <VerifyIconSVG className="ml-2.5" />
                  )}
                </h1>
                <PopInfo containerClass="relative" type="following" />
              </div> 
              
            </div>
          </div>
          <div className="flex justify-start 3md:justify-start text-lightBlack ml-6 leading-6 flex-wrap 2sm:ml-6">
                {data?.username && (
                  <p className="text-primary font-[500]">@{data?.username}</p>
                )}
                {Object.keys(data).length !== 0 && (
                  <p className="text-passwordGray">&#8226;</p>
                )}
                {/* <p>{data?.other_name}</p> */}
                {!_.isEmpty(user) && (
                  <>
                  <>
                  <span className="font-semibold pr-2">aka</span>
                  {/* {data?.displayName !== 'First Name Surname' ? `${user?.first_name} ${user?.last_name} ` : ''}
                  {(data?.displayName !== 'First Name Surname' && user?.email_verified_at)  ?
                    <span className="pr-2">
                    <VerifyIconSVG className="w-3 h-3 3md:w-3 3md:h-3 ml-1 3md:ml-2.5" />
                    </span>
                    :
                    <span>/</span>
                  } */}
                  {
                      notFullName(data?.profile_name,user) &&
                      <> 
                          <>{user?.first_name} {user?.last_name} </>
                      
                          {data?.id3_verified ? (
                              <span className="flex items-center pl-1">
                                  <VerifyIconSVG className="w-3 h-3 3md:w-3 3md:h-3" />
                              </span>
                          ) : (
                              <span className="px-1">/</span>
                          )}
                      </>
                  }
                  {/* {data?.username} */}
                  {Object.keys(data).length !== 0 && (
                    <p className="text-passwordGray">&#8226;</p>
                  )}
                  <p>{data?.other_name}</p>
                  {/* {data?.other_names && 
                    <>
                    <span>/</span>
                    {
                      data?.other_names.map((p, i) => (
                        <span key={i}>
                          {p.label}
                          {i < data?.other_names.length - 1 && " / "}
                        </span>
                      ))
                    }
                    </>
                    } */}
                    </>{" "}
                    {/* {Object.keys(data).length !== 0 && !_.isEmpty(data?.gender) && (
                      <p className="text-passwordGray">&#8226;</p>
                    )} */}
                  </>
                )}
                {Object.keys(data).length !== 0 && (
                  <p className="text-passwordGray">&#8226;</p>
                )}
                {data?.gender && <p>{data?.gender}</p>}
                {Object.keys(data).length !== 0 && (
                  <p className="text-passwordGray">&#8226;</p>
                )}
                {data?.address && <p>{data?.address}</p>}
          </div>
          <div className="following-tabs flex justify-center flex-wrap 1md:flex-nowrap 1md:justify-start items-center mt-11.5">
            <TabSelector
              isActive={selectedTab === "following"}
              gap="3sm:mr-7.6"
              colorType="black"
              paddingY="pb-6.5"
              onClick={() => setSelectedTab("following")}>
              <div className="w-full flex justify-center items-center">
                {_.size(following)} Following
              </div>
            </TabSelector>
            <div className="hidden 3sm:block">
              <LineVerticalSVG />
            </div>
            <TabSelector
              isActive={selectedTab === "followers"}
              gap="3sm:ml-7.6 3sm:mr-7.6"
              colorType="black"
              paddingY="pb-6.5"
              onClick={() => setSelectedTab("followers")}>
              <div className="w-full flex justify-center items-center">
                {_.size(followers)} Followers
              </div>
            </TabSelector>
          </div>
        </div>
        <div className="w-full container-max-width">
          <TabPanel hidden={selectedTab !== "following"}>
            <ProfileListFollowing
              list={following}
              type="following"
              onFollow={(e) => onFollow(e)}
            />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "followers"}>
            <ProfileListFollowing
              list={followers}
              type="follower"
              onFollow={(e) => onFollow(e)}
            />
          </TabPanel>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

export default connect(mapStateToProps)(Following);
