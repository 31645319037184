import { TabSelector } from "components/global/tabselector";
import AboutIconSVG from "assets/svg/about-icon-svg";
import LineVerticalSVG from "assets/svg/line-vertical";
import MilestoneIconSVG from "assets/svg/milestone-icon-svg";
import PhotosIconSVG from "assets/svg/photos-icon-svg";
import ResumeIconSVG from "assets/svg/resume-icon-svg";
import React, { useEffect } from "react";

export default function DefaultTabs({
  profile,
  userData,
  selectedTab,
  isYourProfile,
  setSelectedTab = () => {},
  checkDomain = () => {},
}) {
  
  useEffect(()=>{
    checkCurrentTab();
  },[]);

  const checkCurrentTab = ()=>{
    if(checkDomain() && isYourProfile){
      return;
    }
    setSelectedTab("milestones");
  }

  return (
    <div className="profile-second-row w-full profile-page-wrapper-padding">
      <div className="w-full mt-5 bg-white rounded-md px-2 md:px-24.5 container-max-width">
        <div className="flex flex-row justify-center flex-nowrap items-center gap-x-2 4sm:gap-x-7.6">
          <TabSelector
            isActive={selectedTab === "milestones"}
            gap="mr-0"
            colorType="black"
            paddingY="py-5.5 3sm:py-7.4 w-18.7 3sm:w-fit"
            onClick={() => setSelectedTab("milestones")}>
            <div className="w-full flex flex-col md:flex-row justify-center items-center text-4xs 3sm:text-sm md:text-base">
              <MilestoneIconSVG className="mb-1.6 md:mr-2 md:mb-0" />
              Milestones
            </div>
          </TabSelector>
          <div>
            <LineVerticalSVG />
          </div>
          <TabSelector
            isActive={selectedTab === "about"}
            gap="mr-0"
            colorType="black"
            paddingY="py-5.5 3sm:py-7.4 w-18.7 3sm:w-fit"
            onClick={() => setSelectedTab("about")}>
            <div className="w-full flex flex-col md:flex-row justify-center items-center text-4xs 3sm:text-sm md:text-base">
              <AboutIconSVG className="mb-1.6 md:mr-2 md:mb-0" />
              About
            </div>
          </TabSelector>
          <div>
            <LineVerticalSVG />
          </div>
          <TabSelector
            isActive={selectedTab === "photos-video"}
            gap="mr-0"
            colorType="black"
            paddingY="py-3 3sm:py-7.4 w-18.7 3sm:w-fit"
            onClick={() => setSelectedTab("photos-video")}>
            <div className="w-full flex flex-col md:flex-row justify-center items-center text-4xs 3sm:text-sm md:text-base">
              <PhotosIconSVG className="mb-1.6 md:mr-2 md:mb-0" />
              <span className="hidden 3sm:block">Photos & Videos</span>
              <span className="block 3sm:hidden">
                Photos &<br />
                Videos
              </span>
            </div>
          </TabSelector>

          {checkDomain() && isYourProfile && (
            <>
              <div>
                <LineVerticalSVG />
              </div>
              <TabSelector
                isActive={selectedTab === "resume"}
                gap="mr-0"
                colorType="black"
                paddingY="py-5.5 3sm:py-7.4 w-18.7 3sm:w-fit"
                onClick={() => setSelectedTab("resume")}>
                <div className="w-full flex flex-col md:flex-row justify-center items-center text-4xs 3sm:text-sm md:text-base">
                  <ResumeIconSVG className="mb-1.6 md:mr-2 md:mb-0" />
                  My Resumes
                </div>
              </TabSelector>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
