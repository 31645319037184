import React, { useCallback, useContext, useEffect, useState } from "react";
import EmptyPhotosIconSVG from "assets/svg/empty-photos-icon";
import EmptyVideosIconSVG from "assets/svg/empty-videos-icon";
import ViewButtonModal from "../milestones/media-button/watch";
import ViewImageGalleryModal from "../milestones/media-button/image-gallery";
import { FaEllipsisH, FaPlus } from "react-icons/fa";
import toast from "react-hot-toast";
import _ from "lodash";
import Loader from "components/loader/loader";
import PhotoUploadIconSVG from "assets/svg/photo-icon-upload";
import { useDialogHook } from "utils/customhooks";
import { IoClose } from "react-icons/io5";
import Button from "components/button/button";
import VideoUploadIconSVG from "assets/svg/video-icon-upload";
import LoadingIndicator from "assets/svg/loading-indicator";

import UpgradePremium from "components/subscription/upgrade-premium";
import LockOutlineIcon from "assets/svg/upgrade/lock-outline-icon";
import { SubscriptionContext } from "context/subscription";

import EmptyData from "./empty-data";
import PopInfo from "components/info-popup/pop-info";
import AddPhotoModal from "./add-photo-modal";
import Popup from "reactjs-popup";
import ReportPhotoVideo from "./report";
import { PiWarningOctagonLight } from "react-icons/pi";

const noop = () => {};

export const TypeOject = {
  emptIcon: {
    photo: <EmptyPhotosIconSVG />,
    video: <EmptyVideosIconSVG />,
  },
  loading: {
    photo: "Loading Photos ...",
    video: "Loading Videos ...",
  },
  accept: {
    photo: "image/*",
    video: "video/mp4,video/x-m4v,video/*",
  },
  uploadIcon: {
    photo: <PhotoUploadIconSVG />,
    video: <VideoUploadIconSVG />,
  },
};

export default function PhotosVideos({
  title = "",
  buttonTitle = "",
  type,
  userData = {},
  profile = {},
  isOwner = false,
}) {
  const { http } = global.services;

  const { isSubscribed } = useContext(SubscriptionContext);

  const emptyMessage = isOwner ? "No Images yet. Start adding your photos now." : "No Images yet.";

  // ===================== state ============================
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [loadmore, setLoadmore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [showDeleteBtn,setShowDeleteBtn] = useState(false)
  const viewVideo = useDialogHook(ViewButtonModal);
  const viewImage = useDialogHook(ViewImageGalleryModal);
  const addPhoto = useDialogHook(AddPhotoModal);
  const modalReport = useDialogHook(ReportPhotoVideo);
  const { user } = profile;
  let checkReported = null;
  const buttonProps = {
    edit: {
      btnName: "Edit",
      btnClass: "relative py-2 px-4 2sm:px-5.5 2sm:py-3.6 bg-modalGray hover:bg-gray",
      btnTextClass:
        "text-sm text-lightBlack font-semibold flex items-center space-x-1.6",
    },
  };

  const optionRef = React.useRef();
  const [showMoreOption, setShowMoreOption] = useState(false);

  if (!_.isEmpty(user?.reports))
    checkReported = user?.reports.find(
      (report) => report.user_id === userData?.user?.id
    );
  // ===================== functions =========================
  const onUploadImagesVideos = async (payload) => {
    console.log('::onUploadImagesVideos');
    payload.append("user_id", userData?.user?.id);
    payload.append("user_profile_id", userData?.profile?.id);
    payload.append("type", type);

    try {
      setUploadLoading(true);
      const { data } = await http.post(`public/user-galleries`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (data) {
        toast.success(data.message);
        getPhotoVideos("refetch");
        setUploadLoading(false);
        return "success";
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
      setUploadLoading(false);
      return false;
    }
  };

  const getPhotoVideos = useCallback(
    async (e) => {
      if (e !== "refetch" && e !== "loadmore") {
        setLoading(true);
      }
      try {
        let currentPage = page;
        if (e === "loadmore") {
          setLoadmore(true);
          currentPage = page + 1;
        }
        const result = await http.get(`public/user-galleries`, {
          user_profile_id: profile?.id,
          type: type,
          limit: 5,
          page: currentPage,
        });
        const res = result.data;
        if (!_.isEmpty(res.data)) {
          let newData = res.data;
          if (e === "loadmore") {
            //checking if empty
            if (_.isEmpty(list)) {
              setList(newData);
            } else {
              //if not empty
              let payload = _.clone(list || []);
              if (!_.isEmpty(newData)) {
                //loop
                newData.forEach((n) => {
                  payload.push(n);
                });
                //end loop
                setList(payload);
                setPage(currentPage);
              }
            }
          } else {
            setList(res.data);
          }

          setHasMore(res.current_page !== res.last_page);
          setLoading(false);
          setLoadmore(false);
        } else {
          setHasMore(false);
          setLoading(false);
          setLoadmore(false);
          if (e === "loadmore") {
            return toast.success(
              `All ${
                type === "photo" ? "Photos" : "Videos"
              } are already loaded.`
            );
          }
        }
      } catch (error) {
        toast.error(
          error?.data?.message || "It appears that something went wrong"
        );
        setLoading(false);
        setLoadmore(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, page]
  );

  const globalButtonClick = (e) => {
    onUploadImagesVideos(e);
  };

  const handleWatch = (url) => {
    viewVideo({ url }, (result) => {
      console.log("result =>", result);
    });
  };

  const handleViewImage = (media, focus) => {
    viewImage({ media, focus }, (result) => {
      console.log("result =>", result);
    });
  };

  const onDeleteImageVideos = async (record) => {
    try {
      setLoadingDelete((prev) => ({ ...prev, [`${type}_${record.id}`]: true }));
      const result = await http.delete(`public/user-galleries/${record.id}`);
      const res = result.data;
      if (res) {
        toast.success(res.message);
        //remove image
        let payloadImages = _.clone(list);
        _.remove(payloadImages, function (obj) {
          return obj.id === record.id;
        });
        setList(payloadImages);
        //end remove
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
      setLoadingDelete((prev) => ({
        ...prev,
        [`${type}_${record.id}`]: false,
      }));
    }
  };

  const onAdd = () => {
    addPhoto(
      {
        userData,
        profile,
        onUploadImagesVideos
      },
      (callback) => {
        if (callback) {
          getPhotoVideos();
        }
      }
    );
  };

  const reportPhotoVideo = (media = {}, type="photo") => {
    modalReport({ userData, media, type }, (callback) => {
      console.log(callback);
      if (callback && callback.success) {
        if (type === "photo") {
        }
      }
    });
  };

  //============================ useEffects ==========================
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (optionRef.current) {
          optionRef.current.close(); 
        }
      },
      true
    );
    if (profile) {
      getPhotoVideos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);


  return (
    <div className="w-full bg-white rounded-md px-5 2md:pl-29 2md:pr-24.5 py-10 md:pb-14 md:pt-8">
      <div className="w-full grid gap-y-8">
        {/* ===================== title ========================== */}
        <div className="flex justify-between items-center gap-3">
        <div className="w-full flex flex-row items-center space-x-2">
          {isOwner && !isSubscribed && <LockOutlineIcon />}
          <h1 className="text-2xl font-semibold text-lightBlack">{title}</h1>
          {/* pop info */} 
          <PopInfo containerClass="relative" type="photos" />
          <Button
              buttonName="Add Photos"
              buttonTextClass="text-xs 2sm:text-sm text-white font-semibold flex items-center justify-center"
              buttonRadius="6px"
              iconPosition="left"
              icon={<FaPlus className="mr-2" />}
              buttonClass="relative py-3.5 2sm:py-5 px-5 2sm:px-10 bg-primary hover:bg-darkPrimary w-full 2sm:w-fit"
              onClick={onAdd}
            />
        </div>
        {isOwner && isSubscribed && <>
        {showDeleteBtn ?
        <Button
              buttonName="Cancel"
              buttonTextClass={buttonProps.edit.btnTextClass}
              buttonClass={buttonProps.edit.btnClass}
              onClick={() => { 
                  setShowDeleteBtn((prev) => !prev); 
              }}
            /> 
          :
          <Popup
                ref={optionRef}
                position="bottom right"
                open={showMoreOption}
                onClose={() => setShowMoreOption(false)}
                arrow={false}
                contentStyle={{
                  marginTop: 10,
                  marginLeft: 5,
                  zIndex: 9999,
                }}
                trigger={
                  <button
                    className="bg-thBg w-10 h-10 rounded-md"
                    onClick={() => setShowMoreOption((open) => !open)}>
                    <FaEllipsisH className="m-auto text-1xs 2sm:text-base" />
                  </button>
                }>
              <div className="flex flex-col shadow-lg rounded-md bg-white">
                <ul>
                  <li className="hover:bg-gray hover:rounded-t-md">
                    <button
                      className="flex flex-row items-center text-lg px-6 py-2"
                      onClick={() => {
                        setShowDeleteBtn(true);   
                        optionRef.current.close()
                      }}>
                      Edit Photos
                    </button>
                  </li>
                  <li className="hover:bg-gray hover:rounded-b-md" >
                    <button
                      className="flex flex-row items-center text-lg px-6 py-2"
                      onClick={() => {
                        onAdd();
                        optionRef.current.close()
                      }}>
                      Add Photos
                    </button>
                  </li>
                </ul>
              </div>
            
          </Popup>
          }
        </>}
        
        </div>
        {/* =================== Body ============================= */}
        {
          !isOwner
            ? <>
              <div className="w-full">
                {loading ? (
                  <Loader message={TypeOject.loading[type]} />
                ) : (
                  <>
                    {_.isEmpty(list) ? (
                      <EmptyData
                        icon={TypeOject.emptIcon["video"]}
                        emptyMessage={emptyMessage}
                      />
                    ) : (
                      <ListImagesVideos
                        handleViewImage={(e, a) => handleViewImage(e, a)}
                        handleWatch={(e) => handleWatch(e)}
                        loading={uploadLoading}
                        loadingDelete={loadingDelete}
                        onCallBack={(e) => globalButtonClick(e)}
                        list={list}
                        type={type}
                        message={buttonTitle}
                        handleDelete={(e) => onDeleteImageVideos(e)}
                        readOnly={true}
                        checkReported={checkReported}
                        reportPhotoVideo={reportPhotoVideo}
                        showDeleteBtn={showDeleteBtn}
                      />
                    )}
                  </>
                )}
              </div>
            </>
            : (
              isSubscribed ? (
                <>
                  <div className="w-full">
                    {loading ? (
                      <Loader message={TypeOject.loading[type]} />
                    ) : (
                      <>
                        {_.isEmpty(list) ? (
                          <EmptyData
                            icon={TypeOject.emptIcon["video"]}
                            emptyMessage={emptyMessage}
                          />
                        ) : (
                          <ListImagesVideos
                            handleViewImage={(e, a) => handleViewImage(e, a)}
                            handleWatch={(e) => handleWatch(e)}
                            loading={uploadLoading}
                            loadingDelete={loadingDelete}
                            onCallBack={(e) => globalButtonClick(e)}
                            list={list}
                            type={type}
                            message={buttonTitle}
                            handleDelete={(e) => onDeleteImageVideos(e)}
                            onAdd={onAdd}
                            uploadLoading={uploadLoading}
                            showDeleteBtn={showDeleteBtn}
                          />
                        )}
                      </>
                    )}
                  </div>
      
                  {/* =================== Footer ============================= */}
                  <div className="w-full grid justify-center">
                    {_.isEmpty(list) && !loading && isOwner ? (
                      <button
                        onClick={onAdd}
                        className={`relative py-4 px-6 rounded-md bg-modalGray ${
                          uploadLoading ? "opacity-50" : "hover:bg-gray"
                        }`}>
                        <div className="flex items-center space-x-2.5">
                          {!loading && <FaPlus className=" w-3 h-3" />}
                          <span>{loading ? "Uploading ...." : "Add Photos"}</span>
                          {loading && <LoadingIndicator className="w-5 h-5" />}
                        </div>
                      </button>
                    ) : (
                      <>
                        {!loading && hasMore && (
                          <Button
                            onClick={() => getPhotoVideos("loadmore")}
                            buttonName="Load More"
                            buttonTextClass={`text-sm text-primary font-semibold ${
                              loadmore
                                ? "flex items-center space-x-2"
                                : "load-more-text"
                            }`}
                            buttonRadius="6px"
                            buttonClass={`relative py-4 px-6 border-2 border-primary rounded-md ${
                              loadmore ? "" : "load-more-hover"
                            }`}
                            disabled={loadmore}
                            loadingIcon={
                              loadmore ? (
                                <LoadingIndicator className="text-primary w-4 h-4" />
                              ) : null
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
                ) : (
                  <UpgradePremium styleProp="list" />
                )
            )
        }
      </div>
    </div>
  );
}

function ListImagesVideos({
  list = [],
  type = "",
  message = "",
  onCallBack = noop,
  loading = false,
  loadingDelete = {},
  handleViewImage = noop,
  handleWatch = noop,
  handleDelete = noop,
  onAdd = noop,
  uploadLoading = false,
  readOnly = false,
  reportPhotoVideo = noop,
  checkReported,
  showDeleteBtn=false
}) {
  return (
    <div className="w-full relative">
      <div className="w-full grid 1sm:grid-cols-2 1md:grid-cols-3 4md:grid-cols-4 lg:grid-cols-5 gap-5.6">
        {(list || []).map((obj, index) => (
          <Media
            key={`photo-container-${index}`}
            index={index}
            obj={obj}
            type={type}
            list={list}
            readOnly={readOnly}
            handleViewImage={handleViewImage}
            handleWatch={handleWatch}
            handleDelete={readOnly ? noop : handleDelete}
            loadingDelete={readOnly ? {} : loadingDelete}
            reportPhotoVideo={reportPhotoVideo}
            checkReported={checkReported}
            showDeleteBtn={showDeleteBtn}
          />
        ))}
      </div>
    </div>
  );
}

function Media({
  index,
  obj,
  type,
  list,
  readOnly = false,
  handleViewImage,
  handleWatch,
  handleDelete,
  loadingDelete = {},
  reportPhotoVideo,
  checkReported,
  showDeleteBtn=false
}) {
  const [showMoreOption, setShowMoreOption] = useState(); 
  return (
    <div
      className={`relative photos-videos-container cursor-pointer rounded-md${readOnly ? " photo-video-item-wrapper" : ""}`}
      key={`photo-${index}`}>
      {
        readOnly ?
          <>
            <div
              className={`flex justify-center items-center photo-video-hover-menu${showMoreOption ? " active" : ""}`}
              onClick={() => setShowMoreOption((open) => !open)}>
              <FaEllipsisH className="p-0 m-0" />
            </div>

            <Popup
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ marginTop: 10, marginLeft: 5 }}
              trigger={
                <div className="flex justify-center items-center photo-video-hover-menu-hidden"></div>
              }>
              <div className="flex flex-col shadow-lg rounded-md bg-white">
                <ul className="w-full">
                  <li className="hover:bg-gray hover:rounded-md">
                    <button
                      onClick={() => {
                        reportPhotoVideo(obj, "photo");
                        setShowMoreOption(false);
                      }}
                      className={`flex flex-row items-center text-lg px-4 py-2 gap-2 ${
                        checkReported && "bg-gray cursor-not-allowed"
                      }`}>
                      <PiWarningOctagonLight className="text-lg" />
                      {`${checkReported ? "Reported" : "Report Photo"}`}
                    </button>
                  </li>
                  {/* {isYourProfile && (
                    <li className="hover:bg-gray hover:rounded-md">
                      <button
                        onClick={() => {
                          // history.push("/settings", {
                          //   profile,
                          // });
                        }}
                        className="flex flex-row items-center text-lg px-4 py-2 gap-2">
                        <FiSettings className="text-lg" />
                        Settings
                      </button>
                    </li>
                  )} */}
                </ul>
              </div>
            </Popup>
          </>
          :
          <>
            {
              showDeleteBtn && 
              <button
                  className="h-8 w-8 absolute -right-4 -top-4 bg-black opacity-90 rounded-full"
                  onClick={() => handleDelete(obj)}
                  disabled={loadingDelete[`${type}_${obj.id}`]}>
                  {loadingDelete[`${type}_${obj.id}`] ? (
                    <LoadingIndicator className="h-4 w-4 text-white m-auto" />
                  ) : (
                    <IoClose className="text-white text-lg m-auto" />
                  )}
                </button>
            }
          </> 
      }
      {type === "photo" ? (
        <img
          onClick={() => handleViewImage(list, index + 1)}
          src={obj.media?.webp_url || obj.media?.url}
          alt={obj.media?.key}
          className="w-full h-full object-cover object-center rounded-md"
        />
      ) : (
        <img
          onClick={() => handleWatch(obj.media?.url)}
          src="https://вязники.рф/app/plugins/video-thumbnails/default.jpg" //assume for thumbnail for now
          alt={obj.media?.key}
          className="w-full h-full object-cover object-center rounded-md"
        />
      )}
      {/* <ReactPlayer
            light="https://вязники.рф/app/plugins/video-thumbnails/default.jpg"
            url={obj.media?.url}
            controls={false}
            style={{borderRadius:"6px"}}
            width="100%"
            height="100%"
          /> */}
    </div>
  );
}