import React, { useEffect, useState } from "react";
import Button from "components/button/button";
import UserGearIconSVG from "assets/svg/user-gear-icon";
import GraduationCapIconSVG from "assets/svg/graduation-cap-icon";
import ChatUserIconSVG from "assets/svg/chat-user-icon";
import BriefCaseExperienceIconSVG from "assets/svg/brief-case-icon";
import AwardsIconSVG from "assets/svg/awards-icon";
import FileUserRepresentationIconSVG from "assets/svg/file-user-icon";
import _ from "lodash";
import { HiOutlinePencil } from "react-icons/hi";
import { FiCheck } from "react-icons/fi";
import CreatableSelect from "components/select/creatable";
import TextArea from "components/textarea/textarea";
import { urlSerializer } from "utils/utils";
import LoadingIndicator from "assets/svg/loading-indicator";
import toast from "react-hot-toast";
import { useOccupations } from "redux/modules/occupation/hooks";
import PopInfo from "components/info-popup/pop-info";

const buttonProps = {
  edit: {
    btnName: "Edit",
    icon: <HiOutlinePencil />,
    btnClass: "relative py-3.6 px-5.5 bg-modalGray hover:bg-gray",
    btnTextClass:
      "text-sm text-lightBlack font-semibold flex items-center space-x-1.6",
  },
  save: {
    btnName: "Save Changes",
    icon: <FiCheck className="text-lg" />,
    btnClass: "relative py-3.6 px-5.5 bg-primary hover:bg-darkPrimary",
    btnTextClass:
      "text-sm text-white font-semibold flex items-center space-x-1.6",
  },
};

const ICONS = {
  skills: <UserGearIconSVG className="m-auto" />,
  education: <GraduationCapIconSVG className="m-auto" />,
  training: <ChatUserIconSVG className="m-auto" />,
  experience: <BriefCaseExperienceIconSVG className="m-auto" />,
  awards: <AwardsIconSVG className="m-auto" />,
  representation: <FileUserRepresentationIconSVG className="m-auto" />,
};

export default function ProfessionalInformation(props) {
  const { profile, userData, setUserData } = props;
  const { http, localstorage } = global.services;
  const {
    refresh: refreshOccupation,
    initialize: initializeOccupation,
    occupations,
    setOccupations,
    addOccupation,
  } = useOccupations();

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    id: null,
    skills: [],
    education: null,
    training: null,
    experience: null,
  });
  const [professional, setProfessional] = useState([
    {
      label: "Abilities / Skills / Specialties",
      value: userData?.profile?.occupations,
      icon: ICONS.skills,
      form: "select",
      formProps: {
        name: "skills",
      },
    },
    {
      label: "Education",
      value: "",
      icon: ICONS.education,
      form: "textarea",
      formProps: {
        name: "education",
      },
    },
    {
      label: "Training",
      value: "",
      icon: ICONS.training,
      form: "textarea",
      formProps: {
        name: "training",
      },
    },
    {
      label: "Experience",
      value: "",
      icon: ICONS.experience,
      form: "textarea",
      formProps: {
        name: "experience",
      },
    },
    {
      label: "Awards",
      value: "",
      icon: ICONS.awards,
      form: "textarea",
      formProps: {
        name: "awards",
      },
    },
    {
      label: "Representation",
      value: "",
      icon: ICONS.representation,
      form: "textarea",
      formProps: {
        name: "representation",
      },
    },
  ]);

  //============================== functions ========================
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "skills") {
      const valueMap = value.map((val) => val.label);
      setState((prev) => ({
        ...prev,
        [name]: valueMap,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const createPFInformation = async () => {
    try {
      const { data } = await http.post(`public/professional-informations`, {
        user_profile_id: profile.id,
        skills: [],
        education: "",
        training: "",
        experience: "",
      });
      if (data) {
        toast.message("Professional Information Successfully created.");
        getInfo();
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  const getInfo = async () => {
    try {
      const { data } = await http.get(`public/professional-informations`, {
        user_profile_id: profile.id,
      });
      if (!_.isEmpty(data)) {
        setState({
          id: data.id,
          skills: userData?.profile?.occupations,
          education: data.education ?? "",
          training: data.training ?? "",
          experience: data.experience ?? "",
          awards: data.awards ?? "",
          representation: data.representation ?? "",
        });
        setProfessional([
          {
            label: "Abilities / Skills / Specialties",
            value: userData?.profile?.occupations,
            icon: ICONS.skills,
            form: "select",
            formProps: {
              name: "skills",
            },
          },
          {
            label: "Education",
            value: data.education ?? "",
            icon: ICONS.education,
            form: "textarea",
            formProps: {
              name: "education",
            },
          },
          {
            label: "Training",
            value: data.training ?? "",
            icon: ICONS.training,
            form: "textarea",
            formProps: {
              name: "training",
            },
          },
          {
            label: "Experience",
            value: data.experience ?? "",
            icon: ICONS.experience,
            form: "textarea",
            formProps: {
              name: "experience",
            },
          },
          {
            label: "Awards",
            value: data.awards ?? "",
            icon: ICONS.awards,
            form: "textarea",
            formProps: {
              name: "awards",
            },
          },
          {
            label: "Representation",
            value: data.representation ?? "",
            icon: ICONS.representation,
            form: "textarea",
            formProps: {
              name: "representation",
            },
          }
        ]);
        //update state to false
      } else {
        createPFInformation();
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  const onClickSave = async () => {
    try {
      if (state.id) {
        setLoading(true);
        const payload = _.clone(state);
        delete payload.id;
        if (!_.isEmpty(payload)) {
          const { data } = await http.put(
            `public/professional-informations/${state.id}`,
            payload
          );
          if (data?.professional_info?.id) {
            toast.success("Professional Information Successfully Updated");
            const resultValue = data.professional_info;
            const profData = data.profile;
            // ========= set user data =================
            const newUserData = {
              ...userData,
              profile: {
                ...userData.profile,
                occupations: profData.occupations,
              },
            };
            localstorage.set("user_data", JSON.stringify(newUserData));
            setUserData(newUserData);
            //========= set professional =================
            setProfessional([
              {
                label: "Abilities / Skills / Specialties",
                value: profData.occupations,
                icon: ICONS.skills,
                form: "select",
                formProps: {
                  name: "skills",
                },
              },
              {
                label: "Education",
                value: resultValue.education ?? "",
                icon: ICONS.education,
                form: "textarea",
                formProps: {
                  name: "education",
                },
              },
              {
                label: "Training",
                value: resultValue.training ?? "",
                icon: ICONS.training,
                form: "textarea",
                formProps: {
                  name: "training",
                },
              },
              {
                label: "Experience",
                value: resultValue.experience ?? "",
                icon: ICONS.experience,
                form: "textarea",
                formProps: {
                  name: "experience",
                },
              },
              {
                label: "Awards",
                value: data.awards ?? "",
                icon: ICONS.awards,
                form: "textarea",
                formProps: {
                  name: "awards",
                },
              },
              {
                label: "Representation",
                value: data.representation ?? "",
                icon: ICONS.representation,
                form: "textarea",
                formProps: {
                  name: "representation",
                }
              }
            ]);
            //update state to false
            setLoading(false);
            setEdit(false);
          }
        }
      } else {
        return toast.error("Professional Information cannot be save.");
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "It appears that something went wrong"
      );
      setLoading(false);
      setEdit(false);
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };

  const getOccupations = async () => {
    try {
      const url = urlSerializer("public/occupations", {
        user_profile_id: profile.id,
      });
      const { data } = await http.get(url);
      const options = data.map((opt) => ({
        label: opt.name,
        value: opt.id,
      }));
      setOccupations(options);
    } catch (err) {
      console.error("getOccupations", err);
    }
  };

  const createOccupation = async (option) => {
    try {
      let { data } = await http.post("public/occupations", {
        user_id: userData.user.id,
        user_profile_id: profile.id,
        occupation: option,
      });
      console.log("data", data);
      console.log("value", option);
      setState((prev) => ({
        ...prev,
        skills: [...prev.skills, option],
      }));
      addOccupation({
        label: option,
        value: data?.data?.id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //============================== useEffects ========================
  useEffect(() => {
    if (profile && userData) {
      getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, profile]);

  useEffect(() => {
    if (initializeOccupation) getOccupations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOccupation, initializeOccupation]);

  return (
    <div className="w-full bg-white rounded-md px-5 2md:pl-29 2md:pr-24.5 py-10 md:pb-14 md:pt-8">
      <div className="w-full flex justify-between flex-wrap items-center mb-8">
        <div className="flex flex-row gap-x-2 items-center">
          <h1 className="text-base 2sm:text-2xl font-semibold text-lightBlack">
            Professional Information
          </h1>
          <PopInfo containerClass="relative" type="professional_information" />
        </div>
        {props.isYourProfile && (
          <Button
            buttonName={
              edit ? buttonProps.save.btnName : buttonProps.edit.btnName
            }
            buttonTextClass={
              edit
                ? buttonProps.save.btnTextClass
                : buttonProps.edit.btnTextClass
            }
            iconPosition="left"
            icon={edit ? buttonProps.save.icon : buttonProps.edit.icon}
            buttonRadius="6px"
            buttonClass={
              edit ? buttonProps.save.btnClass : buttonProps.edit.btnClass
            }
            loadingIcon={
              loading ? (
                <LoadingIndicator className="text-white w-4 h-4" />
              ) : null
            }
            disabled={loading}
            onClick={() => {
              if (edit) {
                onClickSave();
              } else {
                setEdit((prev) => !prev);
              }
            }}
          />
        )}
      </div>
      <div className="w-full grid gap-y-12 max-w-screen-2smd">
        {/* ==================== BIO =================================== */}
        <div className="w-full grid gap-y-12">
          {professional.map((obj, index) => (
            <div
              className={`${
                props.isYourProfile
                  ? "w-full flex flex-start items-start space-x-6"
                  : obj?.value
                  ? "w-full flex flex-start items-start space-x-6"
                  : "hidden"
              }`}
              key={index}>
              <div className="w-15 h-15 rounded-full bg-bGray grid">
                {obj.icon}
              </div>
              <div style={{ width: "calc(100% - 60px)" }}>
                <h3 className="text-base 2sm:text-xl text-lightBlack font-semibold">
                  {obj.label}
                </h3>
                {edit ? (
                  <div className="w-full mt-3">
                    <FormBuilder
                      type={obj.form}
                      name={obj.formProps.name}
                      placeholder={obj.label}
                      onChange={handleInputChange}
                      defaultValue={obj.value}
                      occupations={occupations}
                      onCreateOption={createOccupation}
                      skills={state.skills}
                    />
                  </div>
                ) : (
                  <>
                    {typeof obj.value !== "object" ? (
                      <p className="text-base leading-6.7 tracking-tightest text-darkerGray mt-1.5">
                        {_.isEmpty(obj.value) ? "--" : obj.value}
                      </p>
                    ) : (
                      <div className="w-full flex flex-start flex-row flex-wrap gap-2">
                        {(obj.value || []).map((value, index) => {
                          return (
                            <div
                              className="py-2 px-3.6 bg-thBg text-sm font-semibold text-lightBlack rounded-md"
                              key={index}>
                              {value}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function FormBuilder(props) {
  const {
    type,
    name,
    placeholder,
    onChange,
    defaultValue,
    occupations,
    onCreateOption,
    skills = [],
  } = props;
  let selectDefaultValue = [];
  if (name === "skills" && skills) {
    selectDefaultValue = skills.map((value) => {
      let obj = _.find(occupations, { label: value });
      if (!_.isEmpty(obj)) {
        return { label: value, value: obj?.value };
      }
      return false;
    });
  }

  if (type === "textarea") {
    return (
      <TextArea
        defaultValue={defaultValue}
        name={name}
        inputClass="p-4.5 text-base leading-6 text-lightBlack"
        rows={4}
        type="text"
        onChange={onChange}
      />
    );
  } else if (type === "select") {
    return (
      <CreatableSelect
        isMulti
        options={occupations}
        placeholder={placeholder}
        name={name}
        value={selectDefaultValue || null}
        height={58}
        onChange={(e) => onChange({ target: { name: name, value: e } })}
        onCreateOption={onCreateOption}
      />
    );
  }
  return <></>;
}
