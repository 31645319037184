import React, { useContext, useEffect, useState } from "react";
import Button from "components/button/button";
import Input from "components/input/input";
import LoadingIndicator from "assets/svg/loading-indicator";
import { useHistory } from "react-router-dom";
import Select from "components/select/select";
import SendIconSVG from "assets/svg/send-icon";
import { FaMinus } from "react-icons/fa";
import { useDialogHook } from "utils/customhooks";
import InvitationStatusSentModal from "./component/invitation-status-sent";
import RemoveUserModel from "./component/remove-user-modal";
import _ from "lodash";
import update from "immutability-helper";
import { useAccessPermissions } from "redux/modules/access-permissions/hooks";
import { useUserAccess } from "redux/modules/user-access/hooks";
import toast from "react-hot-toast";
import ProfileDefault from "assets/images/profile_default.png";
import UpgradePremium from "components/subscription/upgrade-premium";
import LockOutlineIcon from "assets/svg/upgrade/lock-outline-icon";
import { SubscriptionContext } from "context/subscription";
import PopInfo from "components/info-popup/pop-info";

export default function SettingsAccess({ userData, profile }) {
  const { http } = global.services;
  const history = useHistory();
  const removeUserModal = useDialogHook(RemoveUserModel);
  const invitationStatus = useDialogHook(InvitationStatusSentModal);
  const [state, setState] = useState({
    email: "",
    permission: "",
  });
  const [inviteLoading, setInviteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isSubscribed, subscription } = useContext(SubscriptionContext);

  const {
    initialize: initializeAccess,
    permissions: accessPermissions,
    setPermissions,
  } = useAccessPermissions();

  const { initialize: initializeUsers, users, setUsers } = useUserAccess();

  const [list, setList] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [error, setError] = useState({});

  const handleSave = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      list.forEach((u, i) => {
        formData.append(`access[${i}][id]`, u.id);
        formData.append(`access[${i}][permission_id]`, u.permission_id);
        formData.append(`access[${i}][status]`, u.status);
      });
      formData.append("_method", "PUT");
      let { data } = await http.post(
        "public/user-accesses/update-all",
        formData
      );
      toast.success(data.message);
      getUsers();
    } catch (err) {
      console.log(err);
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  const onSendInvite = async () => {
    let errors = "";
    const checkPoint = users.filter((u) => u.invitee?.email === state.email);
    if (_.isEmpty(checkPoint)) {
      try {
        setInviteLoading(true);
        await http.post("public/user-accesses", {
          user_id: profile.user.id,
          email: state.email,
          permission_id: state.permission.value,
        });
        invitationStatus({ email: state.email, success: true });
        setState((prev) => ({
          ...prev,
          email: "",
          permission: "",
        }));
        getUsers();
      } catch (err) {
        if (err.status === 422) {
          errors = err.data.errors;
        } else if (err.status === 400) {
          const { message } = err.data;
          invitationStatus({ email: state.email, success: false, message });
        } else if (err.status === 404) {
          invitationStatus({ email: state.email, success: false });
        }
      } finally {
        setInviteLoading(false);
      }
    } else {
      toast.error("The email you entered is already exist.");
    }
    setError(errors || {});
  };

  const onChange = (value, el) => {
    setState((prev) => ({
      ...prev,
      [el]: value || "",
    }));
  };

  const onChangePermission = (value, index) => {
    const payload = _.clone(list);
    let result = update(payload, {
      [index]: {
        permission_id: { $set: value },
      },
    });
    console.log(result);
    setList(result);
  };

  const onRemove = async (index, id) => {
    let payload = _.clone(list);
    let result = update(payload, { $splice: [[index, 1]] }); //remove
    let access = list.filter((l) => l.id === id)[0];
    removeUserModal({ access }, (callback) => {
      if (callback) {
        setList(result);
        setUsers(result);
      }
    });
  };

  const getPermissions = async () => {
    try {
      let { data } = await http.get("public/permissions");
      setPermissions(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getUsers = async () => {
    try {
      let { data } = await http.get(
        `public/user-accesses/${profile.user.id}/access`
      );
      setUsers(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (initializeAccess) {
      getPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeAccess]);

  useEffect(() => {
    if (initializeUsers) {
      getUsers();
    } else {
      setList(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeUsers, users]);

  useEffect(() => {
    let options = accessPermissions.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
    setPermissionOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessPermissions]);

  return isSubscribed ? (
    <div className="mt-6 w-full container-max-width bg-white rounded-md">
      {/* content */}
      {/* content */}
      <div className="pt-12.5 pb-10 px-7.5 1md:pl-24.5 1md:pr-56">
        <div className="w-full 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Invite User
              </p>
              <PopInfo containerClass="relative" type="invite_user" />
            </div>
            <p className="text-sm text-darkerGray">
              Invite team members and assign their permissions.
            </p>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="w-full space-y-3.5 8sm:flex 8sm:space-x-3.5 8sm:space-y-0">
              <div className="w-full 8sm:w-9/20">
                <Input
                  inputContainerClass="form-input-container"
                  value={state?.email}
                  name="email"
                  placeholder="tedlasso@gmail.com"
                  noLabelPaddingY="1rem"
                  type="email"
                  onChange={(e) => onChange(e?.target?.value, "email")}
                  errorMessage={error.email}
                />
              </div>
              <div className="w-full 8sm:w-6/20">
                <Select
                  formContainer="flex-1"
                  options={permissionOptions}
                  placeholder="Please Select"
                  onChange={(e) => onChange(e, "permission")}
                  height={58}
                  value={
                    state.permission && {
                      label: state.permission.label,
                      value: state.permission.value,
                    }
                  }
                  name="permission"
                  errorMessage={error.permission_id}
                />
              </div>
              <div className="w-full 8sm:w-5/20">
                <Button
                  buttonWidth="100%"
                  buttonName="Send Invitation"
                  buttonTextClass="text-xs text-white font-semibold flex items-center justify-center"
                  buttonRadius="6px"
                  buttonClass="relative py-5 bg-lightBlack "
                  onClick={onSendInvite}
                  iconPosition="left"
                  icon={
                    inviteLoading ? (
                      <LoadingIndicator className="text-white w-4 h-4 mr-2.5" />
                    ) : (
                      <SendIconSVG className="text-white mr-2.5" />
                    )
                  }
                  disabled={!state.email || !state.permission || inviteLoading}
                />
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="w-full mt-5 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">Users</p>
              <PopInfo
                containerClass="relative"
                type="users"
                responsive={{
                  left: "90px",
                  right: "90px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="w-full grid gap-y-4.5">
              {list.map((obj, index) => (
                <div
                  className="w-full 4sm:flex 4sm:items-center 4sm:justify-between"
                  key={index}>
                  <div className="mb-5 4sm:mb-0">
                    <div className="flex items-center space-x-5">
                      <div className="w-15 h-15">
                        <img
                          src={
                            obj?.profile?.profile_image_url || ProfileDefault
                          }
                          alt="milestone-profile"
                          className="rounded-md w-15 h-15"
                        />
                      </div>
                      <div>
                        <p className="text-lg text-lightBlack font-bold leading-5.5 tracking-tighter">
                          {obj?.invitee?.first_name} {obj?.invitee?.last_name}
                        </p>
                        <p className="text-sm text-darkerGray  leading-5.4">
                          {obj?.invitee?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center space-x-5">
                      <div className="w-full 4sm:w-44">
                        <Select
                          formContainer="flex-1"
                          options={permissionOptions}
                          placeholder="Please Select"
                          defaultValue={
                            permissionOptions.filter(
                              (ap) => ap.value === obj.permission_id
                            )[0]
                          }
                          onChange={(e) => onChangePermission(e?.value, index)}
                          height={58}
                          name="permission"
                        />
                      </div>
                      <button
                        className="w-6.5 h-6.5 bg-lightBlack rounded-full"
                        onClick={() => onRemove(index, obj.id)}>
                        <FaMinus className="text-white m-auto" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="w-full mt-5 3md:flex">
          <div className="w-full mb-5 3md:w-6/20 3md:mb-0">
            <div className="pt-4.5 w-full flex flex-row gap-x-2 items-center">
              <p className="text-lg text-lightBlack font-semibold">
                Permission Levels
              </p>
              <PopInfo
                containerClass="relative"
                type="permission_levels"
                responsive={{
                  left: "180px",
                  right: "180px",
                }}
              />
            </div>
          </div>
          {/* forms */}
          <div className="w-full 3md:w-14/20">
            <div className="w-full">
              <ul className="w-full">
                {accessPermissions.map((obj, index) => (
                  <li
                    className="w-full py-4.5 border-b border-pBorder"
                    key={index}>
                    <p className="text-lg text-lightBlack font-bold leading-5.5 tracking-tighter">
                      {obj.name}
                    </p>
                    <p className="text-sm text-darkerGray  leading-5.4">
                      {obj.description}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="py-5.5 px-7.5 1md:pl-24.5 1md:pr-56 border-t-2 border-gray">
        <div className="w-full flex justify-end">
          <Button
            buttonName="Cancel"
            buttonTextClass="text-xs text-lightBlack font-semibold"
            buttonRadius="6px"
            buttonClass="relative py-4 px-7.5 bg-thBg mr-3.5 hover:bg-gray"
            onClick={() => history.goBack()}
          />
          <Button
            buttonName="Save Changes"
            buttonTextClass="text-xs text-white font-semibold flex items-center"
            buttonRadius="6px"
            buttonClass="relative py-4 px-10 bg-primary hover:bg-darkPrimary"
            onClick={handleSave}
            loadingIcon={
              loading ? (
                <LoadingIndicator className="text-white w-4 h-4 ml-2" />
              ) : null
            }
            disabled={!list.length || loading}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="mt-6 w-full bg-white rounded-md">
      <div className="pt-12.5 pb-10 px-7.5 1md:px-24.5">
        {/** title */}
        <div className="w-full flex flex-row items-center space-x-3 mb-10">
          {subscription.name !== "Premium Account" && <LockOutlineIcon />}
          <h1 className="text-xl font-semibold text-lightBlack">User Access</h1>
          <PopInfo
            containerClass="relative"
            type="user_access"
            responsive={{
              left: "180px",
              right: "180px",
            }}
          />
        </div>
        {/** content */}
        <UpgradePremium styleProp="list" />
      </div>
    </div>
  );
}
