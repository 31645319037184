import React, { useState } from "react";
import Modal from "components/global/modal";
import ReactPlayer from "react-player";
import "./styles.css";
import { FaTimes } from "react-icons/fa";
import { TikTokEmbed } from 'react-social-media-embed';

export default function WatchMediaModal({ hide, ...props }) {
  const { title, url, tiktok } = props;
  const [play, setPlay] = useState(true);
  return (
    <Modal
      width="w-full sm:w-400 md:w-700 lg:w-10/20"
      contentClassName="rounded-lg overflow-auto custom-style"
      wrapperClassName="overflow-visible custom-style shadow-none"
      image_gallery={true}
    >
      <div className="w-full">
        <div className="flex justify-between items-center mb-4">
          <p className="text-xl text-white">
            <span className="font-bold mr-1">Watch:</span>
            {title}
          </p>
          {/* close */}
          <button onClick={() => hide()}>
            <FaTimes className="text-white text-xl" />
          </button>
        </div>

        {tiktok ? (
          <div className="flex justify-center">
            <TikTokEmbed url={url} />
          </div>
        ) : (
          <div className="wrapper">
            <ReactPlayer
              className="player"
              url={url}
              controls={true}
              playing={play}
              width="100%"
              height="100%"
              onPlay={() => setPlay(true)}
              onPause={() => setPlay(false)}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
