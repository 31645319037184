import React, { useEffect, useRef, useState } from "react";
import {
    BsChatText,
    BsShare,
    BsPersonPlus,
    BsPersonFillCheck,
} from "react-icons/bs";
import { FaEllipsisH } from "react-icons/fa";
import Button from "components/button/button";
import { Link, useHistory } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { urlSerializer } from "utils/utils";
import _ from "lodash";
import Popup from "reactjs-popup";
import SocialsModal from "./share/socials";
import { useDialogHook } from "utils/customhooks";
import LoadingIndicator from "assets/svg/loading-indicator";
import { social_icons } from "utils/constant";
import HeroOtherIcon from "assets/svg/hero-other";
import { PiWarningOctagonLight } from "react-icons/pi";
import ToolTip from "components/tootltip/tootltip";
import styled from "styled-components";

export default function ProfileModal({
    userData = {},
    profile = {},
    hasPermission,
    isYourProfile,
    onClickMessage = () => {},
    onReport = () => {},
    showSocialMedia = true,
    isSticky
}) {
    const { http } = global.services;
    const history = useHistory();
    const shareRef = useRef();
    const [open, setOpen] = useState(false);
    const [socials, setSocials] = useState([]);
    const [follow, setFollow] = useState(0);
    const shareModal = useDialogHook(SocialsModal);
    const [showMoreOption, setShowMoreOption] = useState();
    const [state, setState] = useState({
        profileUrl: "",
    });
    const [submitLoading, setSubmitLoading] = useState(true);
    const optionRef = useRef();

    const { user } = profile;
    let checkRerported = null;
    if (!_.isEmpty(user?.reports))
        checkRerported = user?.reports.find(
            (report) => report.user_id === userData?.user?.id
        );

    const getSocialMedia = async () => {
        let socialMedia = [];
        try {
            const { data } = await http.get("public/social-medias", {
                user_profile_id: profile?.id,
                user_id: userData?.profile?.id,
            });
            const { social_media, follow } = data;
            if (follow) {
                setFollow(follow?.status);
            }
            if (social_media.length > 0) {
                social_media.forEach((sm) => {
                    if (sm.media_type === "social") {
                        socialMedia.push({
                            type: sm.type,
                            link: sm.link,
                            fav_icon: sm?.fav_icon?.url || null,
                            media_type: "social",
                        });
                    }
                });
                setSocials(socialMedia);
            }
        } catch (err) {
            console.log("getPosition", err);
        }
        setSubmitLoading(false);
    };

    const showMoreSocial = () => {
        shareModal(
            {
                url: state.profileUrl,
                image: profile.profile_image_url,
            },
            (callBack) => {
                console.log("showMoreSocial callback", callBack);
            }
        );
    };

    const onFollow = async () => {
        setSubmitLoading(true);
        const url = urlSerializer("/follow", {
            followed_id: profile?.id,
            follower_id: userData?.profile?.id,
        });
        const { data } = await http.post(url);
        const { follow } = data;
        setFollow(follow?.status);
        setSubmitLoading(false);
    };

    useEffect(() => {
        if (profile?.id) {
            getSocialMedia();
            let url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${profile.user.username}/profile/${profile.id}`;
            setState((prev) => ({
                ...prev,
                profileUrl: url,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    useEffect(() => {
        window.addEventListener(
            "scroll",
            () => {
                if (shareRef.current) {
                    shareRef.current.close();
                }

                if (optionRef.current) {
                    optionRef.current.close();
                }
            },
            true
        );
        return () => {
            window.removeEventListener("scroll", () => {});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-4 3md:mt-0 grid gap-y-4 2sm:flex 2sm:justify-between 2sm:items-center 3md:block">
            <div className={`flex gap-2 ${isSticky ? 'justify-end': 'flex justify-center 2sm:justify-start flex-wrap 3md:flex-nowrap 3md:justify-end space-x-1.5 profile-right'}`}>
                {!isYourProfile &&
                    !_.isEmpty(userData) &&
                    !_.isEmpty(user?.username) && (
                        <div className="flex gap-2">
                            <button
                                className={`p-2 block sm:hidden bg-thBg hover:bg-gray flex flex-row items-center 3md:mb-0 3md:py-4 px-3 text-xs 3md:text-sm text-lightBlack font-semibold`}
                                onClick={onClickMessage}
                                style={{ borderRadius: "6px" }}
                            >
                                <BsChatText className="text-lg" />
                                <span className="hidden sm:block">Message</span>
                            </button>
                            <div className="hidden sm:block">
                                <Button
                                    buttonName="Message"
                                    buttonTextClass="text-xs 3md:text-sm text-lightBlack font-semibold flex items-center"
                                    iconPosition="left"
                                    icon={
                                        <BsChatText className="mr-1.5 3md:mr-3" />
                                    }
                                    buttonRadius="6px"
                                    buttonClass="relative py-2.5 3md:mb-0 3md:py-4 px-3.5 bg-thBg hover:bg-gray"
                                    onClick={onClickMessage}
                                />
                            </div>
                            {!_.isEmpty(user?.username) && (
                                <>
                                    {!isYourProfile && (
                                        <button
                                            className="flex flex-row items-center 3md:mb-0 3md:py-4 px-3 bg-thBg hover:bg-gray text-xs 3md:text-sm text-lightBlack font-semibold"
                                            onClick={showMoreSocial}
                                            style={{ borderRadius: "6px" }}
                                        >
                                            <BsShare className="mr-3" />
                                            <span className="hidden sm:block">Share</span>
                                        </button>
                                    )}
                                </>
                            )}
                            <button
                                className={`${
                                    follow === 0
                                        ? "bg-thBg hover:bg-gray text-lightBlack"
                                        : "bg-primary hover:bg-darkPrimary text-white"
                                } block sm:hidden flex flex-row items-center 3md:mb-0 3md:py-4 px-3 text-xs 3md:text-sm text-lightBlack font-semibold`}
                                onClick={onFollow}
                                style={{ borderRadius: "6px" }}
                            >
                                {follow === 0 ? (
                                    <BsPersonPlus className=" text-lg" />
                                ) : (
                                    <BsPersonFillCheck className="text-lg" />
                                )}
                                {submitLoading ? (
                                    <LoadingIndicator className="text-blue-500 w-4 h-4 ml-2" />
                                ) : null}
                            </button>
                            <div className="hidden sm:block">
                                <Button
                                    buttonName={`${
                                        follow === 0 ? "Follow" : "Following"
                                    }`}
                                    buttonTextClass={`text-xs 3md:text-sm  ${
                                        follow === 0
                                            ? "text-lightBlack"
                                            : "text-white"
                                    } font-semibold flex items-center`}
                                    iconPosition="left"
                                    icon={
                                        follow === 0 ? (
                                            <BsPersonPlus className="mr-1.5 3md:mr-3 text-lg" />
                                        ) : (
                                            <BsPersonFillCheck className="mr-1.5 3md:mr-3 text-lg" />
                                        )
                                    }
                                    buttonRadius="6px"
                                    buttonClass={`relative py-2.5 3md:mb-0 3md:py-4 px-3.5 ${
                                        follow === 0
                                            ? "bg-thBg hover:bg-gray"
                                            : "bg-primary hover:bg-darkPrimary"
                                    } `}
                                    loadingIcon={
                                        submitLoading ? (
                                            <LoadingIndicator className="text-blue-500 w-4 h-4 ml-2" />
                                        ) : null
                                    }
                                    onClick={onFollow}
                                />
                            </div>
                            <Popup
                                ref={optionRef}
                                position="bottom right"
                                open={showMoreOption}
                                onClose={() => setShowMoreOption(false)}
                                arrow={false}
                                contentStyle={{ marginTop: 10, marginLeft: 5 }}
                                trigger={
                                    <button
                                        className="py-0 bg-thBg rounded-md hidden 3md:block 3md:py-4 3md:px-5"
                                        onClick={() =>
                                            setShowMoreOption((open) => !open)
                                        }
                                    >
                                        <FaEllipsisH className="p-0 m-0" />
                                    </button>
                                }
                            >
                                <div className="flex flex-col shadow-lg rounded-md bg-white">
                                    <ul className="w-full">
                                        <li className="hover:bg-gray hover:rounded-md">
                                            <button
                                                onClick={() => {
                                                    onReport(
                                                        {},
                                                        profile,
                                                        "profile"
                                                    );
                                                }}
                                                className={`flex flex-row items-center text-lg px-4 py-2 gap-2 ${
                                                    checkRerported &&
                                                    "bg-gray cursor-not-allowed"
                                                }`}
                                            >
                                                <PiWarningOctagonLight className="text-lg" />
                                                {`${
                                                    checkRerported
                                                        ? "Reported"
                                                        : "Report Milestone"
                                                }`}
                                            </button>
                                        </li>
                                        {isYourProfile && (
                                            <li className="hover:bg-gray hover:rounded-md">
                                                <button
                                                    onClick={() => {
                                                        history.push(
                                                            "/settings",
                                                            {
                                                                profile,
                                                            }
                                                        );
                                                    }}
                                                    className="flex flex-row items-center text-lg px-4 py-2 gap-2"
                                                >
                                                    <FiSettings className="text-lg" />
                                                    Settings
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </Popup>
                        </div>
                    )}
                {!_.isEmpty(user?.username) && (
                    <>
                        {isYourProfile && (
                            <button
                                className="flex flex-row items-center 3md:mb-0 3md:py-4 px-3 bg-thBg hover:bg-gray text-xs 3md:text-sm text-lightBlack font-semibold share-own"
                                onClick={showMoreSocial}
                                style={{ borderRadius: "6px" }}
                            >
                                <BsShare className="3md:mr-3" />
                                {!isSticky &&(
                                    <> &nbsp;&nbsp; <span>Share</span></>
                                )}
                            </button>
                        )}
                    </>
                )}
                {hasPermission && !_.isEmpty(user?.username) && (
                    <>
                        {/* {!isYourProfile && (
                            <button
                                className="flex flex-row items-center 3md:mb-0 3md:py-4 px-3 bg-thBg hover:bg-gray text-xs 3md:text-sm text-lightBlack font-semibold"
                                onClick={showMoreSocial}
                                style={{ borderRadius: "6px" }}
                            >
                                <BsShare className="3md:mr-3" />
                                <span className="hidden 3md:block">Share</span>
                            </button>
                        )} */}

                        <Popup
                            ref={optionRef}
                            position="bottom right"
                            open={showMoreOption}
                            onClose={() => setShowMoreOption(false)}
                            arrow={false}
                            contentStyle={{ marginTop: 10, marginLeft: 5 }}
                            trigger={
                                <button
                                    className="py-0 bg-thBg rounded-md hidden 3md:block 3md:py-4 3md:px-5"
                                    onClick={() =>
                                        setShowMoreOption((open) => !open)
                                    }
                                >
                                    <FaEllipsisH className="p-0 m-0" />
                                </button>
                            }
                        >
                            <div className="flex flex-col shadow-lg rounded-md bg-white">
                                <ul className="w-full">
                                    <li className="hover:bg-gray hover:rounded-md">
                                        <button
                                            onClick={() => {
                                                history.push("/settings", {
                                                    profile,
                                                });
                                            }}
                                            className="flex flex-row items-center text-lg px-4 py-2 gap-2"
                                        >
                                            <FiSettings className="text-lg" />
                                            Settings
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </Popup>
                        {isYourProfile && (
                            <Button
                                buttonTextClass="text-xs 3md:text-sm text-lightBlack font-semibold flex items-center justify-center"
                                iconPosition="left"
                                icon={<FaEllipsisH className="text-base" />}
                                buttonRadius="6px"
                                buttonClass="relative py-2.5 block 3md:hidden px-3 bg-thBg hover:bg-gray"
                                onClick={() =>
                                    history.push("/settings", { profile })
                                }
                            />
                        )}
                    </>
                )}
            </div>
            {showSocialMedia && (
                <div className="3md:mt-10 relative flex justify-center 2sm:justify-end w-full">
                    <div className="flex justify-center 3md:justify-end items-center gap-1.5 3md:gap-2.5 flex-wrap">
                        {socials.slice(0, 5).map((obj, index) => (
                            <Link
                                className="group/link"
                                to={{
                                    pathname: obj.link,
                                }}
                                target="_blank"
                                key={index}
                            >
                                <ToolTip value={obj.link}>
                                    <p className="w-9 h-9 3md:w-12 3md:h-13 bg-bGray rounded-md group-hover/link:bg-gray truncate flex justify-center items-center">
                                        {Object.keys(social_icons).includes(
                                            obj.type
                                        ) ? (
                                            social_icons[obj.type]
                                        ) : obj?.fav_icon ? (
                                            <p
                                                className={`bg-trasnparent rounded-md truncate grayscale fill-cyan-500 flex justify-center w-[100%]`}
                                            >
                                                <img
                                                    className="w-[70%]"
                                                    src={obj.fav_icon}
                                                    alt=""
                                                />
                                            </p>
                                        ) : (
                                            <p
                                                className={`bg-bGray rounded-md truncate group-hover/link:bg-gray grayscale fill-cyan-500 flex justify-center`}
                                            >
                                                {obj.link ? (
                                                    <img
                                                        className="3md:w-[70%]"
                                                        src={`http://www.google.com/s2/favicons?domain=${obj.link}&sz=64`}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <HeroOtherIcon />
                                                )}
                                            </p>
                                        )}
                                    </p>
                                </ToolTip>
                            </Link>
                        ))}
                        {socials.length > 5 && (
                            <StyledPopup
                            ref={optionRef}
                            position="bottom right"
                            open={open}
                            onClose={() => setOpen(false)}
                            arrow={false}
                            // contentStyle={{ marginTop: 20, marginLeft: 80 }}
                            trigger={
                                <div className="flex items-center">
                                    <button
                                        className={`p-3 2sm:p-2 2md:p-3.5 bg-${
                                            open ? "activeGray" : "bGray"
                                        } rounded-md`}
                                        onClick={() => setOpen((prev) => !prev)}
                                    >
                                        <FaEllipsisH className="text-xs 2sm:text-xl" />
                                    </button>
                                </div>
                            }
                        > 
                                {/* {open && ( */}
                                    <div className="w-[250px] flex flex-col top-15 social-box p-2.5">
                                        <div className="w-full grid grid-cols-4 gap-2.5">
                                            {socials
                                                .slice(5, socials.length)
                                                .map((obj, index) => (
                                                    <Link
                                                        className="group/morelink flex justify-center items-center bg-bGray"
                                                        to={{
                                                            pathname: obj.link,
                                                        }}
                                                        target="_blank"
                                                        key={index}
                                                    >
                                                        <div className="relative group flex justify-center">
                                                            <p className="w-7.5 h-7.5 3md:w-12 3md:h-13 bg-bGray rounded-md group-hover/link:bg-gray truncate flex justify-center items-center shadow-md">
                                                                {Object.keys(
                                                                    social_icons
                                                                ).includes(
                                                                    obj.type
                                                                ) ? (
                                                                    social_icons[
                                                                        obj.type
                                                                    ]
                                                                ) : (
                                                                    <p
                                                                        className={`bg-bGray rounded-md truncate group-hover/link:bg-gray grayscale fill-cyan-500 flex justify-center`}
                                                                    >
                                                                        {obj.link ? (
                                                                            <img
                                                                                className="w-[70%]"
                                                                                src={`http://www.google.com/s2/favicons?domain=${obj.link}&sz=64`}
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <HeroOtherIcon />
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </p>
                                                            <div class="absolute opacity-0 group-hover:opacity-100 text-center transition-opacity duration-300 ease-in-out -top-9">
                                                                <div className="relative flex justify-center ">
                                                                    <div className="z-20 text-[10px] bg-gray p-1 px-2 rounded-md  min-w-18">
                                                                        {
                                                                            obj.link
                                                                        }
                                                                    </div>
                                                                    <div className="absolute z-0 top-[18px] w-2 h-2 transform rotate-45 -transalte-x-1/2 bg-gray"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                        </div>
                                    </div>
                               {/** )} */}
                            </StyledPopup>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
const StyledPopup = styled(Popup)`
  &-content{
    margin-top: 15px;
    margin-left:21px;
  }

  @media (max-width: 429px) {
    &-content{
      margin-left:9px;
    }
  }
`;