import TemplateColorIconSVG from "assets/svg/template-color-icon";
import moment from "moment";
import { components } from "react-select";
import HeroFiverrIcon from "assets/svg/hero-fiverr";
import HeroOnlyFanIcon from "assets/svg/hero-onlyfans";
import HeroPateonIcon from "assets/svg/hero-petreon";
import HeroOtherIcon from "assets/svg/hero-other";
import HeroUpworkIcon from "assets/svg/hero-upwork";
import imgBuyME from "assets/svg/hero-buy-me-a-coffee.webp";
import imgHipages from "assets/svg/hero-hipages.webp";
import imgSs from "assets/svg/hero-ss.webp";
import imgSubstack from "assets/svg/hero-substack.webp";
import imgLocals from "assets/svg/hero-locals.webp";
import imgKofi from "assets/svg/hero-ko-fi.webp";
import HeroFreelancerIcon from "assets/svg/hero-freelancer";
import { FreeAccountBanner, PremiumAccountBanner } from "constants/images";
import {
  STRIPE_PREMIUM_MONTH,
  STRIPE_PREMIUM_YEAR,
  PAYPAL_PREMIUM_MONTH,
  PAYPAL_PREMIUM_YEAR,
} from "configs/subscription";

import TemplateOneThemeOnePageOne from "assets/images/template-one-theme-one-page-1.png";
import TemplateOneThemeOnePageTwo from "assets/images/template-one-theme-one-page-2.png";
import TemplateTwoThemeOnePageOne from "assets/images/template-two-theme-one-page-1.png";
import TemplateTwoThemeOnePageTwo from "assets/images/template-two-theme-one-page-2.png";
import TemplateThreeThemeOnePageOne from "assets/images/template-three-theme-one-page-1.png";
import TemplateThreeThemeOnePageTwo from "assets/images/template-three-theme-one-page-2.png";
import TemplateFourThemeOnePageOne from "assets/images/template-four-theme-one-page-1.png";
import TemplateFourThemeOnePageTwo from "assets/images/template-four-theme-one-page-2.png";

import TemplateOneThemeOnePageOneTG from "assets/images/template-one-theme-one-page-1-tg.png";
import TemplateTwoThemeOnePageOneTG from "assets/images/template-two-theme-one-page-1-tg.png";
import TemplateThreeThemeOnePageOneTG from "assets/images/template-three-theme-one-page-1-tg.png";
import TemplateFourThemeOnePageOneTG from "assets/images/template-four-theme-one-page-1-tg.png";

import TemplateOneThemeTwoPageOne from "assets/images/template-one-theme-two-page-1.png";
import TemplateOneThemeTwoPageTwo from "assets/images/template-one-theme-two-page-2.png";
import TemplateTwoThemeTwoPageOne from "assets/images/template-two-theme-two-page-1.png";
import TemplateTwoThemeTwoPageTwo from "assets/images/template-two-theme-two-page-2.png";
import TemplateThreeThemeTwoPageOne from "assets/images/template-three-theme-two-page-1.png";
import TemplateThreeThemeTwoPageTwo from "assets/images/template-three-theme-two-page-2.png";
import TemplateFourThemeTwoPageOne from "assets/images/template-four-theme-two-page-1.png";
import TemplateFourThemeTwoPageTwo from "assets/images/template-four-theme-two-page-2.png";

import TemplateOneThemeThreePageOne from "assets/images/template-one-theme-three-page-1.png";
import TemplateOneThemeThreePageTwo from "assets/images/template-one-theme-three-page-2.png";
import TemplateTwoThemeThreePageOne from "assets/images/template-two-theme-three-page-1.png";
import TemplateTwoThemeThreePageTwo from "assets/images/template-two-theme-three-page-2.png";
import TemplateThreeThemeThreePageOne from "assets/images/template-three-theme-three-page-1.png";
import TemplateThreeThemeThreePageTwo from "assets/images/template-three-theme-three-page-2.png";
import TemplateFourThemeThreePageOne from "assets/images/template-four-theme-three-page-1.png";
import TemplateFourThemeThreePageTwo from "assets/images/template-four-theme-three-page-2.png";

import TemplateOneThemeFourPageOne from "assets/images/template-one-theme-four-page-1.png";
import TemplateOneThemeFourPageTwo from "assets/images/template-one-theme-four-page-2.png";
import TemplateTwoThemeFourPageOne from "assets/images/template-two-theme-four-page-1.png";
import TemplateTwoThemeFourPageTwo from "assets/images/template-two-theme-four-page-2.png";
import TemplateThreeThemeFourPageOne from "assets/images/template-three-theme-four-page-1.png";
import TemplateThreeThemeFourPageTwo from "assets/images/template-three-theme-four-page-2.png";
import TemplateFourThemeFourPageOne from "assets/images/template-four-theme-four-page-1.png";
import TemplateFourThemeFourPageTwo from "assets/images/template-four-theme-four-page-2.png";

import TemplateOneThemeFivePageOne from "assets/images/template-one-theme-five-page-1.png";
import TemplateOneThemeFivePageTwo from "assets/images/template-one-theme-five-page-2.png";
import TemplateTwoThemeFivePageOne from "assets/images/template-two-theme-five-page-1.png";
import TemplateTwoThemeFivePageTwo from "assets/images/template-two-theme-five-page-2.png";
import TemplateThreeThemeFivePageOne from "assets/images/template-three-theme-five-page-1.png";
import TemplateThreeThemeFivePageTwo from "assets/images/template-three-theme-five-page-2.png";
import TemplateFourThemeFivePageOne from "assets/images/template-four-theme-five-page-1.png";
import TemplateFourThemeFivePageTwo from "assets/images/template-four-theme-five-page-2.png";

import TemplateOneThemeOnePageOneNP from "assets/images/Color1/color-1-telplate-1-P1.png";
import TemplateOneThemeOnePageTwoNP from "assets/images/Color1/color-1-telplate-1-P2.png";
import TemplateTwoThemeOnePageOneNP from "assets/images/Color1/color-1-telplate-2-P1.png";
import TemplateTwoThemeOnePageTwoNP from "assets/images/Color1/color-1-telplate-2-P2.png";
import TemplateThreeThemeOnePageOneNP from "assets/images/Color1/color-1-telplate-3-P1.png";
import TemplateThreeThemeOnePageTwoNP from "assets/images/Color1/color-1-telplate-3-P2.png";
import TemplateFourThemeOnePageOneNP from "assets/images/Color1/color-1-telplate-4-P1.png";
import TemplateFourThemeOnePageTwoNP from "assets/images/Color1/color-1-telplate-4-P2.png";

import TemplateOneThemeTwoPageOneNP from "assets/images/Color2/color-2-telplate-1-P1.png";
import TemplateOneThemeTwoPageTwoNP from "assets/images/Color2/color-2-telplate-1-P2.png";
import TemplateTwoThemeTwoPageOneNP from "assets/images/Color2/color-2-telplate-2-P1.png";
import TemplateTwoThemeTwoPageTwoNP from "assets/images/Color2/color-2-telplate-2-P2.png";
import TemplateThreeThemeTwoPageOneNP from "assets/images/Color2/color-2-telplate-3-P1.png";
import TemplateThreeThemeTwoPageTwoNP from "assets/images/Color2/color-2-telplate-3-P2.png";
import TemplateFourThemeTwoPageOneNP from "assets/images/Color2/color-2-telplate-4-P1.png";
import TemplateFourThemeTwoPageTwoNP from "assets/images/Color2/color-2-telplate-4-P2.png";

import TemplateOneThemeThreePageOneNP from "assets/images/Color3/color-3-telplate-1-P1.png";
import TemplateOneThemeThreePageTwoNP from "assets/images/Color3/color-3-telplate-1-P2.png";
import TemplateTwoThemeThreePageOneNP from "assets/images/Color3/color-3-telplate-2-P1.png";
import TemplateTwoThemeThreePageTwoNP from "assets/images/Color3/color-3-telplate-2-P2.png";
import TemplateThreeThemeThreePageOneNP from "assets/images/Color3/color-3-telplate-3-P1.png";
import TemplateThreeThemeThreePageTwoNP from "assets/images/Color3/color-3-telplate-3-P2.png";
import TemplateFourThemeThreePageOneNP from "assets/images/Color3/color-3-telplate-4-P1.png";
import TemplateFourThemeThreePageTwoNP from "assets/images/Color3/color-3-telplate-4-P2.png";

import TemplateOneThemeFourPageOneNP from "assets/images/Color4/color-4-telplate-1-P1.png";
import TemplateOneThemeFourPageTwoNP from "assets/images/Color4/color-4-telplate-1-P2.png";
import TemplateTwoThemeFourPageOneNP from "assets/images/Color4/color-4-telplate-2-P1.png";
import TemplateTwoThemeFourPageTwoNP from "assets/images/Color4/color-4-telplate-2-P2.png";
import TemplateThreeThemeFourPageOneNP from "assets/images/Color4/color-4-telplate-3-P1.png";
import TemplateThreeThemeFourPageTwoNP from "assets/images/Color4/color-4-telplate-3-P2.png";
import TemplateFourThemeFourPageOneNP from "assets/images/Color4/color-4-telplate-4-P1.png";
import TemplateFourThemeFourPageTwoNP from "assets/images/Color4/color-4-telplate-4-P2.png";

import TemplateOneThemeFivePageOneNP from "assets/images/Color5/color-5-telplate-1-P1.png";
import TemplateOneThemeFivePageTwoNP from "assets/images/Color5/color-5-telplate-1-P2.png";
import TemplateTwoThemeFivePageOneNP from "assets/images/Color5/color-5-telplate-2-P1.png";
import TemplateTwoThemeFivePageTwoNP from "assets/images/Color5/color-5-telplate-2-P2.png";
import TemplateThreeThemeFivePageOneNP from "assets/images/Color5/color-5-telplate-3-P1.png";
import TemplateThreeThemeFivePageTwoNP from "assets/images/Color5/color-5-telplate-3-P2.png";
import TemplateFourThemeFivePageOneNP from "assets/images/Color5/color-5-telplate-4-P1.png";
import TemplateFourThemeFivePageTwoNP from "assets/images/Color5/color-5-telplate-4-P2.png";

import ResumeStepFiveIconSVG from "assets/svg/resume-step-five-icon";
import ResumeStepFourIconSVG from "assets/svg/resume-step-four-icon";
import ResumeStepOneIconSVG from "assets/svg/resume-step-one-icon";
import ResumeStepThreeIconSVG from "assets/svg/resume-step-three-icon";
import ResumeStepTwoIconSVG from "assets/svg/resume-step-two-icon";

import {
  TiSocialFacebook,
  TiSocialFlickr,
  TiSocialLinkedin,
} from "react-icons/ti";
import { AiFillInstagram } from "react-icons/ai";
import {
  FaPinterest,
  FaReddit,
  FaSnapchatGhost,
  FaTiktok,
  FaTwitter,
  FaTumblr,
  FaVimeoV,
  FaYoutube,
  FaDeezer,
  FaGooglePlay,
  FaItunes,
  FaSpotify,
  FaWikipediaW,
} from "react-icons/fa";
import { SiBandcamp, SiOnlyfans, SiDiscogs } from "react-icons/si";
import { RiSoundcloudFill } from "react-icons/ri";
import FiverrIconSVG from "assets/svg/fiverr-icon";

const TO_RADIANS = Math.PI / 180;
let previewUrl = "";

export const KB = 1024;
export const MB = 1024 * 1024;

export const gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];
// const user_data = JSON.parse(localStorage.getItem("user_data"));
// const user = user_data?.user || null;
// const profile = user_data?.profile || null;
// export const displayName = [
//   { label: user?.first_name || '', value: "First Name" },
//   { label: user?.last_name || '', value: "Surename" },
//   { label: `${user?.first_name || ''} ${user?.last_name || ''}`, value: "First Name Surname" },
//   { label: profile?.username || '', value: "Username" },
//   { label: 'Other Name' || '', value: "Other Name" },
// ];

export const pronoun = [
  { label: "He/him", value: "He/him" },
  { label: "She/her", value: "She/her" },
  { label: "They/Them", value: "They/Them" },
];

export const profileTypes = [
  { label: "Personal", value: "personal" },
  { label: "Group", value: "group" },
  { label: "Team", value: "team" },
  { label: "Character", value: "character" },
];

export const profileStyles = [
  { label: "Tribute", value: "tribute" },
  { label: "Parody", value: "parody" },
];

export const occupations = [
  { label: "Writer", value: "Writer" },
  { label: "Director", value: "Director" },
  { label: "Actor", value: "Actor" },
  { label: "Dancer", value: "Dancer" },
  { label: "Choreographer", value: "Choreographer" },
];

export const keywords = [
  { label: "Writer", value: "Writer" },
  { label: "Director", value: "Director" },
  { label: "Actor", value: "Actor" },
  { label: "Dancer", value: "Dancer" },
  { label: "Choreographer", value: "Choreographer" },
];

export const social = [
  { label: "Bandcamp", value: "Bandcamp", site: "bandcamp.com" },
  { label: "Deezer", value: "Deezer", site: "deezer.com" },
  { label: "Discogs", value: "Discogs", site: "discogs.com" },
  { label: "Facebook", value: "Facebook", site: "facebook.com" },
  { label: "Fiverr", value: "Fiverr", site: "fiverr.com" },
  { label: "Flickr", value: "Flickr", site: "flickr.com" },
  { label: "GooglePlay", value: "GooglePlay", site: "play.google.com" },
  { label: "Instagram", value: "Instagram", site: "instagram.com" },
  { label: "Itunes", value: "Itunes", site: "/itunes/" },
  { label: "Linkedin", value: "Linkedin", site: "linkedin.com" },
  { label: "Onlyfans", value: "Onlyfans", site: "onlyfans.com" },
  { label: "Pinterest", value: "Pinterest", site: "pinterest.com" },
  { label: "Reddit", value: "Reddit", site: "reddit.com" },
  { label: "Soundcloud", value: "Soundcloud", site: "soundcloud.com" },
  { label: "Snapchat", value: "Snapchat", site: "snapchat.com" },
  { label: "Spotify", value: "Spotify", site: "spotify.com" },
  { label: "Tiktok", value: "Tiktok", site: "tiktok.com" },
  { label: "Tumblr", value: "Tumblr", site: "tumblr.com" },
  { label: "Twitter", value: "Twitter", site: "twitter.com" },
  { label: "Vimeo", value: "Vimeo", site: "vimeo.com" },
  { label: "Wikipedia", value: "Wikipedia", site: "wikipedia.org" },
  { label: "Youtube", value: "Youtube", site: "youtube.com" },
  { label: "IMDb", value: "IMDb", site: "imdb.com" },
];

export const heroTypes = [
  { label: "buy me a coffee", value: "buy me a coffee" },
  { label: "Upwork", value: "Upwork" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "Subscribe star", value: "Subscribe star" },
  { label: "Locals", value: "Locals" },
  { label: "Hipages", value: "Hipages" },
  { label: "Substack", value: "Substack" },
  { label: "Ko-fi", value: "Ko-fi" },
  { label: "Fiverr", value: "Fiverr" },
  { label: "Onlyfans", value: "Onlyfans" },
  { label: "Patreon", value: "Patreon" },
  { label: "Other", value: "Other" },
];

export const heroCTAs = [
  { label: "Support me on", value: "Support me on" },
  { label: "Subscribe on", value: "Subscribe on" },
  { label: "Hire me on", value: "Hire me on" },
];

// export const country = [
//   { label: "Austrilla", value: "Austrilla" },
//   { label: "Philippines", value: "Philippines" },
// ];

export const country = [
  { label: "Australia", value: "au" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "United Kingdom", value: "gb" },
  { label: "United States", value: "us" },
  { label: "India", value: "in" },
];

export const linkType = [
  { label: "Image", value: "Image" },
  { label: "Youtube/Vimeo Link", value: "Youtube/Vimeo Link" },
  { label: "Audio File", value: "Audio File" },
];

export const reportType = [
  {
    label: "Copyright",
    value: "Copyright",
  },
  {
    label: "Adult Content",
    value: "Adult Content",
  },
  {
    label: "Defamatory",
    value: "Defamatory",
  },
  {
    label: "Contains potentially harmful link",
    value: "Contains potentially harmful link",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const dateDay = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
  { label: "25", value: "25" },
  { label: "26", value: "26" },
  { label: "27", value: "27" },
  { label: "28", value: "28" },
  { label: "29", value: "29" },
  { label: "30", value: "30" },
  { label: "31", value: "31" },
];

export const dateMonth = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  { label: "03", value: "03" },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  { label: "07", value: "07" },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
];

export const PERMISSION = [
  { label: "Super Admin", value: "Super Admin" },
  { label: "Admin", value: "Admin" },
  { label: "Staff", value: "Staff" },
];

export const PERMISSION_LEVELS = [
  {
    title: "Admin",
    description: "Can post milestones, can add users.",
  },
  {
    title: "Staff",
    description: "Can post milestones",
  },
];

export const toBlob = (canvas) => {
  return new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
};

export async function canvasToFile(
  image,
  crop,
  scale = 1,
  rotate = 0,
  name = "crop-image",
  type = ""
) {
  const canvas = document.createElement("canvas");
  canvasPreview(image, canvas, crop, scale, rotate);

  const blob = await toBlob(canvas);
  const base64Canvas = canvas.toDataURL(type).split(";base64,")[1];
  if (!blob) {
    console.error("Failed to create blob");

    return "";
  }

  if (previewUrl) {
    URL.revokeObjectURL(previewUrl);
  }

  previewUrl = URL.createObjectURL(blob);
  let file = new File([blob], name);
  return {
    blobImage: blob,
    image: file,
    previewUrl: previewUrl,
    base64Canvas: base64Canvas,
  };
}

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

//get months
export const getMonths = () => {
  const months = moment.months();
  let result = [];
  if (months) {
    const currentYear = moment().format("YYYY");
    result = months.map((value) => {
      return {
        label: `${value} ${currentYear}`,
        value: `${value} ${currentYear}`,
      };
    });
  }
  return result;
};

export const isValidHttpUrl = (str) => {
  // const url = str.replace("www.", "");
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" + // validate port and path (including @)
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(str);
};

//get days
export const getDays = (year, month) => {
  const lastDayOfTheMonth = new Date(year, month, 0).getDate();

  let result = [];
  for (let i = 0; i < lastDayOfTheMonth; i++) {
    result.push(i + 1);
  }

  return result;
};

// export const maxUploadSize = 30 * 1024 * 1024;  // 30 MB
export const maxUploadSize = 50 * 1024 * 1024;  // 50 MB

export const TemplateColorOptions = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className="w-full flex flex-row items-center space-x-3">
        <TemplateColorIconSVG colors={data.color} />
        <span>{props.label}</span>
      </div>
    </components.Option>
  );
};

export const TemplateControl = ({ children, hasValue, ...props }) => {
  const selected = props.getValue();
  return (
    <components.Control {...props}>
      {hasValue && <TemplateColorIconSVG colors={selected[0].color} />}
      {children}
    </components.Control>
  );
};

export const heroIcon = (iconName, link=null) => {
  switch (iconName) {
    case "Fiverr":
      return <HeroFiverrIcon />;
    case "Onlyfans":
      return <HeroOnlyFanIcon />;
    case "Patreon":
      return <HeroPateonIcon />;
    case "buy me a coffee":
      return (
        <div className="w-6">
          <img alt="milestone-icon" src={imgBuyME} />{" "}
        </div>
      );
    case "Upwork":
      return <HeroUpworkIcon />;
    case "Freelancer":
      return <HeroFreelancerIcon />;
    case "Subscribe star":
      return (
        <div className="w-7">
          <img alt="milestone-icon" src={imgSs} />{" "}
        </div>
      );
    case "Ko-fi":
      return (
        <div className="w-7">
          <img alt="milestone-icon" src={imgKofi} />{" "}
        </div>
      );
    case "Hipages":
      return (
        <div className="w-8">
          <img alt="milestone-icon" src={imgHipages} />{" "}
        </div>
      );
    case "Substack":
      return (
        <div className="w-6">
          <img alt="milestone-icon" src={imgSubstack} />{" "}
        </div>
      );
    case "Locals":
      return (
        <div className="w-8">
          <img alt="milestone-icon" src={imgLocals} />{" "}
        </div>
      );
    case "Other":
        return (
          <div className="w-8">
            {/* <img alt="milestone-icon" src={imgLocals} />{" "} */}
            <img
                className="w-full"
                src={`http://www.google.com/s2/favicons?domain=${link}&sz=32`}
                alt=""
              />
          </div>
        );
    default:
      return <HeroOtherIcon />;
  }
};

export const stepsInfo = [
  { label: "Select a Template", icon: <ResumeStepOneIconSVG /> },
  { label: "Show/Hide Personal Info", icon: <ResumeStepTwoIconSVG /> },
  { label: "Show/Hide More Info", icon: <ResumeStepThreeIconSVG /> },
  { label: "Show/Hide Milestones", icon: <ResumeStepFourIconSVG /> },
  { label: "Share or Download", icon: <ResumeStepFiveIconSVG /> },
];

export const colorOptions = [
  {
    label: "Blue + Orange",
    value: ["#3885FF", "#E5EFFA", "#EF6B33", "#2063CD"],
    color: ["#3885FF", "#EF6B33"],
    key: "theme-one",
  },
  {
    label: "Green + Yellow",
    value: ["#1D845E", "#DEEDE8", "#CA9E00", "#176F4F"],
    color: ["#1D845E", "#CA9E00"],
    key: "theme-two",
  },
  {
    label: "Pink + Dark Pink",
    value: ["#E1529F", "#F5E2ED", "#920046", "#C7498D"],
    color: ["#E1529F", "#C7498D"],
    key: "theme-three",
  },
  {
    label: "Yellow + Blue",
    value: ["#E8B42D", "#F7EED9", "#4387C9", "#CD9914"],
    color: ["#E8B42D", "#4387C9"],
    key: "theme-four",
  },
  {
    label: "Voilet + Pink",
    value: ["#794BDA", "#F0EBFC", "#E82D9D", "#6332CB"],
    color: ["#794BDA", "#E82D9D"],
    key: "theme-five",
  },
];

//import other colors and no profile image
export const templatesPlaceholderNP = [
  {
    key: "theme-one",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeOnePageOneNP },
          { active: false, image: TemplateOneThemeOnePageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeOnePageOneNP },
          { active: false, image: TemplateTwoThemeOnePageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeOnePageOneNP },
          { active: false, image: TemplateThreeThemeOnePageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeOnePageOneNP },
          { active: false, image: TemplateFourThemeOnePageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-two",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeTwoPageOneNP },
          { active: false, image: TemplateOneThemeTwoPageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeTwoPageOneNP },
          { active: false, image: TemplateTwoThemeTwoPageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeTwoPageOneNP },
          { active: false, image: TemplateThreeThemeTwoPageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeTwoPageOneNP },
          { active: false, image: TemplateFourThemeTwoPageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-three",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeThreePageOneNP },
          { active: false, image: TemplateOneThemeThreePageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeThreePageOneNP },
          { active: false, image: TemplateTwoThemeThreePageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeThreePageOneNP },
          { active: false, image: TemplateThreeThemeThreePageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeThreePageOneNP },
          { active: false, image: TemplateFourThemeThreePageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-four",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFourPageOneNP },
          { active: false, image: TemplateOneThemeFourPageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFourPageOneNP },
          { active: false, image: TemplateTwoThemeFourPageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFourPageOneNP },
          { active: false, image: TemplateThreeThemeFourPageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFourPageOneNP },
          { active: false, image: TemplateFourThemeFourPageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-five",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFivePageOneNP },
          { active: false, image: TemplateOneThemeFivePageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFivePageOneNP },
          { active: false, image: TemplateTwoThemeFivePageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFivePageOneNP },
          { active: false, image: TemplateThreeThemeFivePageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFivePageOneNP },
          { active: false, image: TemplateFourThemeFivePageTwoNP },
        ],
      },
    ],
  },
];

//import other colors
export const templatesPlaceholder = [
  {
    key: "theme-one",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeOnePageOne },
          { active: false, image: TemplateOneThemeOnePageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeOnePageOne },
          { active: false, image: TemplateTwoThemeOnePageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeOnePageOne },
          { active: false, image: TemplateThreeThemeOnePageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeOnePageOne },
          { active: false, image: TemplateFourThemeOnePageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-two",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeTwoPageOne },
          { active: false, image: TemplateOneThemeTwoPageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeTwoPageOne },
          { active: false, image: TemplateTwoThemeTwoPageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeTwoPageOne },
          { active: false, image: TemplateThreeThemeTwoPageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeTwoPageOne },
          { active: false, image: TemplateFourThemeTwoPageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-three",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeThreePageOne },
          { active: false, image: TemplateOneThemeThreePageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeThreePageOne },
          { active: false, image: TemplateTwoThemeThreePageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeThreePageOne },
          { active: false, image: TemplateThreeThemeThreePageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeThreePageOne },
          { active: false, image: TemplateFourThemeThreePageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-four",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFourPageOne },
          { active: false, image: TemplateOneThemeFourPageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFourPageOne },
          { active: false, image: TemplateTwoThemeFourPageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFourPageOne },
          { active: false, image: TemplateThreeThemeFourPageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFourPageOne },
          { active: false, image: TemplateFourThemeFourPageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-five",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFivePageOne },
          { active: false, image: TemplateOneThemeFivePageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFivePageOne },
          { active: false, image: TemplateTwoThemeFivePageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFivePageOne },
          { active: false, image: TemplateThreeThemeFivePageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFivePageOne },
          { active: false, image: TemplateFourThemeFivePageTwo },
        ],
      },
    ],
  },
];

/** TEMPLATE PLACEHOLDERS FOR TEAM/GROUP PROFILE TYPES */
//import other colors and no profile image
export const templatesPlaceholderNPTG = [
  {
    key: "theme-one",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeOnePageOneNP },
          { active: false, image: TemplateOneThemeOnePageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeOnePageOneNP },
          { active: false, image: TemplateTwoThemeOnePageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeOnePageOneNP },
          { active: false, image: TemplateThreeThemeOnePageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeOnePageOneNP },
          { active: false, image: TemplateFourThemeOnePageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-two",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeTwoPageOneNP },
          { active: false, image: TemplateOneThemeTwoPageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeTwoPageOneNP },
          { active: false, image: TemplateTwoThemeTwoPageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeTwoPageOneNP },
          { active: false, image: TemplateThreeThemeTwoPageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeTwoPageOneNP },
          { active: false, image: TemplateFourThemeTwoPageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-three",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeThreePageOneNP },
          { active: false, image: TemplateOneThemeThreePageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeThreePageOneNP },
          { active: false, image: TemplateTwoThemeThreePageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeThreePageOneNP },
          { active: false, image: TemplateThreeThemeThreePageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeThreePageOneNP },
          { active: false, image: TemplateFourThemeThreePageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-four",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFourPageOneNP },
          { active: false, image: TemplateOneThemeFourPageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFourPageOneNP },
          { active: false, image: TemplateTwoThemeFourPageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFourPageOneNP },
          { active: false, image: TemplateThreeThemeFourPageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFourPageOneNP },
          { active: false, image: TemplateFourThemeFourPageTwoNP },
        ],
      },
    ],
  },
  {
    key: "theme-five",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFivePageOneNP },
          { active: false, image: TemplateOneThemeFivePageTwoNP },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFivePageOneNP },
          { active: false, image: TemplateTwoThemeFivePageTwoNP },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFivePageOneNP },
          { active: false, image: TemplateThreeThemeFivePageTwoNP },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFivePageOneNP },
          { active: false, image: TemplateFourThemeFivePageTwoNP },
        ],
      },
    ],
  },
];

//import other colors
export const templatesPlaceholderTG = [
  {
    key: "theme-one",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeOnePageOneTG },
          { active: false, image: TemplateOneThemeOnePageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeOnePageOneTG },
          { active: false, image: TemplateTwoThemeOnePageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeOnePageOneTG },
          { active: false, image: TemplateThreeThemeOnePageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeOnePageOneTG },
          { active: false, image: TemplateFourThemeOnePageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-two",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeTwoPageOne },
          { active: false, image: TemplateOneThemeTwoPageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeTwoPageOne },
          { active: false, image: TemplateTwoThemeTwoPageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeTwoPageOne },
          { active: false, image: TemplateThreeThemeTwoPageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeTwoPageOne },
          { active: false, image: TemplateFourThemeTwoPageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-three",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeThreePageOne },
          { active: false, image: TemplateOneThemeThreePageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeThreePageOne },
          { active: false, image: TemplateTwoThemeThreePageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeThreePageOne },
          { active: false, image: TemplateThreeThemeThreePageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeThreePageOne },
          { active: false, image: TemplateFourThemeThreePageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-four",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFourPageOne },
          { active: false, image: TemplateOneThemeFourPageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFourPageOne },
          { active: false, image: TemplateTwoThemeFourPageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFourPageOne },
          { active: false, image: TemplateThreeThemeFourPageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFourPageOne },
          { active: false, image: TemplateFourThemeFourPageTwo },
        ],
      },
    ],
  },
  {
    key: "theme-five",
    templates: [
      {
        name: "Template Name 1",
        images: [
          { active: true, image: TemplateOneThemeFivePageOne },
          { active: false, image: TemplateOneThemeFivePageTwo },
        ],
      },
      {
        name: "Template Name 2",
        images: [
          { active: true, image: TemplateTwoThemeFivePageOne },
          { active: false, image: TemplateTwoThemeFivePageTwo },
        ],
      },
      {
        name: "Template Name 3",
        images: [
          { active: true, image: TemplateThreeThemeFivePageOne },
          { active: false, image: TemplateThreeThemeFivePageTwo },
        ],
      },
      {
        name: "Template Name 4",
        images: [
          { active: true, image: TemplateFourThemeFivePageOne },
          { active: false, image: TemplateFourThemeFivePageTwo },
        ],
      },
    ],
  },
];

export const social_icons = {
  Facebook: <TiSocialFacebook className="text-base 3md:text-3xl" />,
  Flickr: <TiSocialFlickr className="text-base 3md:text-3xl" />,
  Instagram: <AiFillInstagram className="text-base 3md:text-3xl" />,
  Linkedin: <TiSocialLinkedin className="text-base 3md:text-3xl" />,
  Tiktok: <FaTiktok className="text-base 3md:text-3xl" />,
  Twitter: <FaTwitter className="text-base 3md:text-3xl" />,
  Pinterest: <FaPinterest className="text-base 3md:text-3xl" />,
  Reddit: <FaReddit className="text-base 3md:text-3xl" />,
  Snapchat: <FaSnapchatGhost className="text-base 3md:text-3xl" />,
  Tumblr: <FaTumblr className="text-base 3md:text-3xl" />,
  Vimeo: <FaVimeoV className="text-base 3md:text-3xl" />,
  Youtube: <FaYoutube className="text-base 3md:text-3xl" />,
  Bandcamp: <SiBandcamp className="text-base 3md:text-3xl" />,
  Deezer: <FaDeezer className="text-base 3md:text-3xl" />,
  Discogs: <SiDiscogs className="text-base 3md:text-3xl" />,
  Fiverr: <FiverrIconSVG className="text-base 3md:text-3xl" />,
  GooglePlay: <FaGooglePlay className="text-base 3md:text-3xl" />,
  Itunes: <FaItunes className="text-base 3md:text-3xl" />,
  Soundcloud: <RiSoundcloudFill className="text-base 3md:text-3xl" />,
  Spotify: <FaSpotify className="text-base 3md:text-3xl" />,
  Wikipedia: <FaWikipediaW className="text-base 3md:text-3xl" />,
  Onlyfans: <SiOnlyfans className="text-base 3md:text-3xl" />,
};

export const socialTemplate = {
  facebook: TiSocialFacebook,
  flickr: TiSocialFlickr,
  instagram: AiFillInstagram,
  linkedin: TiSocialLinkedin,
  tiktok: FaTiktok,
  twitter: FaTwitter,
  pinterest: FaPinterest,
  reddit: FaReddit,
  snapchat: FaSnapchatGhost,
  tumblr: FaTumblr,
  vimeo: FaVimeoV,
  youtube: FaYoutube,
  bandcamp: SiBandcamp,
  deezer: FaDeezer,
  discogs: SiDiscogs,
  fiverr: FiverrIconSVG,
  googlePlay: FaGooglePlay,
  itunes: FaItunes,
  soundcloud: RiSoundcloudFill,
  spotify: FaSpotify,
  wikipedia: FaWikipediaW,
  onlyfans: SiOnlyfans,
};

export const PLANS = [
  {
    banner: FreeAccountBanner,
    plan: "Free Account",
    description:
      "Curabitur mattis vel orci id mattis. Aliquam eu dignissim sem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris vitae fermentum quam.",
    inclusions: [
      { name: "Headshot & Background image", status: true },
      { name: "Milestones", status: true },
      { name: "Images on Milestones", status: true },
      { name: "Bio", status: true },
      { name: "Search", status: true },
      { name: "Advanced Search", status: false },
      { name: "Links/PDFs/Images/MP3s on Milestones", status: false },
      { name: "Images/Links/PDFs on Bio", status: false },
      { name: "Ability to Send/Receive Messages", status: false },
      { name: "Blue Tick", status: false },
    ],
  },
  {
    banner: PremiumAccountBanner,
    plan: "Premium Monthly",
    price_id: STRIPE_PREMIUM_MONTH,
    plan_id: PAYPAL_PREMIUM_MONTH,
    term: "month",
    unit_amount: 4.99,
    description:
      "Curabitur mattis vel orci id mattis. Aliquam eu dignissim sem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris vitae fermentum quam.",
    inclusions: [
      { name: "Headshot & Background image", status: true },
      { name: "Milestones", status: true },
      { name: "Images on Milestones", status: true },
      { name: "Bio", status: true },
      { name: "Search", status: true },
      { name: "Advanced Search", status: true },
      { name: "Links/PDFs/Images/MP3s on Milestones", status: true },
      { name: "Images/Links/PDFs on Bio", status: true },
      { name: "Ability to Send/Receive Messages", status: true },
      { name: "Blue Tick (with proof of identity*)", status: true },
    ],
  },
  {
    banner: PremiumAccountBanner,
    plan: "Premium Annual",
    price_id: STRIPE_PREMIUM_YEAR,
    plan_id: PAYPAL_PREMIUM_YEAR,
    term: "year",
    unit_amount: 49.99,
    description:
      "Curabitur mattis vel orci id mattis. Aliquam eu dignissim sem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris vitae fermentum quam.",
    inclusions: [
      { name: "Headshot & Background image", status: true },
      { name: "Milestones", status: true },
      { name: "Images on Milestones", status: true },
      { name: "Bio", status: true },
      { name: "Search", status: true },
      { name: "Advanced Search", status: true },
      { name: "Links/PDFs/Images/MP3s on Milestones", status: true },
      { name: "Images/Links/PDFs on Bio", status: true },
      { name: "Ability to Send/Receive Messages", status: true },
      { name: "Blue Tick (with proof of identity*)", status: true },
    ],
  },
];
