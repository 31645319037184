import React, { useContext, useEffect, useState } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import RouteList from "./list";
import userActions from "redux/modules/user/actions";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { randomExtension } from "utils/utils";
import NotificationHandler from "components/notification/notification-handler";
import { SubscriptionContext } from "context/subscription";
import { Context } from "context/context";
import AudioPlayerMobile from "../components/profile/milestones/media-button/audio-player-mobile";
import { useMilestones } from "../redux/modules/milestone/hooks";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
}

function RouteCheck() {
  const { pathname } = useLocation();
  const { setRoute } = useContext(Context);

  useEffect(() => {
    setRoute((prev) => ({
      to: pathname,
      from: prev.to ? prev.to : pathname,
      previousProfileVisit: prev.previousProfileVisit,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}

const RouterComponent = ({ user = null, setUserData }) => {
  const {
    playMusic,
    setPLayMusic,
  } = useMilestones();

  // useEffect(() => {
  //   console.log('playMusic  --->22222', playMusic)
  // },[playMusic])
  const { localstorage, http } = global.services;

  const [state, setState] = useState({
    renderContent: false,
  });
  const extension = randomExtension();
  const { getSubscription } = useContext(SubscriptionContext);

  useEffect(() => {
    const getUserData = async () => {
      const userData = localstorage.get("user_data");

      if (userData.value) {
        try {
          const { data } = await http.get("auth/user-profile");
          let parseData = JSON.parse(userData.value);
          let updatedData = { ...parseData, user: data };
          setUserData(updatedData);
          localstorage.set("user_data", JSON.stringify(updatedData));

          getSubscription();
          getVerification();
        } catch (error) {
          console.error(error);
          localstorage.remove("user_data");
          setUserData(null);
          toast.error("It appears that something went wrong");
          return (window.location.href = `/login`);
        }
      }

      setState((prevState) => ({ ...prevState, renderContent: true }));
    };

    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localstorage, setUserData]);

  const getVerification = async () => {
    const userData = localstorage.get("user_data");

    if (userData.value) {
      let parseData = JSON.parse(userData.value);

      try {
        const { data } = await http.get("public/user-valid-ids/verification", {
          user_id: parseData.user.id,
        });
        let updatedData = { ...parseData, account_verified: data };
        setUserData(updatedData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderRoutes = () => {
    const routes = RouteList.map((route, routeIndex) => {
      return (
        <Route
          exact
          key={`route-${routeIndex}`}
          path={route.path}
          render={() => {
            if (route.authRequired) {
              if (!user) {
                return <Redirect to={{ pathname: "/login" }} />;
              }
            } else {
              if (route.validateUserValid) {
                if (user) {
                  return (
                    <Redirect
                      to={{
                        pathname: `${user?.user?.username}-${extension}/profile/${user.profile.id}`,
                      }}
                    />
                  );
                }
              }
            }

            return route.component();
          }}
        />
      );
    });
    return routes;
  };

  return (
    state.renderContent && (
      <React.Fragment>
        <NotificationHandler />
        <Toaster toastOptions={{ duration: 3000 }} position="top-right" />
        <Router>
          <ScrollToTop />
          <RouteCheck />
          <Switch>{renderRoutes()}</Switch>
        </Router>
        <div className="audio-player  audio-container block sm:hidden">
          {playMusic && (
            <AudioPlayerMobile playable={true} media={playMusic} onClosePlayer={() =>  setPLayMusic('')}  /> 
          )}
        </div>
      </React.Fragment>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);
