import React, { useContext, useEffect } from "react";
import Layout from "layouts/layout";
import { useHistory } from "react-router-dom";
import ArrowLeftIcon from "assets/svg/arrow-left-icon";
import { useUser } from "redux/modules/user/hooks";
import CCIconSVG from "assets/svg/cc-icon-svg";
import { useDialogHook } from "utils/customhooks";
import ChangePaymentMethod from "components/settings/component/change-payment-method";
import { SubscriptionContext } from "context/subscription";
import PaypalIconSVG from "assets/svg/paypal-icon-svg";
import moment from "moment";
import PopInfo from "components/info-popup/pop-info";

const BillingHistory = () => {
  const { userData } = useUser();
  const changePaymentMethodModal = useDialogHook(ChangePaymentMethod);
  // const extension = randomExtension();
  let history = useHistory();

  const {
    subscription = {},
    billingHistory,
    getBillingHistory,
  } = useContext(SubscriptionContext);

  const onChangePaymentMethod = () => {
    changePaymentMethodModal(subscription, (cb) => {});
  };

  useEffect(() => {
    getBillingHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Settings">
      <div className="auth-page-wrapper pb-8.5">
        <div className="w-full container-max-width bg-white rounded-md px-7.5 1md:px-24.5 pt-9 pb-9">
          {/* <Link to={`/settings`}> */}
          <div
            className="flex items-center font-bold gap-1 hover:text-lightPrimary mb-2 text-sm text-darkerGray cursor-pointer"
            onClick={() => history.goBack()}>
            <ArrowLeftIcon className="font-bold" />
            <span>Back to Account</span>
          </div>
          {/* </Link> */}
          <div className="w-full flex flex-row gap-x-2 items-center">
            <h1 className="settings-title text-3xl text-lightBlack tracking-tighter font-bold">
              Billing Details
            </h1>
            <PopInfo containerClass="relative" type="billing_details" />
          </div>

          <div className="bg-siteBg rounded-md mt-4 mb-3 py-6 px-8">
            <div className="flex flex-col 2sm:flex-row">
              <div className="flex-1 flex-col">
                <h3 className="text-darkerGray font-semibold text-sm">
                  Current Plan:
                </h3>
                <p className="font-semibold flex mt-2 text-xs 3md:text-base flex-col 3md:flex-row">
                  {subscription.subscription.term.toLowerCase() === "month" &&
                    <span>Premium Monthly</span>}
                  {subscription.subscription.term.toLowerCase() === "year" &&
                    <span>Premium Yearly</span>}
                  {subscription.subscription.term.toLowerCase() ===
                    <span>lifetime && Premium Lifetime</span>}
                  <span className="text-primary ml-2 text-xs 3md:text-base">
                    ${subscription.subscription.price}/
                    {subscription.subscription.term.toLowerCase() === "month" &&
                      "monthly"}
                    {subscription.subscription.term.toLowerCase() === "year" &&
                      "yearly"}
                    {subscription.subscription.term.toLowerCase() ===
                      "lifetime" && "lifetime"}
                  </span>
                </p>
              </div>
              <div className="flex-1 flex-col px-1 2sm:px-6 2sm:border-l border-gray">
                <h3 className="text-darkerGray font-semibold text-xs 3md:text-sm">
                  Current Payment Method:
                </h3>
                <div className="flex flex-col 3md:flex-row items-center gap-x-2">
                  {subscription.payment_method.provider_type === "stripe" ? (
                    <CCIconSVG className="w-8 h-8" />
                  ) : (
                    <PaypalIconSVG className="w-8 h-8" />
                  )}
                  {subscription.payment_method.provider_type === "stripe" ? (
                    <p className="text-xs 3md:text-base font-bold">
                      **** **** **** {subscription.payment_method.last_digits}
                    </p>
                  ) : (
                    <p className="font-bold">
                      {subscription.payment_method.subscriber}
                    </p>
                  )}
                </div>
                <button
                  onClick={onChangePaymentMethod}
                  className="text-primary font-bold text-sm cursor-pointer">
                  Change Payment Method
                </button>
              </div>
              <div className="flex-1 flex-col 2sm:px-6 2sm:border-l border-gray">
                <h3 className="text-darkerGray font-semibold text-sm">
                  Next Billing Date:
                </h3>
                {userData.user.next_payment_date && (
                  <p className="font-semibold mt-2">
                    {moment(userData.user.next_payment_date).format(
                      "DD MMMM YYYY"
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
          <p className="text-sm text-darkGray">
            Plan fees are billed at the beginning of each period and may take a
            few days after the billing date to appear on your account.
          </p>
        </div>
        <div className="w-full 3md:px-24 pb-8.5">
          <div className="w-full mt-9 mb-3">
            <div className=" border-b border-gray pb-3 px-2 hidden 2sm:flex flex-row">
              <div className="flex-1 font-semibold text-darkerGray">Date</div>
              <div className="flex-1 font-semibold text-darkerGray">
                Description
              </div>
              <div className="flex-1 font-semibold text-darkerGray">
                Plan Period
              </div>
              <div className="flex-1 font-semibold text-darkerGray">
                Payment Method
              </div>
              <div className="justify-end font-semibold text-darkerGray">
                Total
              </div>
            </div>
            {billingHistory.map((billing, i) => (
              <div
                key={i}
                className="flex flex-col 2sm:flex-row border-b border-gray py-3 px-2">
                <div className="flex-1">
                  <p><span className="inline 2sm:hidden font-bold">Date : </span> {moment(billing.created_at).format("DD/MM/YYYY")}</p>
                </div>
                <div className="flex-1">
                  <p><span className="inline 2sm:hidden font-bold">Description : </span> {billing.description}</p>
                </div>
                <div className="flex-1">
                  <p><span className="inline 2sm:hidden font-bold">Plan Period : </span>
                    {moment(billing.start_period).format("DD/MM/YYYY")} -{" "}
                    {moment(billing.end_period).format("DD/MM/YYYY")}
                  </p>
                </div>
                {billing.provider === "stripe" ? (
                  <div className="flex flex-1 items-center">
                    <span className="inline 2sm:hidden font-bold">Payment Method : </span>
                    <CCIconSVG className="w-6 h-6 mr-2" />
                    <p>**** **** **** {billing.payment_method.last_digits}</p>
                  </div>
                ) : (
                  <div className="flex flex-1 items-center">
                    <span className="inline 2sm:hidden font-bold">Payment Method : </span>
                    <PaypalIconSVG className="w-6 h-6 mx-2" />
                    <p>{billing.payment_method.subscriber}</p>
                  </div>
                )}
                <div className=""> 
                  <p>
                  <span className="inline 2sm:hidden font-bold">Total : </span>${billing.amount}</p>
                </div>
              </div>
            ))}
          </div>
          <p className="text-sm">
            NOTE: We only show up to 1 year of billing history
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default BillingHistory;
