import React from "react";
import Modal from "components/global/modal";
import "./styles.css";
import { FaTimes } from "react-icons/fa";

export default function PdfViewerModal({ hide, url, ...props }) {
  return (
    <Modal
      width="w-19/20"
      contentClassName="rounded-lg overflow-auto custom-style"
      wrapperClassName="overflow-visible custom-style shadow-none"
    >
      <div className="w-full">
        <div className="w-full flex justify-end">
          <button onClick={hide}>
            <FaTimes className="text-2xl text-white" />
          </button>
        </div>
        <iframe
          title={props?.title}
          className="w-full"
          style={{ height: "calc(100vh - 100px)" }}
          src={url}
        />
      </div>
    </Modal>
  );
}
