import Modal from "components/global/modal";
import { FaTimes } from "react-icons/fa";
import Zoom from "./media-button/zoom";

const ImageModal = ({ hide, ...props }) => {
    const { source = null, viewUrl = "", isProfile = false } = props;
    return (
        <Modal
            width={`w-full`}
            contentClassName="rounded-lg overflow-auto custom-style"
            wrapperClassName="overflow-visible custom-style "
            centerModal="flex items-center justify-center md:inline"
        >
            <div className="flex-1 flex flex-col justify-center">
                <div className="flex flex-row-reverse justify-between items-baseline mb-4">
                    <button onClick={() => hide()} className="mr-4">
                        <FaTimes className="text-white text-xl" />
                    </button>
                </div>
                <div className="image-zoom flex-1 flex flex-col">
                    <div className={`bg-black flex justify-center flex-1`}>
                        <Zoom key={viewUrl || source}>
                            {({resetTransform}) => (
                                <img
                                    src={viewUrl || source}
                                    className={`object-cove m-auto ${
                                        isProfile ? "w-[400px]" : ""
                                    }`}
                                    style={{ maxHeight: "555px", opacity: 0, transition: 'all 0.3s 0.25s' }}
                                    alt="viewImage"
                                    onLoad={(e) => {
                                        /**
                                         * sometimes the library do not center the image 
                                         * when it's not done looading the image, so we trigger
                                         * resetTransform to recenter it after it's loaded
                                         */
                                        e.target.style.opacity = 1;
                                        typeof resetTransform === 'function' && resetTransform();
                                    }}
                                />

                            )}
                        </Zoom>
                    </div>
                </div>
                <div className="image-zoom-mobile">
                    <div className={`bg-black flex justify-center`}>
                        <img
                            src={viewUrl || source}
                            className={`image-zoom-mobile-image`}
                          
                            alt="viewImage"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ImageModal;
