import React, { useState, useEffect, useContext } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import ModalHeader from "components/global/modal-header";
import WYSIWYGEditor from "components/wysiwyg/wysiwyg";
import Input from "components/input/input";
import Select from "components/select/select";
import Toggle from "components/toggle/toggle";
import DateInput from "components/input-group/date-input";
import update from "immutability-helper";
import LinkGroupSelect from "components/link-group/LinkGroupSelect";
import _ from "lodash";
import FileUpload from "components/file-upload/file-upload";
import { useDialogHook } from "utils/customhooks";
import InputGroup from "components/input-group/input-group";
import { urlSerializer, isValidYoutubeVideoLink } from "utils/utils";
import LoadingIndicator from "assets/svg/loading-indicator";
import EditThumbnailModal from "./edit-thumbnail";
import CreatableSelect from "components/select/creatable";
import validator from "validator";
import moment from "moment";
import { isValidHttpUrl, maxUploadSize } from "utils/constant";
import CustomCheckbox from "components/checkbox/custom-checkbox";

import { usePrivacies } from "redux/modules/privacy/hooks";
import { useMediaTypes } from "redux/modules/media-type/hooks";
import { useMediaSubTypes } from "redux/modules/media-sub-type/hooks";
import { useMilestones } from "redux/modules/milestone/hooks";
import UploadSizeModal from "components/file-upload/upload-size-modal";

import UpgradePremium from "components/subscription/upgrade-premium";
import { SubscriptionContext } from "context/subscription";
import LockOutlineIcon from "assets/svg/upgrade/lock-outline-icon";
import { usePlacesWidget } from "react-google-autocomplete";

export default function EditMilestoneModal({
  hide,
  userData,
  profile,
  milestone,
  ...props
}) { 
  const { http } = global.services;
  const currentDate = moment().format("YYYY-MM-DD");
  const maxSelectedImages = 50;
  const { isSubscribed } = useContext(SubscriptionContext);

  const {
    refresh: refreshPrivacies,
    initialize: initializePrivacies,
    privacies,
    setPrivacies,
  } = usePrivacies();

  const {
    refresh: refreshMediaTypes,
    initialize: initializeMediaTypes,
    types: mediaTypes,
    setMediaTypes,
  } = useMediaTypes();

  const {
    refresh: refreshMediaSubTypes,
    initialize: initializeMediaSubTypes,
    types: mediaSubTypes,
    setMediaSubTypes,
  } = useMediaSubTypes();

  const { organizeBy } = useMilestones();

  const [state, setState] = useState({
    title: "",
    role: "",
    platform: "",
    location: "",
    category: "",
    privacy: "",
    description: "",
    date_note: "",
    loading: false,
    validationErrors: null,
  });
  const [startDate, setStartDate] = useState({
    day: null,
    month: null,
    year: null,
    approximately: false,
  });
  const [endDate, setEndDate] = useState({
    day: null,
    month: null,
    year: null,
    approximately: false,
    present: false,
  });
  const [jobs, setJobs] = useState([{ job_role: "", description: "" }]);
  const [linkType, setLinkType] = useState([
    { type: "", subType: "", value: "" },
  ]);
  const [adultContent, setAdultContent] = useState(true);
  const [featured, setFeatured] = useState(true);
  // const modal = useDialogHook(TagPeopleModal);
  const edit = useDialogHook(EditThumbnailModal);
  const [preview, setPreview] = useState(null);
  const [cropFile, setCropFile] = useState(null);
  const [btnAddPositionHidden, setBtnAddPositionHidden] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [mediaTypeOptions, setMediaTypeOptions] = useState(mediaTypes);
  const [positionOptions, setPositionOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const uploadSizeModal = useDialogHook(UploadSizeModal);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "category" && value) {
      createCategory(value);
    }

    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const { ref } = usePlacesWidget({
    apiKey:process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setState((prev) => ({
        ...prev,
        location: place.formatted_address,
        validationErrors: { ...state.validationErrors, location: null },
      }));
    }
  });

  const onAddTypeLink = () => {
    if (linkType.length === 6) return;

    let payload = _.clone(linkType);
    payload.push({ type: "", subType: "", value: "" });
    setLinkType(payload);
  };

  const onRemoveTypeLink = (index) => {
    // if (linkType.length === 1) return;

    // let types = mediaTypeOptions.map((mt) => {
    //   if (mt.value === linkType[index].type) {
    //     const { selected, ...val } = mt;
    //     return val;
    //   }
    //   return mt;
    // });
    // setMediaTypeOptions((prev) => types);

    let payload = _.clone(linkType);
    let result = update(payload, { $splice: [[index, 1]] }); //remove
    setLinkType(result);
  };

  const onSelectTypeLink = (e, index) => {
    // let types = mediaTypeOptions.map((mt) => {
    //   if (mt.value === e.value) {
    //     return {
    //       ...mt,
    //       selected: true,
    //     };
    //   } else if (linkType[index].type === mt.value) {
    //     const { selected, ...val } = mt
    //     return val
    //   }

    //   return mt
    // });
    // setMediaTypeOptions((prev) => types);

    let payload = _.clone(linkType);
    let result = update(payload, {
      [index]: {
        type: { $set: e.value },
      },
    }); //remove
    setLinkType(result);
  };

  const onSelectSubTypeLink = (e, index) => {
    let payload = _.clone(linkType);
    let params = {
      subType: { $set: e },
      value: { $set: "" },
    };
    if (payload.placeholder) {
      params.placeholder = { $set: "" };
    }
    let result = update(payload, {
      [index]: params,
    }); //remove
    setLinkType(result);
  };

  const onRemoveMediaLink = (e, index) => {
    let payload = _.clone(linkType);
    let results = linkType[index]["value"].filter((f, i) => i !== e);
    payload[index]["value"] = results.length > 0 ? results : "";

    // check if errorMessages has multiple_images and remove this error if results.length <= 10
    if (errorMessages?.multiple_images && results.length <= 10) {
      delete errorMessages.multiple_images;
    }

    setLinkType(payload);
    setErrorMessages({ ...errorMessages });
  };

  const onUploadLink = async (e, index) => {
    let payload = _.clone(linkType);
    let result = update(payload, {
      [index]: {
        value: { $set: e },
      },
    }); //remove
    setLinkType(result);
  };

  const showUploadAlert = () => {
    uploadSizeModal({}, (callback) => {
      console.log("callback", callback);
    });
  };

  const onUploadMultipleLink = async (e, index) => {
    let fileSize = 0;
    let payload = _.clone(linkType);
    let currValue = linkType[index].value ?? {};
    e.forEach((f) => {
      fileSize += parseFloat(f.size);
    });

    if (Array.isArray(currValue)) {
      currValue.forEach((cv) => {
        if (cv.media) {
          fileSize += parseFloat(cv.media.file_size || 0);
        } else {
          fileSize += cv.size || 0;
        }
      });
    } 
    console.log(fileSize);
    if (fileSize < maxUploadSize) {
      let result = update(payload, {
        [index]: {
          value: { $set: [...currValue, ...e] },
        },
      }); //remove
      setLinkType(result);
    } else {
      setErrorMessages({
        ...errorMessages,
        [`media.${index}.value`]: "The file is too large. Maximum size is 50MB.",
      });
      showUploadAlert();
    }
  };

  const onTypeLink = (e, index) => {
    let payload = _.clone(linkType);
    let result = update(payload, {
      [index]: {
        value: { $set: e.target.value },
      },
    }); //remove
    setLinkType(result);
  };

  const onBlurTypeLink = (index) => {
    let payload = _.clone(linkType);
    let value = payload[index].value;

    if (payload[index].subType && payload[index].subType.label === "Link") {
      if (value && !value.includes("http://") && !value.includes("https://")) {
        value = `https://${value}`;
      }

      let result = update(payload, {
        [index]: {
          value: { $set: value },
        },
      }); //remove
      setLinkType(result);
    }
  };

  const onChangeInput = (e, index) => {
    let payload = _.clone(jobs);
    let result = update(payload, {
      [index]: {
        description: { $set: e.target.value },
      },
    }); //remove
    setJobs(result);
  };

  const onAddPosition = () => {
    if (jobs.length === 5) return;

    let payload = _.clone(jobs);
    payload.push({ job_role: "", description: "" });
    setJobs(payload);

    if (jobs.length < 4) setBtnAddPositionHidden(false);
    else setBtnAddPositionHidden(true);
  };

  const onSelectJobRole = async (e, index) => {
    let _remove = jobs[index] ? jobs[index].job_role : {};
    let _value = e ? e.value : "";
    let opt = e ? e : "";

    if (!_value) {
      _remove = jobs[index].job_role;
    }

    if (e && e.__isNew__) {
      let createdPosition = await createPosition(e);
      opt = { label: createdPosition.name, value: createdPosition.id };
      _value = createdPosition.id;

      setSelectJobRole(_remove, _value, opt, index);
    } else {
      setSelectJobRole(_remove, _value, opt, index);
    }
  };

  const setSelectJobRole = (_remove, _value, opt, index) => {
    let types = positionOptions.map((op) => {
      if (op.value === _value) {
        return {
          ...op,
          selected: true,
        };
      } else if (op.value === _remove.value) {
        const { selected, ...val } = op;
        return val;
      }
      return op;
    });
    setPositionOptions((prev) => types);

    let payload = _.clone(jobs);
    let result = update(payload, {
      [index]: {
        job_role: { $set: opt },
      },
    }); //remove
    setJobs(result);
  };

  const createPosition = async (e) => {
    try {
      let { data } = await http.post("public/positions", {
        user_id: profile.user.id,
        user_profile_id: profile.id,
        position: e.label,
      });
      getPositions();
      return data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const createCategory = async (e) => {
    try {
      let { data } = await http.post("public/categories", {
        user_id: profile.user.id,
        user_profile_id: profile.id,
        category: e.label,
      });
      getCategories();
      return data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const onRemoveJobRole = (index) => {
    if (jobs.length === 1) return;

    // let types = positionOptions.map(op => {
    //   if (op.value === jobs[index].job_role.value) {
    //     const { selected, ...val } = op
    //     return val
    //   }
    //   return op;
    // })
    // setPositionOptions((prev) => types)

    let payload = _.clone(jobs);
    let result = update(payload, { $splice: [[index, 1]] }); //remove
    setJobs(result);

    if (result.length < 5) setBtnAddPositionHidden(false);
    else setBtnAddPositionHidden(true);
  };

  const onUploadImage = async (file) => {
    setCropFile(file);
  };

  // const onTagPeople = () => {
  //   modal({}, (result) => {
  //     console.log("tag people => ", result);
  //   });
  // };

  const onEditThumbnail = (image, type) => {
    if (image) {
      edit({ image: image, type: type, returnType: "blob" }, (result) => {
        if (result) {
          const { imageFile, preview } = result;
          setCropFile(imageFile);
          setPreview(preview);
        }
      });
    }
  };

  const submit = async () => {
    let errors = {};
    setErrorMessages(errors);
    let thumbnail = cropFile;

    //validate date
    let concatStartDate = `${startDate.year}-${startDate.month ?? "00"}-${
      startDate.day ?? "00"
    }`;
    let concatEndDate = endDate.present
      ? null
      : endDate.year
      ? `${endDate.year}-${endDate.month ?? "00"}-${endDate.day ?? "00"}`
      : null;

    if (!_.isEmpty(concatStartDate) && !_.isEmpty(concatEndDate)) {
      if (new Date(concatStartDate) > new Date(concatEndDate)) {
        errors = {
          ...errors,
          "start_date.day": [""],
          "start_date.month": [""],
          "start_date.year": [""],
          "end_date.day": [""],
          "end_date.month": [""],
          "end_date.year": [""],
          errorDates: ["The start date must be less than the end date."],
        };
      }
    }
    //end validate date

    if (startDate.day > 31) {
      errors = {
        ...errors,
        "start_date.day": ["The day field should not be greater than 31."],
      };
    }
    if (startDate.month > 12) {
      errors = {
        ...errors,
        "start_date.month": ["The month field should not be greater than 12."],
      };
    }
    if (endDate.day > 31) {
      errors = {
        ...errors,
        "end_date.day": ["The day field should not be greater than 31."],
      };
    }
    if (endDate.month > 12) {
      errors = {
        ...errors,
        "end_date.month": ["The month field should not be greater than 12."],
      };
    }

    //if (preview) thumbnail = await fetch(preview).then((res) => res.blob()); 
    let formData = new FormData();

    if (thumbnail) formData.append("thumbnail", thumbnail);

    formData.append("user_id", userData.user.id);
    formData.append("user_profile_id", userData.profile.id);
    formData.append("title", state.title);
    formData.append("description", state.description);
    formData.append("platform", state.platform);
    formData.append("location", state.location);
    formData.append("date_note", state.date_note);

    // position
    jobs.forEach((p, index) => {
      if (typeof p.job_role === "object") {
        const { value } = p.job_role;
        formData.append(`position[${index}][job_role]`, value);
      } else {
        formData.append(`position[${index}][job_role]`, p.job_role);
      }
      formData.append(`position[${index}][description]`, p.description);
    });
   


    // media
    linkType.forEach(async (media, index) => {
      if (media.type || media.subType) {
        formData.append(`media[${index}][type]`, media.type);

        if (media.subType) {
          formData.append(`media[${index}][sub_type]`, media.subType.value);
          if (media.subType.is_media_file) {
            formData.append(`media[${index}][is_media]`, true);
          } else {
            formData.append(`media[${index}][is_media]`, false);
          }
        } else {
          formData.append(`media[${index}][sub_type]`, media.subType);
        }

        if (typeof media.value === "object") {
          if (Array.isArray(media.value)) {
            media.value.map(async (file, fileIndex) => {
              if (file.hasOwnProperty("media")) {
                formData.append(
                  `media[${index}][media_id][${fileIndex}]`,
                  file.media_id
                );
                formData.append(
                  `media[${index}][value][${fileIndex}]`,
                  JSON.stringify(file)
                );
              } else {
                formData.append(`media[${index}][value][${fileIndex}]`, file);
              }
            });
          } else {
            if (media.value.hasOwnProperty("media")) {
              formData.append(
                `media[${index}][media_id][0]`,
                media.value.media_id
              );
              formData.append(
                `media[${index}][value][0]`,
                JSON.stringify(media.value)
              );
            } else {
              formData.append(`media[${index}][value][0]`, media.value);
            }
          }
        } else {
          //validate
          if (media?.subType?.is_link) {
            if (media?.subType?.label === "Youtube/Vimeo Link") {
              if (!isValidYoutubeVideoLink(media.value)) {
                errors = {
                  ...errors,
                  [`media.${index}.value`]: "Invalid youtube/vimeo link",
                };
              }
            }

            if (media.value) {
              if (validator.isURL(media.value) && isValidHttpUrl(media.value)) {
                formData.append(`media[${index}][value]`, media.value);
              } else {
                errors = {
                  ...errors,
                  [`media.${index}.value`]: "Invalid url link.",
                };
              }
            } else {
              formData.append(`media[${index}][value]`, media.value);
            }
          } else {
            formData.append(`media[${index}][value]`, media.value);
          }
        }
        formData.append(`media[${index}][other_description]`, media.other_description);
      }
    });

    if (!_.isEmpty(errors)) {
      return setErrorMessages((prev) => ({ ...prev, ...errors }));
    }

    if (state.category) formData.append("category", state.category.value);
    if (state.privacy) formData.append("privacy", state.privacy.value);

    formData.append("is_featured", featured);
    formData.append("is_adult_content", adultContent);

    if (startDate.day) {
      formData.append("start_date[day]", startDate.day);
    }

    if (startDate.month) {
      formData.append("start_date[month]", startDate.month);
    }

    if (startDate.year) {
      formData.append("start_date[year]", startDate.year);
    } else {
      errors = {
        ...errors,
        "start_date.year": ["The year field is required."],
      };
    }

    if (endDate.day) formData.append("end_date[day]", endDate.day);
    if (endDate.month) formData.append("end_date[month]", endDate.month);
    if (endDate.year) formData.append("end_date[year]", endDate.year);

    formData.append("start_date[approximately]", startDate.approximately);
    formData.append("end_date[approximately]", endDate.approximately);
    formData.append("end_date[present]", endDate.present);
    formData.append("organize_by", organizeBy);
    formData.append("_method", "PUT");

    try {
      setSubmitLoading(true);
      const { data } = await http.post(
        `public/milestones/${milestone.id}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
          mode: "no-cors",
        }
      );
      console.log("create milestone return data => ", data);
      getPositions();
      getCategories();
      hide({ success: true, data: data.data });
    } catch (err) {
      console.log("error@submit", err);
      const { data, status } = err;
      if (status === 422) {
        errors = data.errors;
      }
      setSubmitLoading(false);
    } finally {
      setSubmitLoading(false);
    }

    setErrorMessages((prev) => ({ ...prev, ...errors }));
  };

  const Footer = () => {
    return (
      <div className="w-full flex items-center justify-start 1sm:justify-end space-x-2">
        <Button
          buttonName="Cancel"
          buttonClass="bg-thBg py-4 px-8 hover:bg-gray"
          buttonTextClass="text-black text-sm font-semibold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn-category"
          buttonName="Update Milestone"
          buttonClass="bg-primary py-4 px-8 hover:bg-darkPrimary"
          buttonTextClass="text-white text-sm font-semibold flex items-center"
          onClick={submit}
          loadingIcon={
            submitLoading ? (
              <LoadingIndicator className="text-white w-4 h-4 ml-2" />
            ) : null
          }
        />
      </div>
    );
  };

  /**
   * This method will fetch
   * position filter options
   */
  const getPositions = async () => {
    try {
      const url = urlSerializer("public/positions", {
        user_profile_id: profile.id,
      });

      const { data } = await http.get(url);
      const positions = data.map((position) => ({
        label: position.name,
        value: position.id,
      }));
      setPositionOptions(positions);
    } catch (err) {
      console.log("error@getPositions", err);
      throw err;
    }
  };

  /**
   * This method will fetch
   * categories filter options
   */
  const getCategories = async () => {
    try {
      const url = urlSerializer("public/categories", {
        user_profile_id: profile.id,
      });
      const { data } = await http.get(url);
      const categories = data.map((category) => ({
        label: !profile.dateFormat ? (category.english_version || category.name) : category.name,
        value: category.id,
      }));
      setCategoriesOptions(categories);
    } catch (err) {
      console.log("error@getCategories", err);
      throw err;
    }
  };

  /**
   * This method will fetch
   * privacies filter options
   */
  const getPrivacies = async () => {
    try {
      const { data } = await http.get("public/privacies");
      const privacies = data.map((category) => ({
        label: category.name,
        value: category.name,
      }));
      setPrivacies(privacies);
    } catch (err) {
      console.log("error@getPrivacies", err);
      throw err;
    }
  };

  /**
   * This method will fetch
   * media type filter options
   */
  const getMediaTypes = async () => {
    try {
      const { data } = await http.get("public/media-types");
      const types = data.map((type) => ({
        label: type.name,
        value: type.id,
      }));
      setMediaTypes(types);
      setMediaTypeOptions(types);
    } catch (err) {
      console.log("error@getMediaTypes", err);
      throw err;
    }
  };

  /**
   * This method will fetch
   * media sub type filter options
   */
  const getMediaSubTypes = async () => {
    try {
      const { data } = await http.get("public/media-sub-types");
      const types = data.map((type) => ({
        label: type.name,
        value: type.id,
        is_media_file: type.is_media_file,
        is_link: type.is_link,
        ...type.option,
      }));
      setMediaSubTypes(types);
    } catch (err) {
      console.log("error@getMediaSubTypes", err);
      throw err;
    }
  };

  const getMilestonePositions = async () => {
    let positions = [];
    try {
      const { data } = await http.get(
        `public/milestones/${milestone.id}/positions`
      );
      data.forEach((p) => {
        positions.push({
          job_role: {
            label: p.position.name,
            value: p.position_id,
          },
          description: p.description || "",
        });
      });

      setJobs(positions);
    } catch (err) {
      console.log("error@getMilestonePositions", err);
      throw err;
    }
  };

  const updateJobRoleOption = async () => {
    let types = positionOptions.map((op) => {
      const job = jobs.filter((j) => op.value === j.job_role?.value);
      if (!_.isEmpty(job)) {
        return {
          ...op,
          selected: true,
        };
      } else {
        const { selected, ...val } = op;
        return val;
      }
    });
    setPositionOptions(types);
  };

  useEffect(() => {
    getPositions();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initializePrivacies) getPrivacies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPrivacies]);

  useEffect(() => {
    if (initializeMediaTypes) getMediaTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshMediaTypes]);

  useEffect(() => {
    if (initializeMediaSubTypes) getMediaSubTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshMediaSubTypes]);

  useEffect(() => {
    let startDate = {
      day: milestone.start_day,
      month: milestone.start_month,
      year: milestone.start_year,
      approximately: milestone.is_start_date_approximately ? true : false,
    };

    let endDate = {
      day: milestone.end_day,
      month: milestone.end_month,
      year: milestone.end_year,
      present: milestone.is_present ? true : false,
      approximately: milestone.is_end_date_approximately ? true : false,
    };

    const category = categoriesOptions.filter(
      (c) => c.value === milestone.category_id
    );
    const privacy = privacies.filter((c) => c.value === milestone.privacy);

    let description = milestone.description;

    if (!_.isEmpty(milestone.mention_names)) {
      let anchorTags = description.split("<a");

      Object.keys(milestone.mention_names).forEach((k) => {
        let isInsideAnchor = false;

        anchorTags.forEach((tag) => {
          if (tag.includes(`${milestone.mention_names[k].username}</a>`)) {
            isInsideAnchor = true;
            return true;
          }
        });

        if (isInsideAnchor) {
          description = description.replaceAll(
            `${milestone.mention_names[k].username}</a>`,
            `[@${milestone.mention_names[k].p_username}]</a>`
          );
        }
      });
    }

    setState((prev) => ({
      ...prev,
      id: milestone.id,
      title: milestone.title,
      platform: milestone.platform || "",
      location: milestone.location || "",
      date_note: milestone.date_note || "",
      category: category[0],
      privacy: privacy[0],
      description,
    }));

    if (milestone.thumbnail) {
      setPreview(milestone.thumbnail.url);
    }

    if (!_.isEmpty(milestone.media_type)) {
      let links = [];

      milestone.media_type.forEach((media) => {
        const { name, option, ...rest } = media.sub_type;

        if (name === "Multiple Images") {
          links.push({
            type: media.media_type_id,
            subType: {
              label: name,
              value: rest.id,
              is_media_file: rest.is_media_file,
              is_link: rest.is_link,
              ...option,
            },
            value: media.media,
          });
        } else {
          let val = {
            type: media.media_type_id,
            subType: {
              label: name,
              value: rest.id,
              is_media_file: rest.is_media_file,
              is_link: rest.is_link,
              ...option,
            },
          };

          if (media.hasOwnProperty("media") && media.media.length > 0) {
            val.value = media.media[0];
            val.placeholder = media.media[0]["media"]["file_name"];
          } else {
            val.value = media.url;
          }
          val.other_description = media?.other_description;
          links.push(val);
        }
      });
      setLinkType(links);
    }

    setStartDate((prev) => ({
      ...prev,
      ...startDate,
    }));
    setEndDate((prev) => ({
      ...prev,
      ...endDate,
    }));

    setFeatured(() => (milestone.is_featured ? true : false));
    setAdultContent(() => (milestone.is_adult_content ? true : false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone, categoriesOptions, privacies, mediaTypes]);

  useEffect(() => {
    let types = mediaTypeOptions.map((mt) => {
      let exist = linkType.filter((l) => l.type === mt.value);
      if (exist.length > 0) {
        return {
          ...mt,
          selected: true,
        };
      } else {
        const { selected, ...val } = mt;
        return val;
      }
    });
    setMediaTypeOptions((prev) => types);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkType]);

  useEffect(() => {
    getMilestonePositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone]);

  useEffect(() => {
    updateJobRoleOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, categoriesOptions]);

  useEffect(() => {
    // check if startDate > currentDate
    if (
      `${startDate.year ?? "00"}-${startDate.month ?? "00"}-${
        startDate.day ?? "00"
      }` > currentDate
    ) {
      endDate.present = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const onDescription = async (e, index) => {
    let payload = _.clone(linkType);
    let result = update(payload, {
      [index]: {
        other_description: { $set: e.target.value },
      },
    }); 
    setLinkType(result);
  }
  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-18/20 2md:w-17/20 3md:w-15/20 4md:w-13/20 1xl:w-11/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-gray">
      <div className="w-full">
        {/* header */}
        <ModalHeader title="Edit Milestone" hide={hide} />
        {/* content */}
        <div className="px-8.5 py-6 h-[70dvh] overflow-y-scroll">
          {/* profile */}
          <Input
            inputContainerClass="form-input-container"
            value={state?.title}
            name="title"
            outsideLabel="Title"
            placeholder="e.g. Star Wars"
            outsideLabelClass="text-sm font-bold text-darkerGray marked"
            noLabelPaddingY="1rem"
            type="text"
            onChange={handleInputChange}
            errorMessage={errorMessages?.title}
            errorMessagePosition="bottom"
          />
          <InputGroup
            title="position"
            subTitleLeft="Job / Role"
            subTitleRight="Additional information / Role played (optional)"
            options={positionOptions}
            items={jobs}
            fieldKey="job_role"
            onChange={(e, index) => onChangeInput(e, index)}
            onSelect={(e, index) => onSelectJobRole(e, index)}
            onRemove={(index) => onRemoveJobRole(index)}
            onAdd={onAddPosition}
            hideAddButton={btnAddPositionHidden}
            errors={errorMessages}
            customClass="marked"
          />
          <div className="flex gap-4 milestone-location">
            <Input
              inputContainerClass="form-input-container"
              value={state?.platform}
              name="platform"
              outsideLabel="Platform"
              otherLabel="Company/Venue/Network/Publisher/Medium/Website"
              placeholder="e.g. 20 Century Fox"
              optional={true}
              outsideLabelClass="text-sm font-bold text-darkerGray"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={errorMessages?.platform}
            />
            {/* <Input
                inputContainerClass="form-input-container"
                value={state?.location}
                name="location"
                outsideLabel="Location"
                otherLabel="City/Town, Country"
                placeholder="e.g. Melbourne Australlia"
                optional={true}
                outsideLabelClass="text-sm font-bold text-darkerGray"
                noLabelPaddingY="1rem"
                type="text"
                onChange={handleInputChange}
                errorMessage={errorMessages?.location}
              /> */}

            <div className="form-input-container">
              <label for="location" className="text-sm font-bold text-darkerGray leading-4">
                Location
                <span className="font-normal"> (optional)</span>
                <span className="font-normal leading-4"><br/>City/Town, Country</span>
              </label>
              <div className="input-wrapper mt-2">
                <input ref={ref}  
                  className="input-class w-full" type="text" name="location" 
                  placeholder="Hobart, Tas, AUS" 
                  value={state?.location || ""}
                  onChange={handleInputChange}
                  style={{
                    padding:  "1rem",
                  }}
                  />
                  {
                    errorMessages?.location && 
                    <p className="input-error-message">* {errorMessages?.location}</p>
                  }
                </div>
              </div>
          </div>
          <WYSIWYGEditor
            label="Description"
            optional={true}
            value={state.description}
            limitChar={420}
            onChange={(value) =>
              handleInputChange({
                target: { name: "description", value },
              })
            }
            preserveWhitespace={true}
          />
          <FileUpload
            onUpload={onUploadImage}
            outsideLabel="Add Image"
            optional={true}
            preview={preview}
            setPreview={setPreview}
            onEdit={(image, type) => onEditThumbnail(image, type)}
            cropOnload={true}
            placeholder="imagefilename.jpg"
            accept="image/*"
            label="Select File"
          />
          {/** media buttons */}
          {isSubscribed ? (
            <LinkGroupSelect
              options={mediaTypeOptions}
              subOptions={mediaSubTypes}
              label="Add Image, PDF, Audio File or Link"
              optional={true}
              items={linkType}
              onAdd={onAddTypeLink}
              onUploadMultiple={(e, index) => onUploadMultipleLink(e, index)}
              onUpload={(e, index) => onUploadLink(e, index)}
              onChange={(e, index) => onTypeLink(e, index)}
              onSelect={(e, index) => onSelectTypeLink(e, index)}
              onBlur={(index) => onBlurTypeLink(index)}
              onRemove={(index) => onRemoveTypeLink(index)}
              onSelectSubType={(e, index) => onSelectSubTypeLink(e, index)}
              onRemoveMedia={(e, index) => onRemoveMediaLink(e, index)}
              onChangeDescription={(e, index) => onDescription(e, index)}
              errors={errorMessages}
              maxSelectedImages={maxSelectedImages}
            />
          ) : (
            <div className="mt-2 mb-8">
              <div className="flex flex-row items-center mb-4 space-x-2">
                <LockOutlineIcon />
                <label className="text-sm font-bold text-darkerGray leading-4">
                  Add Image, PDF, Audio File or Link"
                  <span className="font-normal">{" (optional)"}</span>
                </label>
              </div>
              <UpgradePremium
                styleProp="list"
                onClick={() => hide("upgrade")}
              />
            </div>
          )}
          <div className="w-full grid 1smd:grid-cols-2 1smd:gap-7">
            <DateInput
              label="Start"
              name="startDate"
              showApp={true}
              date={startDate}
              setDate={setStartDate}
              errors={errorMessages}
              fieldKey="start_date"
              required={true}
            />
            <DateInput
              label="End"
              name="endDate"
              showApp={true}
              showPresent={
                `${startDate.year ?? "00"}-${startDate.month ?? "00"}-${
                  startDate.day ?? "00"
                }` <= currentDate
              }
              date={endDate}
              setDate={setEndDate}
              errors={errorMessages}
              fieldKey="end_date"
            />
          </div>
          {errorMessages?.errorDates && (
            <p className="text-sm text-red">{`*${errorMessages.errorDates[0]}`}</p>
          )}
          <div className="w-full grid 4sm:grid-cols-3 4sm:gap-6">
            <Input
              inputContainerClass="form-input-container"
              value={state?.date_note}
              name="date_note"
              outsideLabel="Date Note"
              placeholder="e.g. 3 episodes"
              outsideLabelClass="text-sm font-bold text-darkerGray"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={errorMessages?.date_note}
              errorMessagePosition="bottom"
            />
            <CreatableSelect
              label="Category"
              options={categoriesOptions}
              placeholder="Please Select"
              value={categoriesOptions.filter((c) => c === state.category)[0]}
              height={58}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "category", value: e },
                })
              }
              errorMessage={errorMessages?.category}
              labelClass="text-sm font-bold text-darkerGray leading-4 marked"
            />
            <Select
              label="Privacy"
              options={privacies}
              placeholder="Please Select"
              name="privacy"
              value={state.privacy || ""}
              height={58}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "privacy", value: e },
                })
              }
              errorMessage={errorMessages?.privacy}
              labelClass="text-sm font-bold text-darkerGray leading-4 marked"
            />
          </div>

          <div className="w-full">
            <div className="flex space-x-2 items-center mb-2">
              <div className="">
                  {/* <Toggle
                    checked={adultContent}
                    name="adultContent"
                    onChange={(e) => setAdultContent(e?.target?.checked)}
                  /> */} 
                <CustomCheckbox
                  name={`adultContent`}
                  checked={adultContent}
                  onChange={(e) => setAdultContent(e?.target?.checked)} 
                  isBoxType={true}
                /> 
                </div>
              <p className="text-base text-darkerGray font-bold">
                This milestone does not contain adult content
              </p>
            </div>
            <div className="flex space-x-2 items-center">
              <div>
                {/* <Toggle
                  checked={featured}
                  name="featured"
                  onChange={(e) => setFeatured(e?.target?.checked)}
                /> */}
                <CustomCheckbox
                  checked={featured}
                  name="featured"
                  onChange={(e) => setFeatured(e?.target?.checked)}
                  isBoxType={true}
                /> 
              </div>
              <p className="text-base text-darkerGray font-bold">
                Pin This Milestone
              </p>
            </div>
          </div>
        </div>
        {/* end content */}
      </div>
    </Modal>
  );
}
