import React, { useEffect, useState, useMemo, useRef } from "react";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import Layout from "layouts/layout";
import SearchIconSVG from "assets/svg/search-icon";
import ConversationList from "components/message/conversation-list";

import MessageHeader from "components/message/message-header";
import MessageContent from "components/message/message-content";
import toast from "react-hot-toast";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

import { useMessages } from "redux/modules/messages/hooks";
import PopInfo from "components/info-popup/pop-info";
import { Link } from "react-router-dom";
import ArrowLeftIcon from "assets/svg/arrow-left-icon";
import { randomExtension } from "utils/utils";
import MessageDeleteModal from "components/message/message-delete";
import { useDialogHook } from "utils/customhooks";
import axios from "axios";

const Messages = ({ userData, setUserData }) => {
  const { http } = global.services;
  const [search, setSearch] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  // const [messages, setMessage] = useState([]);
  // const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const extension = randomExtension();
  const searchTimeoutRef = useRef(null);
  const cancelTokenRef = useRef(null);
  
  const modalDelete = useDialogHook(MessageDeleteModal);
  const {
    initialize,
    messages,
    selected,
    setActiveThread,
    setMessages,
    setBlockMessages,
    conversations,
    setConversations,
  } = useMessages();

  const lastSeen = useMemo(() => {
    if (!selected || !selected?.thread?.participant?.last_seen) {
      return null;
    }

    return moment.utc(selected?.thread?.participant?.last_seen).fromNow();
  }, [selected]);

  const getMessages = async () => {
    // Cancel the previous request if it exists
    if (cancelTokenRef.current) {
      console.log("Aborting previous request...");
      cancelTokenRef.current.cancel("Operation canceled due to new request.");
    }

    // Create a new CancelToken source
    cancelTokenRef.current = axios.CancelToken.source();


    try {
      setLoading(true);
      const { data } = await http.get("public/messages/inbox", {
        user_profile_id: userData?.profile?.id,
        limit: 5,
        offset: 0,
        search: search
      }, 
      {
          cancelToken: cancelTokenRef.current.token, // Pass the cancel token here
      });
      if (data) {
        setMessages(data);
        if (localStorage.getItem("selectedMessage") !== null) {
          let selectedMessage = JSON.parse(localStorage.getItem("selectedMessage"));
          if(selectedMessage?.id){
            const index = data.findIndex((item) => item.id === selectedMessage?.id);
            setActiveThread(data[index]);
          }
        }
        // setSelected(data[0]);
      }
    } catch (err) {
      if(err.message && err.message.includes('Operation canceled')) {
      }else{
        setLoading(false);
        toast.error("An Error occured. Failed to fecth messages");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialize) {
      getMessages();
    }else if(isDirty){
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      // Set a new timeout to delay the search by 350 millisecond
      searchTimeoutRef.current = setTimeout(() => {
        getMessages();
      }, 350);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize, search]);

  const updateSearch = (e) => {
    setSearch(e?.target?.value)
    setIsDirty(true);
  }
  

  // const blockMessageHandler = async (status) => {
  //   try {
  //     setLoading(true);
  //     let statusBlock = 1;
  //     if (status) {
  //       statusBlock = status === 1 ? 0 : 1;
  //     }
  //     const { data } = await http.post("public/messages/block", {
  //       owner_id: userData?.profile?.id,
  //       block_id: selected?.thread?.participant?.profile?.id || null,
  //       thread_id: selected?.thread?.id,
  //       status: statusBlock,
  //     });
  //     if (data) {
  //       selected.thread = { ...selected.thread, message_block: { ...data } };
  //       setBlockMessages(selected);
  //       console.log("data => ", selected);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     console.log("error@getPositions", err);
  //     toast.error("An Error occured. Failed to fecth messages");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const deleteConversation = async()=>{
      modalDelete({userData, selected}, (callback) => {  
        if (callback?.success) {   
          if (callback?.data) { 
            let data = {...callback?.data.data, disable_delete_btn: true};
            setConversations([data]);
            getMessages();
            localStorage.removeItem("selectedMessage")
          }
        }
      }); 
  }

  return (
    <Layout
      title="Messages"
      containerStyle="lg:static lg:overflow-y-auto lg:overflow-x-hidden">
      <div className="auth-page-wrapper pb-8.5">
        <div className="w-full container-max-width bg-white rounded-md p-7.5">
          <Link
            to={`/${userData?.user?.username}-${extension}/profile/${userData?.profile?.id}`}>
            <div className="flex items-center font-bold gap-1 hover:text-lightPrimary mb-2 text-sm text-darkerGray">
              <ArrowLeftIcon className="font-bold" />
              <span>Back to Profile</span>
            </div>
          </Link>
          <div className="w-full flex justify-between items-center"> 
            <div className="w-full flex flex-row gap-x-2 items-center">
              <h1 className="text:base 2sm:text-3xl text-lightBlack tracking-tighter font-bold">
                Messages
              </h1>
              <PopInfo containerClass="relative" type="messages" />
            </div>
          </div>
        </div>
        {/* content */}
        <div className="message-input-search mt-2 flex 2md:hidden 3md:hidden items-center">
          <SearchIconSVG className="text-darkerGray absolute left-6" />
          <input
            placeholder="Search"
            className="pr-5.5 py-10"
            value={search}
            onChange={updateSearch}
          />
        </div>
        <div className="w-full container-max-width mt-4">
          <div
            className="w-full flex space-x-4"
            style={{ minHeight: "calc(100vh - 345px)" }}>
            <div className="bg-white rounded-md w-24.5 3md:w-6/20">
              {/* search */}
              <div className="py-2.5 w-full border-b border-gray hidden 2md:flex 3md:flex">
                <div className="message-input-search">
                  <SearchIconSVG className="text-darkerGray absolute top-2.5 left-6" />
                  <input
                    placeholder="Search"
                    className="pr-5.5"
                    value={search}
                    onChange={updateSearch}
                  />
                </div>
              </div>
              {/* conversation users list */}
              <div className="w-full">
                {loading ? (
                  <div className="px-2.5 py-3">
                    <Skeleton count={10} className="mt-4" />
                  </div>
                ) : (
                  <>
                    {_.isEmpty(messages) ? (
                      <div className="px-2.5 py-3">
                        <p className="text-center opacity-50">
                          Thread is Empty
                        </p>
                      </div>
                    ) : (
                      <ThreadList
                        {...{ messages, selected, setActiveThread }}
                        // messages={messages}
                        // selected={selected}
                        // setSelected={setSelected}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            {/*  */}
            <div className="bg-white rounded-md w-full 3md:w-14/20">
              {loading ? (
                <div className="p-3">
                  <Skeleton count={15} className="mt-2" />
                </div>
              ) : (
                <>
                  {!_.isEmpty(selected) && (
                    <>
                      {/* header content */}
                      <MessageHeader
                        message_block={selected?.thread?.message_block}
                        owner_id={userData?.profile?.id}
                        // blockMessageHandler={blockMessageHandler}
                        deleteConversation={deleteConversation}
                        conversations={conversations}
                        image={
                          selected?.thread?.participant?.profile
                            ?.profile_image_url
                        }
                        name={
                          selected?.thread?.participant?.profile?.user
                            ?.first_name +
                          " " +
                          selected?.thread?.participant?.profile?.user
                            ?.last_name
                        }
                        status={lastSeen}
                      />
                      {/* Message Content */}
                      <MessageContent
                        userId={userData.user.id}
                        userProfileId={userData.profile.id}
                        threadId={selected.thread_id}
                        participantId={
                          selected?.thread?.participant?.profile?.id
                        }
                        message_block={selected?.thread?.message_block}
                        type={selected?.thread?.type}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

const ThreadList = ({ messages = [], selected, setActiveThread }) => {
  const { setUnreadMessages } = useMessages();
  const readMessage = async (obj, setActiveThread) => {
    const { http } = global.services;
    localStorage.setItem('selectedMessage', JSON.stringify(obj))
    try {
      setActiveThread(obj);
      const { data } = await http.put("public/messages/action/read", {
        user_profile_id: obj.user_profile_id,
        thread_id: obj.thread_id,
      });
      setUnreadMessages(data.unread);
    } catch (err) {
      console.log("onSeen", err);
    }
  };

  return (
    <>
      {messages.map((obj, index) => (
        <ConversationList
          key={index}
          selected={selected}
          obj={obj}
          onClick={(obj) => readMessage(obj, setActiveThread)}
        />
      ))}
    </>
  );
};
