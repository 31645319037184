import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import Button from "components/button/button";
import { FaEllipsisH } from "react-icons/fa";
import { IoEllipse } from "react-icons/io5";
import { BsGlobe2, BsExclamationDiamondFill } from "react-icons/bs";
import { AiFillPushpin } from "react-icons/ai";
import UserTagIconSVG from "assets/svg/user-tag-icon";
import { Context } from "context/context";
import { formatDate, isImgUrl } from "utils/utils";
import ReactPlayer from "react-player";
import _ from "lodash";
import Popup from "reactjs-popup";
import OptionListItem from "./more-option/option-list-item";
import { gsap } from "gsap";
import AudioPlayer from "./media-button/audio-player";
import LazyImage from "./LazyImage";
import { imageSquareOrPortrait } from "utils/utils";
import { useMilestones } from "redux/modules/milestone/hooks";
import PrivacyBadge from "components/milestonelist/privacyBadge";
import { DATE_NEWEST_TO_OLDEST, DATE_OLDEST_TO_NEWEST } from "constants/options";
import CharacterLimit from "./character-limit";
import ToolTip from "components/tootltip/tootltip";
import { CoverPlaceholderMilestone } from "constants/images";

const DetailedViewCard = ({
  title,
  dateNote,
  viewUrl,
  date,
  people,
  url = "",
  description,
  privacy,
  isAdultContent,
  category,
  buttons = [],
  positions = [],
  platform,
  isYourProfile,
  permission,
  profile,
  featured,
  onWatchMedia = () => {},
  onViewImage = () => {},
  handleViewPdf = () => {},
  onEdit = () => {},
  onDelete = () => {},
  //
  onShare = () => {},
  share,
  closeModal = () => {},
  onReport = () => {},
  hide = () =>{},
  handlePopup = ()=>{},
  activePopup = {},
  group = null,
  setActivePopup = ()=>({ group:null, index: null}),
  indexPopUp = -1,
  organizeBy = null
}) => {
  const { showMaturedContent, setShowMaturedContent } = useContext(Context);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [hideMusic, setHideMusic] = useState(false);
  const [imageHeight, setImageHeight] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const listenRef = useRef();
  const optionRef = useRef();
  const contentRef = useRef();
  const [isVisible, setIsVisible] = useState(-1);
  const {
    setPLayMusic,
  } = useMilestones();

  // ================== handleMedia ================================
  const handleMedia = async (media) => {
    let url = "";
    let fileExt = "";

    if (
      media.sub_type.name === "Multiple Images" ||
      media.sub_type.name === "Single Image"
    ) {
      onViewImage(title, media, media["media"]);
    } else {
      if (media.url) {
        url = media.url;
      } else {
        url = media["media"][0]["media"]["url"];
        fileExt = media["media"][0]["media"]["file_extension"];
      }

      if (await isImgUrl(url)) {
        onViewImage(title, media, media["media"]);
      } else if (ReactPlayer.canPlay(url)) {
        onWatchMedia(title, url);
      } else if (fileExt === "pdf") {
        handleViewPdf(title, url);
      } else {
        if (url.includes("https://www.tiktok.com/")) {
          onWatchMedia(title, url, true);
        } else {
          window.open(url, "_blank");
        }
      }
    }
  };
  // ================== useEffect ================================
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (optionRef.current) {
          optionRef.current.close(); 
        }
      },
      true
    );
  }, [showAudioPlayer]);

  useLayoutEffect(() => {
    gsap.fromTo(
      ".milestone-item",
      {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
      }
    );
  }, []);

  useEffect(() => {
    (async () => {
      const res = await imageSquareOrPortrait(url);
      const element = contentRef.current;
      if (res === "portrait") {
        if (element) {
          if (element.offsetHeight < 300) {
            setImageHeight(300);
          } else {
            setImageHeight(element.offsetHeight);
          }
          setImageUrl(url);
        }
      } else {
        setImageUrl(url);
      }
    })();
  }, [url]); 

  return (
    <div className="milestone-item w-full bg-siteBg rounded-md mt-3 2sm:mt-4.5 3md:mt-6 mb-8">
      <div
        className={`w-full flex flex-col 2md:flex-row space-y-0.5 2md:space-x-0.5 2md:space-y-0  ${
          imageUrl ? "min-h-300" : "min-h-300"
        }`}>
        {imageUrl ? (
          <div className="relative bg-white h-auto 2md:min-h-[300px] 2md:w-[300px]">
            {/* thumbnail */}
            <LazyImage
              viewUrl={viewUrl}
              source={imageUrl}
              customClassName="w-full md:max-w-[300px] h-auto 2md:max-h-[300px]"
              imageHeight={imageHeight}
              withContainer="w-full 2md:w-[300px]"
            />
            {/* restricted Image */}
            {/* {!isAdultContent && !showMaturedContent && (
              <div className="absolute top-0 left-0 matured-content-blured">
                <div className="w-full px-6 text-center">
                  <div className="flex justify-center">
                    <BsExclamationDiamondFill className="text-5xl" />
                  </div>
                  <h3 className="mt-4.5 font-bold text-lg">Mature Content</h3>
                  <p className="mt-4 text-sm font-medium hidden sm:block">
                    You must be 18+ to view this image. Are you over eighteen
                    and willing to see adult content?
                  </p>
                  <Button
                    buttonName="Continue"
                    buttonTextClass="text-sm text-white font-semibold"
                    buttonRadius="6px"
                    buttonClass="relative py-4 px-11 bg-transparent border-2 border-white mt-5.5"
                    onClick={() => setShowMaturedContent((prev) => !prev)}
                  />
                </div>
              </div>
            )} */}
          </div>
        ):
        <div className="relative min-h-[300px] 2md:w-[300px] flex items-center justify-center bg-[#e9e9e9]">
            <div className="w-full 2md:w-[300px] flex items-center justify-center h-full bg-[#e9e9e9]">
              <img src={CoverPlaceholderMilestone} alt="Milestone" className="h-[111px] w-[113px]"/>
            </div>
        </div>
        }
        <div
          className={`relative bg-white 2md:mt-0 px-6 pb-6 pt-4 2sm:py-4 2sm:px-7.5 p-7 w-full ${
            imageUrl
              ? "width-responsive-detailed-view"
              : "width-responsive-detailed-view-full"
          }`}
          ref={contentRef}>
          {/* content */}
          <div className="w-full flex justify-between">
            <div className="w-full">
              {/* title */}
              <div className="flex items-center space-x-1 flex-wrap">
                <p className="text-base 2sm:text-xl 3md:text-2xl text-lightBlack font-medium tracking-tighter leading-7">
                  {title}
                </p> 
              </div>
              {/* category */}
              {/* <div className="items-center space-x-5 flex-wrap mb-1 hidden 3md:flex">
                <p className="px-1.5 py-1 3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-1xs 2sm:text-xs uppercase font-semibold rounded-md">
                  {category}
                </p>
              </div> */}
              {/* details */}
              <div className="w-full mb-2 mt-1.5">
                <div className="w-full text-4xs 2sm:text-sm 3md:text-base text-darkerGray font-medium 3md:leading-[16px] flex items-center space-x-2 3md:space-x-3 flex-wrap">
                  <p>
                    {date}
                    {dateNote && (
                      <span className="ml-1">{`(${dateNote})`}</span>
                    )}
                  </p>
                  <PrivacyBadge privacy={privacy} isYourProfile={isYourProfile}/> 
                  {/* <p className="flex items-center capitalize">
                    {privacy === "public" ? (
                      <BsGlobe2 className="mr-1.5" />
                    ) : (
                      <BsExclamationDiamondFill className="mr-1.5" />
                    )}
                    {privacy}
                  </p> */}
                  {people && (
                    <>
                      <p className="hidden md:block">
                        <IoEllipse className="w-1 h-1" />
                      </p>
                      <p className="hidden md:flex items-center">
                        <UserTagIconSVG className="mr-1.5" />
                        {people}
                      </p>
                    </>
                  )}
                  <p className="px-1.5 py-1 block  3md:px-4.5 3md:py-1.5 bg-lightGreen text-white text-1xs 2sm:text-xs uppercase font-semibold rounded-md " style={{ marginLeft: 20 }} >
                    {category}
                  </p>
                </div>
              </div>
              {/* Author Name and description */}
              <div className="w-full my-2">
                <p className="text-4xs 2sm:text-sm 3md:text-base font-bold text-primary 3md:leading-6 mt-4">
                  {positions.map((p, i) => (
                    <span key={i}>
                      {p.position.name}
                      {p.description && ` - ${p.description} `}
                      {i < positions.length - 1 && ", "}
                    </span>
                  ))}
                  <span className="pl-1 break-anywhere">{platform && `(${platform})`}</span>
                </p>
                {/* <p
                  className="mt-0.5 3md:mt-2.5 text-4xs 2sm:text-sm 3md:text-base 3md:leading-7.5 tracking-tightest truncate-multiline"
                  dangerouslySetInnerHTML={{ __html: description }}
                  >
                  </p> */}
                   <CharacterLimit html={description} /> 
              </div>
              {/* buttons */}
              {!_.isEmpty(buttons) && (
                <div className="w-full mt-3">
                  <div className="w-full grid grid-cols-[repeat(auto-fill,minmax(140px,1fr))] gap-3">
                    {buttons.map((btn, index) => {   
                      if (btn.type.name === "Listen") {
                        return (
                          // <Popup
                          //   ref={listenRef}
                          //   key={index}
                          //   position="bottom left"
                          //   open={showAudioPlayer}
                          //   onClose={() => setShowAudioPlayer(false)}
                          //   onOpen={() => setHideMusic(false)}
                          //   arrow={false}
                          //   closeOnDocumentClick={false}
                          //   contentStyle={{ marginTop: 20 }}
                          //   repositionOnResize={true}
                          //   trigger={(open) => {
                          //     return (
                          //       <button
                          //         className={`rounded-md py-2 px-4 2sm:px-10 2md:px-4 lg:py-5 lg:px-4 xl:px-0 btn-hover-white-primary border-2 border-gray text-xs 2md:text-sm font-semibold ${
                          //           showAudioPlayer || open
                          //             ? "bg-darkPrimary text-white"
                          //             : "text-lightBlack"
                          //         }`}
                          //         onClick={() => {
                          //           setShowAudioPlayer((prev) => !prev);
                          //           if (open) {
                          //             setHideMusic(false);
                          //           }
                          //         }}>
                          //         Listen
                          //       </button>
                          //     );
                          //   }}
                            
                          //   >
                          //   <AudioPlayer
                          //     media={btn}
                          //     hide={hideMusic}
                          //     onClosePlayer={() => {
                          //       if (listenRef.current) {
                          //         listenRef.current.close();
                          //       }
                          //     }}
                          //   />
                          // </Popup>
                        <div className="relative"> 
                            {/* <Button
                              key={index}
                              buttonName={btn.type.name}
                              buttonTextClass={`text-xs 2md:text-sm text-lightBlack font-semibold`}
                              buttonRadius="6px"
                              buttonClass={`relative rounded-md py-2 px-4 2sm:px-10 2md:px-4 lg:py-5 xl:px-0 border-2 border-gray btn-hover-white-primary bg-white w-full h-full`}
                              onClick={() => {
                                setIsVisible(buttons.id);
                              }}
                            /> */}
                          <Button
                              key={index}
                              buttonName={btn.type.name}
                              buttonTextClass={`text-xs 2md:text-sm text-lightBlack font-semibold`}
                              buttonRadius="6px"
                              buttonClass={`relative rounded-md py-3 px-4   xl:px-0 border-2 border-gray btn-hover-white-primary bg-white w-full h-full`}
                              onClick={() => {
                                setPLayMusic('')
                                setPLayMusic(buttons)
                                handlePopup()
                                setIsVisible(buttons.id);
                              }}
                            />
                          {
                            // isVisible === buttons.id &&
                            activePopup.group === group && activePopup.index === indexPopUp &&
                              <div className="absolute -bottom-[3.8rem] z-[9999] left-0 inser-0" ref={listenRef}>
                                <AudioPlayer
                                  media={btn}
                                  hide={hideMusic}
                                  onClosePlayer={() => {
                                    setActivePopup({ group:null, index: null});
                                  }}
                                />
                              </div>
                          }
                          {
                            (organizeBy === "Job/Role" && isVisible === buttons.id) &&
                            <div className="absolute -bottom-[3.8rem] z-[9999] left-0 inser-0" ref={listenRef}>
                                <AudioPlayer
                                  media={btn}
                                  hide={hideMusic}
                                  onClosePlayer={() => {
                                    setIsVisible(null);
                                  }}
                                />
                              </div>
                          }
                            
                        </div>
                        );
                      }
                      return (   
                        btn.type.name === 'Other'  ? 
                        <div className="w-full">
                        <ToolTip
                        value={btn?.other_description === 'undefined' ? 'No descrption' : btn?.other_description || 'No descrption'}
                        backgroundColor="#0c6ed1"
                        color="white">
                          <Button
                            key={index}
                            buttonName={"Other"}
                            buttonTextClass={`text-xs 2md:text-sm text-lightBlack font-semibold`}
                            buttonRadius="6px"
                            buttonClass="relative rounded-md py-3 px-4   xl:px-0 border-2 border-gray btn-hover-white-primary bg-white w-full h-full"
                            onClick={() => handleMedia(btn)}
                          />
                          </ToolTip>
                          </div>
                        : <Button
                            key={index}
                            buttonName={btn.type.name}
                            buttonTextClass={`text-xs 2md:text-sm text-lightBlack font-semibold`}
                            buttonRadius="6px"
                            buttonClass={`relative rounded-md py-3 px-4   xl:px-0 border-2 border-gray btn-hover-white-primary bg-white w-full h-full`}
                            onClick={() => handleMedia(btn)}
                          />
                        
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {/* more */}
            <Popup
              ref={optionRef}
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{
                marginTop: 10,
                marginLeft: 5,
                zIndex: 9999,
              }}
              trigger={
                <button
                  className="bg-thBg w-5 h-5 2sm:w-10 2sm:h-10 rounded-md"
                  onClick={() => setShowMoreOption((open) => !open)}>
                  <FaEllipsisH className="m-auto text-1xs 2sm:text-base" />
                </button>
              }>
              <OptionListItem
                {...{
                  isYourProfile,
                  onEdit,
                  onDelete,
                  permission,
                  onShare,
                  share,
                  closeModal,
                  onReport,
                  profile,
                  featured,
                  hide
                }}
                closeMoreOption={() => {
                  setShowMoreOption(false);
                  optionRef.current.close();
                }} 
              />
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
};
export default function DetailedViewMilestones({
  isYourProfile,
  featured = {},
  current = [],
  past = [],
  pin =[],
  showFeatured,
  permission,
  profile,
  onWatchMedia = () => {},
  onViewImage = () => {},
  handleViewPdf = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onShare = () => {},
  onReport = () => {},
}) {

  const {
    organizeBy,
  } = useMilestones();
  
  // State to track the active pop-up's group and index
  const [activePopup, setActivePopup] = useState({ group: null, index: null });

  const featuredThumbnail = featured.thumbnail
    ? featured.thumbnail.webp_url || featured.thumbnail.url
    : null;
  const featuredDate = formatDate(featured, profile?.dateFormat);
  useLayoutEffect(() => {
    gsap.fromTo(
      ".milestone-title",
      {
        y: -20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
      }
    );
  }, []);

  const handlePopup = (group, index) => {
    // Toggle pop-up visibility based on group and index
    if (activePopup.group === group && activePopup.index === index) {
      setActivePopup({ group: null, index: null });
    } else {
      setActivePopup({ group, index });
    }
  };
  
  return (
    <>
    <div className="w-full container-max-width px-4 3md:px-0">
      {showFeatured && (
        <div className="w-full container-max-width mb-2">
          {/* <p className="milestone-title px-7.5 3md:px-0 text-lightBlack font-bold text-2xl leading-7.5 tracking-tighter">
            Featured
          </p> */}
          {!_.isEmpty(featured) && ( 
            <DetailedViewCard
              profile={profile}
              featured={featured}
              url={featuredThumbnail}
              viewUrl={featured.thumbnail.url}
              title={featured.title}
              dateNote={featured.date_note}
              date={featuredDate}
              people={featured.people}
              positions={featured.positions}
              platform={featured.platform}
              description={featured.description}
              privacy={featured.privacy}
              isAdultContent={featured.is_adult_content ? true : false}
              category={featured?.category?.name}
              buttons={featured.media_type}
              onWatchMedia={onWatchMedia}
              onViewImage={onViewImage}
              handleViewPdf={handleViewPdf}
              onEdit={() => onEdit(featured, "featured")}
              onDelete={() => onDelete(featured)}
              isYourProfile={isYourProfile}
              permission={permission}
              onShare={() => onShare(featured, "featured")}
              share={true}
              onReport={() => onReport(featured)}
            />
          )}
        </div>
      )}

      <div className="w-full mt-4.5 3md:mt-10">
      {pin.length > 0 && 
        <p className="milestone-title text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter flex gap-3">
          <>
           Pinned
          </> 
          <AiFillPushpin className="text-2xl"/>
        </p>
      }
        {pin.map((obj, index) => {
          const date = formatDate(obj, profile?.dateFormat);
          const thumbnail = obj.thumbnail
            ? obj.thumbnail.webp_url || obj.thumbnail.url
            : null;
          const viewUrl = obj.thumbnail ? obj.thumbnail.url : null;

          return (
            <DetailedViewCard
              profile={profile}
              featured={obj}
              key={`past-${index}`}
              details={obj}
              url={thumbnail}
              viewUrl={viewUrl}
              title={obj.title}
              dateNote={obj.date_note}
              date={date}
              people={obj.people}
              positions={obj.positions}
              platform={obj.platform}
              description={obj.description}
              privacy={obj.privacy}
              isAdultContent={obj.is_adult_content ? true : false}
              category={obj?.category?.name}
              buttons={obj.media_type}
              onWatchMedia={onWatchMedia}
              onViewImage={onViewImage}
              handleViewPdf={handleViewPdf}
              onEdit={() => onEdit(obj, "past")}
              onDelete={() => onDelete(obj)}
              isYourProfile={isYourProfile}
              permission={permission}
              onShare={() => onShare(obj, "past")}
              share={true}
              onReport={() => onReport(obj)}
              handlePopup = {()=>{handlePopup('pin',index)}}
              activePopup = {activePopup}
              setActivePopup={setActivePopup}
              group = 'pin'
              indexPopUp = {index}
            />
          );
        })}
      </div>

      <div className="w-full mt-4.5 3md:mt-10">
      {current.length > 0 && 
        <p className="milestone-title text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter">
          {
            organizeBy === DATE_NEWEST_TO_OLDEST ? DATE_NEWEST_TO_OLDEST  :
            organizeBy === DATE_OLDEST_TO_NEWEST ? DATE_OLDEST_TO_NEWEST : 'Upcoming'
          }
          
        </p>
        }
        {current.map((obj, index) => {
          const date = formatDate(obj, profile?.dateFormat);
          const thumbnail = obj.thumbnail
            ? obj.thumbnail.webp_url || obj.thumbnail.url
            : null;
          const viewUrl = obj.thumbnail ? obj.thumbnail.url : null;
      
          return (
            <DetailedViewCard
              profile={profile}
              featured={obj}
              key={`current-${index}`}
              details={obj}
              url={thumbnail}
              viewUrl={viewUrl}
              title={obj.title}
              dateNote={obj.date_note}
              date={date}
              people={obj.people}
              positions={obj.positions}
              platform={obj.platform}
              description={obj.description}
              privacy={obj.privacy}
              isAdultContent={obj.is_adult_content ? true : false}
              category={obj?.category?.name}
              buttons={obj.media_type}
              onWatchMedia={onWatchMedia}
              onViewImage={onViewImage}
              handleViewPdf={handleViewPdf}
              onEdit={() => onEdit(obj, "current")}
              onDelete={() => onDelete(obj)}
              isYourProfile={isYourProfile}
              permission={permission}
              onShare={() => onShare(obj, "current")}
              share={true}
              onReport={() => onReport(obj)}
              handlePopup = {()=>{handlePopup('current',index)}}
              activePopup = {activePopup}
              group = 'current'
              setActivePopup={setActivePopup}
              indexPopUp = {index}
            />
          );
        })}
      </div>
      <div className="w-full mt-4.5 3md:mt-10">
      {past.length > 0 && 
        <p className="milestone-title text-lightBlack font-bold text-base 2sm:text-xl 3md:text-2xl leading-7.5 tracking-tighter">
          {
            (organizeBy === DATE_NEWEST_TO_OLDEST || organizeBy === DATE_OLDEST_TO_NEWEST || current.length <= 0) ? '' :'Previous'
          }
          
        </p>
        }
        {past.map((obj, index) => {
          const date = formatDate(obj, profile?.dateFormat);
          const thumbnail = obj.thumbnail
            ? obj.thumbnail.webp_url || obj.thumbnail.url
            : null;
          const viewUrl = obj.thumbnail ? obj.thumbnail.url : null;

          return (
            <DetailedViewCard
              profile={profile}
              featured={obj}
              key={`past-${index}`}
              details={obj}
              url={thumbnail}
              viewUrl={viewUrl}
              title={obj.title}
              dateNote={obj.date_note}
              date={date}
              people={obj.people}
              positions={obj.positions}
              platform={obj.platform}
              description={obj.description}
              privacy={obj.privacy}
              isAdultContent={obj.is_adult_content ? true : false}
              category={obj?.category?.name}
              buttons={obj.media_type}
              onWatchMedia={onWatchMedia}
              onViewImage={onViewImage}
              handleViewPdf={handleViewPdf}
              onEdit={() => onEdit(obj, "past")}
              onDelete={() => onDelete(obj)}
              isYourProfile={isYourProfile}
              permission={permission}
              onShare={() => onShare(obj, "past")}
              share={true}
              onReport={() => onReport(obj)}
              handlePopup = {()=>{handlePopup('past',index)}}
              activePopup = {activePopup}
              group = 'past'
              setActivePopup={setActivePopup}
              indexPopUp = {index}
            />
          );
        })}
      </div>
    </div>
    </>
  );
}

export { DetailedViewCard };
