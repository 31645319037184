import React, { useEffect, useLayoutEffect } from "react";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import Layout from "layouts/layout";
import { TabSelector } from "components/global/tabselector";
import { TabPanel, useTabs } from "react-headless-tabs";
import AboutIconSVG from "assets/svg/about-icon-svg";
import LineVerticalSVG from "assets/svg/line-vertical";
import PasswordIconSVG from "assets/svg/password-icon";
import SettingsGeneral from "components/settings/general";
import SettingsAccount from "components/settings/account";
import ViewsIconSVG from "assets/svg/views-icon-svg";
import SettingsViews from "components/settings/views";
import UsersXLIconSVG from "assets/svg/users-xl-icon";
import SettingsAccess from "components/settings/access";
import { randomExtension } from "utils/utils";

import { Link, useLocation } from "react-router-dom";
import ArrowLeftIcon from "assets/svg/arrow-left-icon";

import { gsap } from "gsap";

const Settings = ({ userData, setUserData }) => {
  const { profile, activeTab } = useQuery();

  const [selectedTab, setSelectedTab] = useTabs([
    "general",
    "access",
    "account",
    "views",
  ]);

  const extension = randomExtension();

  useLayoutEffect(() => {
    gsap.fromTo(
      ".settings-tabs",
      { scale: 0.8, opacity: 0 },
      { scale: 1, opacity: 1 }
    );
    gsap.fromTo(
      ".settings-title",
      { y: -25, opacity: 0 },
      { y: 0, opacity: 1 }
    );
  }, []);

  useEffect(() => {
    if (activeTab) {
      setSelectedTab(activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <Layout title="Settings">
      <div className="auth-page-wrapper pb-8.5">
        <div className="w-full container-max-width bg-white rounded-md px-7.5 1md:px-24.5 pt-9">
          <Link
            to={`/${userData?.user?.username}-${extension}/profile/${userData?.profile?.id}`}>
            <div className="flex items-center font-bold gap-1 hover:text-lightPrimary mb-2 text-sm text-darkerGray">
              <ArrowLeftIcon className="font-bold" />
              <span>Back to Profile</span>
            </div>
          </Link>
          <h1 className="settings-title text-3xl text-lightBlack tracking-tighter font-bold">
            Settings
          </h1>
          <div className="settings-tabs flex justify-center flex-wrap gap-x-6 1md:gap-x-1 1md:flex-nowrap 1md:justify-start items-center">
            <TabSelector
              isActive={selectedTab === "general"}
              gap="3sm:mr-7"
              colorType="black"
              paddingY="py-7.5"
              onClick={() => setSelectedTab("general")}>
              <div className="w-full flex justify-center items-center">
                <AboutIconSVG className="mr-2" />
                General
              </div>
            </TabSelector>
            <div className="hidden 3sm:block">
              <LineVerticalSVG />
            </div>
            <TabSelector
              isActive={selectedTab === "access"}
              gap="3sm:ml-7 3sm:mr-7"
              colorType="black"
              paddingY="py-7.5"
              onClick={() => setSelectedTab("access")}>
              <div className="w-full flex justify-center items-center">
                <UsersXLIconSVG className="mr-2" />
                User Access
              </div>
            </TabSelector>
            <div className="hidden 3sm:block">
              <LineVerticalSVG />
            </div>
            <TabSelector
              isActive={selectedTab === "account"}
              gap="3sm:ml-7 3sm:mr-7"
              colorType="black"
              paddingY="py-7.5"
              onClick={() => setSelectedTab("account")}>
              <div className="w-full flex justify-center items-center">
                <PasswordIconSVG className="mr-2" />
                Account
              </div>
            </TabSelector>
            <div className="hidden 3sm:block">
              <LineVerticalSVG />
            </div>
            <TabSelector
              isActive={selectedTab === "views"}
              gap="3sm:ml-7"
              colorType="black"
              paddingY="py-7.5"
              onClick={() => setSelectedTab("views")}>
              <div className="w-full flex justify-center items-center">
                <ViewsIconSVG className="mr-2" />
                Your Views
              </div>
            </TabSelector>
          </div>
        </div>
        <TabPanel hidden={selectedTab !== "general"}>
          <SettingsGeneral userData={userData} setUserData={setUserData} />
        </TabPanel>
        <TabPanel hidden={selectedTab !== "access"}>
          <SettingsAccess userData={userData} profile={profile} />
        </TabPanel>
        <TabPanel hidden={selectedTab !== "account"}>
          <SettingsAccount userData={userData} />
        </TabPanel>
        <TabPanel hidden={selectedTab !== "views"}>
          <SettingsViews userData={userData} />
        </TabPanel>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

const useQuery = () => {
  const { state } = useLocation();

  return React.useMemo(() => state, [state]);
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
