import React from "react";
import Input from "components/input/input";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import Select from "components/select/select";
import _ from "lodash";

const func = () => { };

export default function DateInput({
  date = {},
  setDate = func,
  label = "",
  labelClass = "text-sm font-bold text-darkerGray",
  showApp = false,
  showPresent = false,
  name = "",
  errors = {},
  fieldKey,
  required = false,
}) {
  const months = [
    {
      label: "January",
      value: 1
    },
    {
      label: "February",
      value: 2
    },
    {
      label: "March",
      value: 3
    },
    {
      label: "April",
      value: 4
    },
    {
      label: "May",
      value: 5
    },
    {
      label: "June",
      value: 6
    },
    {
      label: "July",
      value: 7
    },
    {
      label: "August",
      value: 8
    },
    {
      label: "September",
      value: 9
    },
    {
      label: "October",
      value: 10
    },
    {
      label: "November",
      value: 11
    },
    {
      label: "December",
      value: 12
    },
  ]
  // const year = new Date().getFullYear() + 5000;
  const year = 2099;
  // const [approximately, setApproximately] = useState(false);
  // const [toPresent, setToPresent] = useState(false);

  // const yearOption = useMemo(() => {
  //   let years = [];
  //   const currentYear = new Date().getFullYear() + 1;
  //   let startYear = 1900;
  //   while (startYear <= currentYear) {
  //     years.push({
  //       label: startYear,
  //       value: startYear,
  //     });
  //     startYear++;
  //   }
  //   return _.reverse(years);
  // }, []);

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    const { name, value } = e.target;
    if (value === "" || re.test(value)) {
      if(e.target?.max && parseInt(e.target?.max) < parseInt(value)){
        setDate((prev) => ({ ...prev, [name]: e.target.max, present: false }));
        return;
      }
      setDate((prev) => ({ ...prev, [name]: value, present: false }));
    }
  };

  const setMinYear = (e) =>{ 
    const { name, value } = e.target;
    if(e.target?.min && parseInt(e.target?.min) > parseInt(value)){
      setDate((prev) => ({ ...prev, [name]: e.target.min, present: false })); 
      return;
    }
  }

  return (
    <div className="w-full mb-6">
      {label && <label className={`${labelClass} leading-4 ${required ? "marked" : ""}`}>{label}</label>}
      <div className={`w-full grid 3sm:grid-cols-3 gap-3 ${label && "mt-2"}`}>
        <Input
          min="1"
          max="31"
          value={date?.day || ""}
          name="day"
          placeholder="Day"
          inputClass="border border-borderGray rounded-md"
          outsideLabelClass="text-sm font-bold text-darkerGray"
          noLabelPaddingY="1rem"
          type="number"
          onChange={handleChange}
          errorMessage={errors[`${fieldKey}.day`]}
          errorMessagePosition="bottom"
          disableUpDownArrow={true}
        />
        {/* <Input
          min="1"
          max="12"
          value={date?.month || ""}
          name="month"
          placeholder="Month"
          inputClass="border border-borderGray rounded-md"
          outsideLabelClass="text-sm font-bold text-darkerGray"
          noLabelPaddingY="1rem"
          type="number"
          onChange={handleChange}
          errorMessage={errors[`${fieldKey}.month`]}
          errorMessagePosition="bottom"
          disableUpDownArrow={true}
        /> */}
        <Select
          options={months}
          placeholder="Month"
          name="month"
          value={date?.month ? months.filter((m) => m.value === date.month)[0] : ""}
          height={58}
          onChange={(e) =>
            handleChange({
              target: { name: "month", value: e.value },
            })
          } 
          errorMessage={errors[`${fieldKey}.month`]}
          labelClass="text-sm font-bold text-darkerGray leading-4 marked"
          onlyText={true}
        />
        <div>
        <Input
          min="1900"
          max={`${year}`}
          value={date?.year || ""}
          name="year"
          placeholder={required ? "* Year" : "Year"}
          inputClass="border border-borderGray rounded-md"
          outsideLabelClass="text-sm font-bold text-darkerGray"
          noLabelPaddingY="1rem"
          type="number"
          onChange={handleChange}
          errorMessage={errors[`${fieldKey}.year`]}
          errorMessagePosition="bottom"
          disableUpDownArrow={true}
          onBlur={setMinYear}
        />
          {
            (_.isEmpty(errors[`${fieldKey}.year`]) && name === 'startDate' && !date?.year) &&
            <label className="input-error-message">* Start year is required</label>
          }
        </div>
      </div>

      <div className="w-full milestone-checkbox-form"  >
        <div className="w-full sm:flex items-center sm:space-x-10">
          {showApp && (
            <CustomCheckbox
              label={
                <p className="text-sm text-darkerGray leading-6 whitespace-nowrap">
                  Approximately
                </p>
              }
              name={`approximately-${name}`}
              checked={date.approximately}
              // onChange={(e) => setApproximately(e.target.checked)}
              onChange={(e) =>
                setDate((prev) => ({
                  ...prev,
                  approximately: e.target.checked,
                }))
              }
            />
          )}
          {date.hasOwnProperty("present") && showPresent && (
            <CustomCheckbox
              label={
                <p className="text-sm text-darkerGray leading-6 whitespace-nowrap">
                  To Present
                </p>
              }
              name={`toPresent-${name}`}
              checked={date.present}
              // onChange={(e) => setToPresent(e.target.checked)}
              onChange={(e) =>
                setDate((prev) => ({ ...prev, present: e.target.checked }))
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
